export const generateShareLink = (participantId, visitId, setLink) => {
    const authToken = sessionStorage.getItem('token')
    const baseUrl = window._env_.REACT_APP_BACKEND_URL
    const endpoint = `${baseUrl}/visits/${participantId}/visit/${visitId}/remote`
    const headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    const { origin } = window.location
                    const path = `remote/participants/${participantId}/visits/${visitId}`
                    const link = `${origin}/${path}?remote_token=${r.remote_visit_url}`
                    setLink(link)
                })
            } else {
                setLink('FAIL')
            }
        })
        .catch((err) => {
            setLink('FAIL')
        })
}
