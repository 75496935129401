import { toast } from 'react-toastify'

export function fetchParticipants(
    setLoading,
    setParticipantList,
    t,
    facility_id,
) {
    let authToken = sessionStorage.getItem('token')
    let endpoint =
        facility_id &&
        !(facility_id === 'default' || facility_id === 'show_all')
            ? `${window._env_.REACT_APP_BACKEND_URL}/participants/list/facility/${facility_id}`
            : `${window._env_.REACT_APP_BACKEND_URL}/participants/list`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                setLoading(false)

                response.json().then((r) => {
                    // reverse() response array so that most recent entries are on top,
                    // however the backend implementation does not guarantee the order
                    // of the entries returned (MUST SEE BACKEND IMPLEMENTATION)
                    r.reverse()
                    setParticipantList(r)
                })
            } else {
                setLoading(false)
                toast.error(t('ParticipantListRetrievalFailure'))
            }
        })
        .catch((err) => {
            setLoading(false)
            toast.error(t('ParticipantListRetrievalFailure'))
        })
}

export function fetchParticipantSupportedLanguages(
    setLoading,
    setSupportedLanguages,
    t,
) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/participants/supported_languages`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                setLoading(false)

                response.json().then((r) => {
                    if (r.supported_languages?.length > 0) {
                        setSupportedLanguages(r.supported_languages)
                    } else {
                        setSupportedLanguages(['en-US', 'el-GR'])
                    }
                })
            } else {
                setLoading(false)
                toast.error(t('ParticipantSupportedLanguagesRetrievalFailure'))
            }
        })
        .catch((err) => {
            setLoading(false)
            toast.error(t('ParticipantSupportedLanguagesRetrievalFailure'))
        })
}
