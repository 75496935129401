import { toast } from 'react-toastify'

export default function fetchAudioSampleURL(
    set_audio_sample_url,
    visit_id,
    category,
    path,
    t,
) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/assessments/${visit_id}/${category}/${path}/info`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken?.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    const protected_endpoint =
                        window._env_.REACT_APP_BACKEND_URL +
                        '/' +
                        r.audio_route +
                        '?X-Alzheimer-Service-Token=' +
                        authToken?.toString()
                    set_audio_sample_url(protected_endpoint)
                })
            } else if (response.status === 404) {
                toast.error(t('AssessmentAudioSampleNotFound'))
            } else if (response.status === 401 || response.status === 403) {
                toast.error(t('AssessmentAudioSampleAccessDenied'))
            } else {
                toast.error(t('AssessmentAudioSampleAccessError'))
            }
        })
        .catch((err) => {
            toast.error(t('AssessmentAudioSampleAccessError'))
        })
}
