/**
 * A tiny util that extracts the paths from the
 * assessment list and exports them.
 * 
 * Made due to the fact that the original list
 * has multiple layers and this tedious process
 * might have to be repeated in the future.
 */
import list_of_assessments from './list_of_assessments.json'

let sublist_of_paths = []
	
list_of_assessments.forEach( x => {
	x.assessments.forEach( y => sublist_of_paths.push(y.path) )
})

export default sublist_of_paths