import React from 'react'
import { useTranslation } from 'react-i18next'
import ReplayIcon from '@material-ui/icons/Replay'

export default function Retry({ messageCode }) {
    const { t } = useTranslation()

    return (
        <div>
            {messageCode ? t(messageCode) : t('Retry_Message')}
            <div align='center'>
                <ReplayIcon onClick={() => window.location.reload()} />
            </div>
        </div>
    )
}
