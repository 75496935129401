import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Slide,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import React, { useState } from 'react'
import { useEffect } from 'react'

const useStyles = makeStyles((_) => ({
    layout: {
        paddingBottom: '5px',
        marginTop: '20px',
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
        borderBottom: '1px solid #F2F2F2',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    titleContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'baseline',
    },
    title: {
        marginRight: '30px',
    },
    subtitle: {
        fontSize: '12px !important',
        fontWeight: '300 !important',
        maxHeight: '10px',
        lineHeight: '10px',
    },
}))

export const AccordeonContext = React.createContext({
    accordenActiveId: '',
    setAccordenActiveId: (activeId) => {},
})

function makeId() {
    var result = ''
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < 10; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        )
    }
    return result
}

export const NavigationAccordion = (props) => {
    const {
        label,
        backlabel,
        direction,
        children,
        subtitle,
        disabled = false,
    } = props
    const [expanded, setExpanded] = useState(false)
    const [id, setId] = useState('')
    const { accordenActiveId, setAccordenActiveId } =
        React.useContext(AccordeonContext)

    const classes = useStyles()

    useEffect(() => {
        setId(makeId())
    }, [])

    useEffect(() => {
        if (id !== accordenActiveId) {
            setExpanded(false)
        }
    }, [accordenActiveId, id])

    const toggleExpanded = (value) => {
        setAccordenActiveId(value && id)
        setExpanded(value)
    }

    return (
        <Accordion
            elevation={0}
            expanded={expanded}
            disabled={disabled}
            className='navigation-accordion'
            style={{
                background: accordenActiveId === id ? '#00a79d14' : 'initial',
            }}
        >
            <AccordionSummary
                expandIcon={
                    <ExpandMore style={{ transform: 'rotate(-90deg)' }} />
                }
                onClick={() => toggleExpanded(!expanded)}
                style={{ maxHeight: '84px' }}
            >
                <div className={classes.titleContainer}>
                    <label className={classes.title}>{label}</label>
                    <div className={classes.subtitle}>{subtitle}</div>
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <Slide
                    direction={direction}
                    in={expanded}
                    mountOnEnter
                    unmountOnExit
                >
                    <div className='navigation-accordion-content'>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box className={classes.layout}>
                                    <Button
                                        variant='text'
                                        style={{ fontSize: '14px' }}
                                        onClick={() => toggleExpanded(false)}
                                    >
                                        {`< ${backlabel}`}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    className='top-title'
                                    style={{ marginBottom: '30px' }}
                                >
                                    <Typography
                                        variant='h1'
                                        align='center'
                                        component='h1'
                                        className='top-title-text'
                                    >
                                        {label}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {children}
                            </Grid>
                        </Grid>
                    </div>
                </Slide>
            </AccordionDetails>
        </Accordion>
    )
}
