/* eslint-disable react-hooks/exhaustive-deps */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Slider,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Minimize, SettingsOverscan, ExpandMore } from '@material-ui/icons'
import ReplayIcon from '@material-ui/icons/Replay'
import WarningIcon from '@material-ui/icons/Warning'
import { Validator } from 'jsonschema'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonWithLoader } from '../utils/ButtonWithLoader'
import DialogWrapper from '../utils/DialogWrapper'

export const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 14,
    },
    formInnerContainer: {
        display: 'flex',
        padding: '10px 0',
        overflowY: 'auto',
        textAlign: 'left',
        bottom: '65px',
        top: 0,
        width: '100%',
    },
    formRoot: {
        margin: 'auto',
        position: 'relative',
        marginTop: '5px',
    },
    formGroup: {
        justifyItems: '',
    },
    formControlContainer: {
        position: 'relative',
        borderRadius: '8px',
        background: '#F7F7F7',
    },
    formControlGrey: {
        background: '#F7F7F7',
        position: 'relative',
        borderRadius: '8px',
    },
    formControl: {
        margin: theme.spacing(1),
        width: 'calc(100% - 20px)',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    input: {
        width: '100%',
        borderRadius: '5px',
        minWidth: '60px',
    },
    groupHeader: {
        background: '#f3f3f38a',
    },
    asterisk: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'red',
    },
    floatingButton: {
        alignSelf: 'center',
    },
    fullView: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        paddingLeft: 'calc(50% - 550px)',
        paddingRight: 'calc(50% - 550px)',
        paddingTop: '25px',
        background: 'white',
        zIndex: 1100,
        overflowY: 'scroll',
    },
    lockedElement: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        borderRadius: '5px',
        background: '#00a79d20',
        cursor: 'pointer',
        '&:hover': {
            background: '#00a79d30',
        },
    },
    lockButton: {
        color: '#b77700',
        right: '-10px',
        top: '-10px',
        width: '50px',
        border: '#a1a1a1 solid 2px',
        height: '50px',
        position: 'absolute',
        alignSelf: 'center',
        background: 'white',
        borderRadius: '30px',
        boxShadow: '0px 0px 6px 4px white',
        '&:hover': {
            backgroundColor: 'rgb(238 238 238) !important',
        },
    },
    typography: {
        padding: theme.spacing(2),
    },
    buttonContainer: {
        width: '100%',
        margin: 'auto',
        padding: '10px 8px',
        bottom: 0,
    },
    submitButton: {
        '&:disabled': {
            background: '#c7c7c7',
            color: 'white',
        },
    },
    cardContent: {
        padding: 0,
    },
    cardActions: {
        boxShadow: '0px 1px 3px 1px grey',
    },
    arrayContainer: {
        padding: '20px 10px 10px 10px',
        borderTop: '1px solid #ededed',
        borderBottom: '1px solid #ededed',
        margin: '10px 0px',
    },
    arrayElementTitle: {
        padding: '10px 20px',
    },
    arrayButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 10px',
    },
    errorListItem: {
        margin: '4px 8px',
        height: '50px',
        lineHeight: '50px',
        padding: '2px !important',
        background: '#ff000012',
        borderRadius: '5px',
        '& p': {
            color: '#FF6666',
            fontSize: '16px',
            textAlign: 'center',
            height: '100%',
            lineHeight: '50px',
        },
    },
    errorText: {
        color: 'white',
        position: 'absolute',
        zIndex: 1,
        background: '#a23030cf',
        padding: '0px 5px',
        borderRadius: '2px',
        right: '7px',
        top: '-7px',
        fontSize: '14px',
        fontStyle: 'italic',
        whiteSpace: 'pre',
    },
    hasError: {
        borderRadius: '5px',
        position: 'relative',
        border: '2px solid #FF6666',
    },
}))

export const diagnosisKeys = [
    'healthy_diagnosis',
    'dementia_diagnosis',
    'mci_diagnosis',
    'other_diagnosis',
]

export let schemaVersion = ''

export function Retry(i18n_message, t) {
    return (
        <div>
            {t(i18n_message)}
            <div align='center'>
                <ReplayIcon onClick={() => window.location.reload()} />
            </div>
        </div>
    )
}

const mapError = (e, rootProp, t) => {
    let props = e.property
        .split('.')
        .filter((p) => {
            return !(p === 'instance' && !rootProp)
        })
        .map((p) => (p === 'instance' ? t(rootProp) : t(p)))
        .join(' -> ')
    if (e.name === 'required') {
        props += !!rootProp
            ? ` -> ${t(e.message.split('"')[1])}`
            : t(e.message.split('"')[1])
    }

    return e.name === 'required'
        ? `${t('the_field')} "${props}" ${t('is_required_form')}`
        : e.message.indexOf('is not exactly one from') > -1
        ? `${t('the_field')} "${props}" ${t('no_valid_value')}`
        : e.name === 'maximum'
        ? `"${props}" - ${t('must be less than or equal to')} ${
              e.schema.maximum
          }`
        : `"${props}" - ${t(e.message)}`
}

const validateDiagnosis = (state, jsonSchema, t) => {
    const diagnosisMap = {
        Healthy: 0,
        MCI: 1,
        Dementia: 2,
        Other: 3,
    }
    const diagnosisKey = diagnosisMap[state?.diagnosis?.type]
    if (diagnosisKey == null) {
        return []
    }

    const diagnosisSchema =
        jsonSchema?.properties?.diagnosis?.oneOf[diagnosisKey]
    if (diagnosisSchema == null) {
        return []
    }

    var validator = new Validator()
    const validation = validator.validate(state.diagnosis, diagnosisSchema)
    validation.propertyPath = 'diagnosis'
    return validation.errors.map((e) => mapError(e, 'diagnosis', t))
}

const checkForEmptyObjects = (state, t, parents = []) => {
    if (!state || typeof state !== 'object') {
        return []
    }

    let errors = []
    Object.entries(state).forEach(([key, entry]) => {
        if (!entry || typeof entry !== 'object') {
            return
        }

        if (!Object.keys(entry).length) {
            const error = parents.length
                ? `${t('the_field')} "${parents
                      .map((parent) => t(parent))
                      .join(' -> ')} -> ${t(key)}" ${t('is_empty')}`
                : `${t('the_field')} "${t(key)}" ${t('is_empty')}`
            errors.push(error)
        }
        const propErrors = checkForEmptyObjects(entry, t, [...parents, key])
        errors = [...errors, ...propErrors]
    })
    return errors
}

const validateState = (state, schema, t) => {
    var validator = new Validator()
    const validation = validator.validate(state, schema)
    const errorList = validation.errors.map((e) => mapError(e, null, t))
    const diagnosisErrors = validateDiagnosis(state, schema, t)
    const emptyObjects = checkForEmptyObjects(state, t)
    return {
        valid: validation.valid && !emptyObjects.length,
        errors: [...errorList, ...diagnosisErrors, ...emptyObjects],
    }
}

export const isValuesEmpty = (value) => {
    if (!value) {
        return true
    }
    if (value == null) {
        return true
    }
    if (typeof value !== 'object') {
        return false
    }
    if (!Object.keys(value).length) {
        return true
    }
    return Object.values(value).every((val) => val == null)
}

export const DynamicForm = (props) => {
    const { version, values, schema, onSubmit, overrides, loading } = props
    const { properties, required } = schema
    schemaVersion = version

    const formatInput = (state) => {
        if (!state || !state.diagnosis) {
            return state
        }
        const frontotemporalDegeneration =
            state.diagnosis?.dementiaDiseases?.frontotemporalDegeneration
        if (
            frontotemporalDegeneration &&
            typeof frontotemporalDegeneration !== 'object'
        ) {
            state.diagnosis.dementiaDiseases.frontotemporalDegeneration =
                typeof frontotemporalDegeneration === 'boolean'
                    ? { 1: frontotemporalDegeneration }
                    : { 0: frontotemporalDegeneration }
        }
        const diagnosis =
            state.diagnosis.typeId === 1
                ? { 0: state.diagnosis }
                : state.diagnosis.typeId === 3
                ? { 1: state.diagnosis }
                : state.diagnosis.typeId === 4
                ? { 2: state.diagnosis }
                : { 3: state.diagnosis }

        return {
            ...state,
            diagnosis,
        }
    }
    const { t } = useTranslation()
    const [state, setState] = useState(formatInput(values ?? {}))
    const [errors, setErrors] = useState([])
    const [validState, setValidState] = useState(false)
    const [touched, setTouched] = useState(false)
    const classes = useStyles()

    const fixStateForValidation = (state) => {
        if (!state) {
            return state
        }

        let mappedData = JSON.parse(JSON.stringify(state))

        if (
            !mappedData.diagnosis ||
            !Object.values(mappedData.diagnosis).length
        ) {
            return mappedData
        }
        mappedData['diagnosis'] = Object.values(mappedData.diagnosis)[0]

        const frontotemporalDegeneration =
            mappedData.diagnosis.dementiaDiseases?.frontotemporalDegeneration
        if (
            frontotemporalDegeneration &&
            Object.values(frontotemporalDegeneration).length
        ) {
            mappedData.diagnosis.dementiaDiseases[
                'frontotemporalDegeneration'
            ] = Object.values(frontotemporalDegeneration)[0]
        }
        return mappedData
    }
    const isStateEmpty = (state) => {
        return state == null
    }
    const cleanUpState = (state) => {
        if (!state) {
            return state
        }

        const newState = {}
        Object.entries(state).forEach(([key, entry]) => {
            if (isStateEmpty(entry)) {
                return
            }
            if (key === 'diagnosis' && entry?.length) {
                cleanUpState(entry[0])
            } else if (entry && typeof entry === 'object' && entry.length) {
                newState[key] = [...entry]
            } else if (entry && typeof entry === 'object') {
                newState[key] = cleanUpState(entry)
            } else {
                newState[key] = entry
            }
        })
        return newState
    }

    let tempState = {}
    const handleValueChanged = (_newValue) => {
        tempState = { ...tempState, ..._newValue }
        const newValue = cleanUpState(tempState)
        if (newValue && schemaVersion) {
            newValue['version'] = schemaVersion
        }
        const fixedState = fixStateForValidation(newValue)
        const { valid, errors } = validateState(fixedState, schema, t)
        setValidState(valid)
        setErrors(errors)
        setState(newValue)
    }

    useEffect(() => {
        handleValueChanged(state)
    }, [schema])

    const formGroups =
        properties &&
        Object.entries(properties)
            .filter(([key, _]) => {
                return key !== 'version'
            })
            .map(([key, element]) => {
                const label = key
                return (
                    <DynamicFragment
                        {...{
                            t: t,
                            touched,
                            classes,
                            level: 0,
                            overrides,
                            key: label,
                            path: 'root',
                            values: state,
                            manualKey: label,
                            element: element,
                            requiredList: required,
                            valueChanged: (newValue) => {
                                if (!touched) {
                                    setTouched(true)
                                }
                                handleValueChanged(newValue)
                            },
                        }}
                    />
                )
            })
    const errorElements = Object.values(errors).map((error) => (
        <Grid item key={error} xs={12} className={classes.errorListItem}>
            <Typography>{error}</Typography>
        </Grid>
    ))

    return (
        <div id='dynamic-form-container'>
            <div className={classes.formInnerContainer}>
                <Grid container spacing={2} className={classes.formRoot}>
                    {formGroups}
                    {touched && errorElements}
                </Grid>
            </div>
            <div className={classes.buttonContainer}>
                <ButtonWithLoader
                    label={t('submit')}
                    loading={loading}
                    className={classes.submitButton}
                    disabled={!validState}
                    onClick={() => {
                        const fixedState = fixStateForValidation(state)
                        const { valid, errors } = validateState(
                            fixedState,
                            schema,
                            t,
                        )
                        setValidState(valid)
                        setErrors(errors)
                        valid && onSubmit && onSubmit(fixedState)
                    }}
                />
            </div>
        </div>
    )
}

export const ArrayElement = (props) => {
    const {
        level,
        label,
        items,
        touched,
        value,
        overrides,
        valueChanged,
        path,
    } = props
    const [itemCounter, setItemCounter] = useState(value?.length || 0)
    const { t } = useTranslation()
    const classes = useStyles()

    const elements = []
    for (let index = 0; index < itemCounter; index++) {
        const propertiesCount = Object.keys(items?.properties).length
        elements.push(
            Object.entries(items?.properties ?? {}).map(([manualKey, prop]) => (
                <Grid
                    item
                    key={manualKey}
                    xs={
                        propertiesCount > 1 &&
                        manualKey?.toLowerCase() === 'name'
                            ? 9
                            : propertiesCount > 1 &&
                              manualKey.toLowerCase() === 'score'
                            ? 3
                            : 12
                    }
                >
                    <DynamicFragment
                        {...{
                            t,
                            path,
                            level,
                            touched,
                            manualKey,
                            values: (value ?? {})[index],
                            element: prop,
                            overrides,
                            valueChanged: (newValue) => {
                                const copyValue = value
                                    ? [...Object.values(value)]
                                    : []
                                copyValue[index] = {
                                    ...copyValue[index],
                                    ...newValue,
                                }
                                valueChanged(copyValue)
                            },
                        }}
                    />
                </Grid>
            )),
        )
    }

    return (
        <>
            <Grid container className={classes.arrayContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.arrayElementTitle}>
                        {t(label)}
                    </Typography>
                </Grid>
                {elements}
                <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    className={classes.arrayButtonContainer}
                >
                    <Grid item>
                        <Button
                            size='small'
                            color='primary'
                            variant='outlined'
                            onClick={(_) => {
                                value?.push({})
                                setItemCounter(itemCounter + 1)
                            }}
                        >
                            {t('add_one_more')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size='small'
                            color='secondary'
                            variant='outlined'
                            disabled={!itemCounter}
                            onClick={(_) => {
                                setItemCounter(Math.max(0, itemCounter - 1))
                                if (value?.length) {
                                    value?.pop()
                                }
                                value?.length
                                    ? valueChanged(value)
                                    : valueChanged(null)
                            }}
                        >
                            {t('remove_one')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export const DynamicElementGroup = (props) => {
    const {
        path,
        level,
        oneOf,
        label,
        values,
        touched,
        required,
        disabled,
        overrides,
        doubleColumn,
        valueChanged,
        requiredList,
        elements = {},
        expandedAccordeon,
        disabledKeys = {},
        showFullView = false,
    } = props
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(expandedAccordeon)
    const getErrorMessage = useCallback(() => {
        return touched && values && isValuesEmpty(values) ? t('is_empty') : ''
    }, [touched, values, t])
    const [error, setError] = useState(getErrorMessage())
    const [fullView, setfullView] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const anchor = useRef(null)

    const classes = useStyles()

    const currentPath = () => `${path}/${label}`

    useEffect(() => {
        if (required && !values) {
            valueChanged({})
        }
    }, [])
    useEffect(() => {
        setError(getErrorMessage())
    }, [getErrorMessage])

    const isPropertyConstant = ([key, element]) =>
        (element.enum && element.enum.length === 1) || key === 'version'
    const constantProps = () =>
        Object.entries(elements)
            .filter((args) => isPropertyConstant(args))
            .map(([key, element]) => {
                return {
                    key,
                    value: (element.enum ?? [schemaVersion])[0],
                }
            })
    const handleValueChanged = (value) => {
        let obj = value ? values ?? {} : null

        if (value) {
            obj = applyProps(obj, constantProps())
        }
        if (!obj) {
            setPopupOpen(true)
            return
        }
        valueChanged && valueChanged(obj)
    }
    const filteredElements = Object.entries(elements).filter(
        (args) => !isPropertyConstant(args),
    )
    const formattedElements = oneOf ? Object.entries(oneOf) : filteredElements
    const applyProps = (obj, props) => {
        const newObj = { ...obj }
        props.forEach(({ key, value }) => {
            newObj[key] = value
        })
        return newObj
    }
    const elementsList = formattedElements.map(([manualKey, element]) => (
        <DynamicFragment
            {...{
                t,
                values,
                touched,
                element,
                classes,
                manualKey,
                overrides,
                doubleColumn,
                requiredList,
                key: manualKey,
                level: level + 1,
                path: currentPath(),
                disabled: disabledKeys[manualKey],
                valueChanged: (obj, key) => {
                    const newObj = applyProps(obj, constantProps())
                    valueChanged(newObj, key)
                },
            }}
        />
    ))

    return (
        <>
            <Accordion
                ref={anchor}
                elevation={0}
                disabled={disabled}
                TransitionProps={{ unmountOnExit: true }}
                expanded={fullView || (values && expanded) ? true : false}
                className={`${error ? classes.hasError : ''} ${
                    fullView ? classes.fullView : ''
                }`}
                onChange={(_, expanded) => {
                    setExpanded(expanded)
                    if (expanded) {
                        handleValueChanged(expanded)
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore disabled={fullView} />}
                    className={classes.groupHeader}
                >
                    <Grid container>
                        <Grid item>
                            <FormControlLabel
                                label={t(label)}
                                aria-label={currentPath()}
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <Checkbox
                                        checked={!!values}
                                        disabled={disabled || fullView}
                                        onChange={(evt, checked) =>
                                            handleValueChanged(checked)
                                        }
                                    />
                                }
                            />
                        </Grid>
                        {error ? (
                            <Grid item xs={12}>
                                <Typography className={classes.errorText}>
                                    {error}
                                </Typography>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    {showFullView ? (
                        <IconButton
                            className={classes.floatingButton}
                            onClick={(evt) => {
                                evt.stopPropagation()
                                if (!fullView) {
                                    handleValueChanged(true)
                                } else {
                                    document.body.style = { overflow: 'hide' }
                                }
                                setfullView(!fullView)
                            }}
                        >
                            {fullView ? (
                                <span title={t('restore_view')}>
                                    <Minimize />
                                </span>
                            ) : (
                                <span title={t('maximize_view')}>
                                    <SettingsOverscan />
                                </span>
                            )}
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {elementsList}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <ClearPropertyPopup
                label={label}
                open={popupOpen}
                anchor={anchor?.current}
                setPopupOpen={setPopupOpen}
                onAffirm={() => {
                    setPopupOpen(false)
                    valueChanged && valueChanged(null)
                }}
            />
        </>
    )
}

export const InputSlider = (props) => {
    const { label, minimum = 0, maximum = 0, valueChanged } = props
    const classes = useStyles()
    const [value, setValue] = useState(props.value ?? '')
    const [timeout, setTImeoutState] = useState(0)

    const debounce = (func) => {
        clearTimeout(timeout)
        setTImeoutState(
            setTimeout(() => {
                func && func()
            }, 500),
        )
    }

    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
        valueChanged && debounce(() => valueChanged(newValue))
    }

    const usesSlider = () => {
        return minimum !== 0 || maximum !== 0
    }
    const handleInputChange = (event) => {
        let newValue = event.target.value
        if (newValue === '') {
            setValue('')
            valueChanged && valueChanged('')
            return
        }
        newValue = Number(event.target.value)
        if (usesSlider()) {
            newValue = Math.min(maximum, Math.max(minimum, newValue))
        }
        setValue(newValue)
        valueChanged && valueChanged(newValue)
    }

    return (
        <Grid container spacing={2} alignItems='center'>
            {usesSlider() ? (
                <Grid item xs>
                    <Slider
                        valueLabelDisplay='auto'
                        marks
                        value={typeof value === 'number' ? value : 0}
                        onChange={(evt, newValue) =>
                            handleSliderChange(evt, newValue)
                        }
                        min={minimum}
                        max={maximum}
                    />
                </Grid>
            ) : (
                <Grid item xs></Grid>
            )}
            {usesSlider() ? (
                <Grid item xs>
                    <TextField
                        type='number'
                        value={value}
                        label={label}
                        id='outlined-number'
                        className={classes.input}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            step: 1,
                            min: minimum,
                            max: maximum,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                        variant='outlined'
                    />
                </Grid>
            ) : (
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <TextField
                        type='number'
                        value={value}
                        label={label}
                        id='outlined-number'
                        className={classes.input}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            type: 'number',
                        }}
                        variant='outlined'
                    />
                </Grid>
            )}
        </Grid>
    )
}

export const OneOfRadio = (props) => {
    const {
        path,
        level,
        value,
        label,
        touched,
        overrides,
        oneOf = {},
        valueChanged,
    } = props
    const [disabled, setDisabled] = useState({})

    useEffect(() => {
        disableExceptFor()
        Object.entries(oneOf).forEach(([key, _]) => {
            if (value && value[key]) {
                disableExceptFor(key)
            }
        })
    }, [])
    const disableExceptFor = (key) => {
        const _disabled = { ...disabled }
        Object.keys(oneOf).forEach((_key) => {
            if (key) {
                _disabled[_key] = `${_key}` !== `${key}`
            } else {
                _disabled[_key] = false
            }
        })
        setDisabled(_disabled)
    }

    return (
        <DynamicElementGroup
            path={path}
            level={level}
            label={label}
            values={value}
            elements={oneOf}
            touched={touched}
            overrides={overrides}
            disabledKeys={disabled}
            valueChanged={(newValue, key) => {
                let obj = {}
                if (key) {
                    obj[key] = newValue[key]
                    if (newValue[key]) {
                        disableExceptFor(key)
                    } else {
                        disableExceptFor()
                    }
                } else {
                    obj = newValue
                }
                valueChanged && valueChanged(obj)
            }}
        />
    )
}

export const DynamicElement = (props) => {
    const {
        type,
        path,
        level,
        label,
        value,
        oneOf,
        touched,
        minimum,
        options,
        maximum,
        required,
        overrides,
        placeholder,
        valueChanged,
    } = props
    const { t } = useTranslation()
    const [internalValue, setinternalValue] = useState(value ?? '')

    const currentPath = () => `${path}/${label}`

    const getErrorMessage = (_value) => {
        return required && touched && isValuesEmpty(_value)
            ? `${t('is_required')}`
            : ''
    }
    const [error, setError] = useState(getErrorMessage())
    const classes = useStyles()
    const [confirmEdit, setConfirmEdit] = useState(
        !!(overrides?.confirmEdit ?? {})[label],
    )
    const [disabled] = useState((overrides?.disabled || {})[currentPath()])

    useEffect(() => {
        setError(getErrorMessage(value))
    }, [value, touched])

    const debounce = (func, timeout) => {
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            func && func()
        }, timeout)
    }

    const handleValueChanged = (newValue) => {
        valueChanged && valueChanged(newValue)
    }
    const fromElementsMap = {
        oneOf: (
            <OneOfRadio
                path={path}
                level={level}
                oneOf={oneOf}
                value={value}
                label={label}
                touched={touched}
                disabled={disabled}
                overrides={overrides}
                valueChanged={handleValueChanged}
            />
        ),
        string: (
            <TextField
                label={t(label)}
                disabled={disabled}
                value={internalValue}
                placeholder={placeholder}
                aria-label={currentPath()}
                className={classes.formControl}
                InputLabelProps={{
                    shrink: placeholder ? true : undefined,
                }}
                onChange={(evt) => {
                    const {
                        target: { value },
                    } = evt
                    setinternalValue(value)
                    debounce(() => {
                        handleValueChanged(value)
                    }, 300)
                }}
            />
        ),
        password: (
            <TextField
                type='password'
                label={t(label)}
                className={classes.formControl}
                onChange={(evt) => {
                    const {
                        target: { value },
                    } = evt
                    setinternalValue(value)
                    debounce(() => {
                        handleValueChanged(value)
                    }, 300)
                }}
                value={internalValue}
                placeholder={placeholder}
                InputLabelProps={{
                    shrink: placeholder ? true : undefined,
                }}
            />
        ),
        boolean: (
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(evt) =>
                            handleValueChanged(evt.target.checked)
                        }
                    />
                }
                color='primary'
                label={t(label)}
                disabled={disabled}
                aria-label={currentPath()}
                checked={value || false}
                className={classes.formControl}
            />
        ),
        integer: (
            <InputSlider
                label={t(label)}
                minimum={minimum}
                maximum={maximum}
                value={value ?? ''}
                disabled={disabled}
                aria-label={currentPath()}
                className={classes.formControl}
                valueChanged={handleValueChanged}
            />
        ),
        number: (
            <InputSlider
                label={t(label)}
                value={value ?? ''}
                disabled={disabled}
                aria-label={currentPath()}
                className={classes.formControl}
                valueChanged={handleValueChanged}
            />
        ),
        enum: (
            <FormControl className={classes.formControl}>
                <InputLabel
                    id={`${label}-select-label`}
                    aria-label={currentPath()}
                >
                    {t(label)}
                </InputLabel>
                <Select
                    value={value ?? ''}
                    disabled={disabled}
                    id={`${label}-select`}
                    onChange={(evt) => handleValueChanged(evt.target.value)}
                >
                    <MenuItem value='' style={{ color: 'lightgrey' }}>
                        <em>{t('none')}</em>
                    </MenuItem>
                    {options?.map((option) => (
                        <MenuItem value={option} key={option}>
                            {t(option)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ),
    }

    return (
        <>
            <Grid container className={error ? classes.hasError : ''}>
                <Grid xs item className={classes.formControlContainer}>
                    {fromElementsMap[type]}
                    {confirmEdit ? (
                        <LockMechanism setConfirmEdit={setConfirmEdit} />
                    ) : (
                        <></>
                    )}
                </Grid>
                {error ? (
                    <Grid item xs={12}>
                        <Typography className={classes.errorText}>
                            {error}
                        </Typography>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </>
    )
}

export const LockMechanism = (props) => {
    const { setConfirmEdit } = props
    const { t } = useTranslation()
    const [popoverOpen, setPopoverOpen] = useState(false)
    const classes = useStyles()

    return (
        <>
            <div
                className={classes.lockedElement}
                onClick={(evt) => {
                    setPopoverOpen(true)
                }}
            ></div>
            <DialogWrapper
                title={t('attention')}
                open={popoverOpen}
                setOpen={(value) => {
                    setPopoverOpen(value)
                }}
            >
                <Grid
                    container
                    spacing={3}
                    style={{
                        maxWidth: 500,
                        textAlign: 'center',
                        margin: 'auto',
                    }}
                >
                    <Grid item xs={12}>
                        <WarningIcon
                            style={{ fill: '#FF6666', fontSize: '50px' }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            style={{ textAlign: 'center' }}
                            className={classes.typography}
                        >
                            {t('change_propety_warning')}
                        </Typography>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                        <Grid item xs={6}>
                            <Button
                                variant='contained'
                                onClick={(_) => setPopoverOpen(false)}
                                style={{ width: '100%' }}
                            >
                                {t('cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant='outlined'
                                onClick={(_) => setConfirmEdit(false)}
                                style={{ width: '100%' }}
                            >
                                {t('unlock')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogWrapper>
        </>
    )
}

export const DateElement = (props) => {
    const { label, value, required, touched, overrides, valueChanged, format } =
        props
    const { t } = useTranslation()
    const parse = (value) => {
        let parsedDate
        if (format === 'date') {
            parsedDate = value
        } else {
            const { year, month, day } = value ?? {}
            parsedDate = `${year}-${('0' + month).slice(-2)}-${(
                '0' + day
            ).slice(-2)}`
        }
        return parsedDate ?? ''
    }
    const [parsedValue, setParsedValue] = useState(parse(value))
    const [confirmEdit, setConfirmEdit] = useState(
        (overrides?.confirmEdit ?? {})[label],
    )
    const { min, max } = (overrides?.dateLimits ?? {})[label] ?? {}

    const getErrorMessage = () =>
        required && touched && !value ? `${t('is_required')}` : ''
    const [error, setError] = useState(getErrorMessage())
    const classes = useStyles()

    useEffect(() => {
        setError(getErrorMessage())
        setParsedValue(parse(value))
    }, [value, touched])

    const applyMinMax = (date, min, max) => {
        let finalDate = new Date(date)
        if (min) {
            finalDate = Math.max(min, finalDate)
        }
        if (max) {
            finalDate = Math.min(max, finalDate)
        }
        return new Date(finalDate).toISOString().split('T')[0]
    }

    const dateChanged = (evt) => {
        let date = evt.target.value
        if (!date) {
            valueChanged(null)
            setParsedValue(null)
            return
        }
        date = applyMinMax(date, min, max)

        if (format === 'date') {
            valueChanged(date)
            setParsedValue(parse(date))
            return
        }
        const [year, month, day] = date.split('-')
        let newValue = {
            day: parseInt(day),
            month: parseInt(month),
            year: parseInt(year),
        }
        valueChanged(newValue)
        setParsedValue(parse(newValue))
    }

    return (
        <div className={classes.formControlContainer}>
            <Grid container className={error ? classes.hasError : ''}>
                <Grid item xs style={{ position: 'relative' }}>
                    <TextField
                        id='date'
                        label={t(label)}
                        type='date'
                        className={classes.formControl}
                        value={parsedValue}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onBlur={dateChanged}
                        onChange={(evt) => {
                            setParsedValue(evt.target.value)
                        }}
                    />
                    {confirmEdit ? (
                        <LockMechanism setConfirmEdit={setConfirmEdit} />
                    ) : (
                        <></>
                    )}
                </Grid>
                {error ? (
                    <Grid item xs={12}>
                        <Typography className={classes.errorText}>
                            {error}
                        </Typography>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </div>
    )
}

export const ClearPropertyPopup = (props) => {
    const { label, open, anchor, setPopupOpen, onAffirm } = props
    const { t } = useTranslation()

    const classes = useStyles()

    return (
        <Popover
            id={`${label}-lock-popover`}
            open={open}
            anchorEl={anchor}
            onClose={() => setPopupOpen(false)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Card style={{ width: '340px' }}>
                <CardActionArea>
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.typography}>
                            {t('clear_oneof_property_warning')}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardActions}>
                    <Button
                        size='small'
                        color='primary'
                        onClick={(_) => setPopupOpen(false)}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        size='small'
                        color='primary'
                        onClick={(_) => {
                            onAffirm && onAffirm()
                        }}
                    >
                        {t('clear')}
                    </Button>
                </CardActions>
            </Card>
        </Popover>
    )
}

export const DynamicFragment = (props) => {
    const {
        path,
        level,
        values,
        touched,
        element,
        disabled,
        manualKey,
        overrides,
        requiredList,
        doubleColumn,
        valueChanged,
    } = props
    const { type, minimum, maximum, oneOf, title, format } = element
    const description = (overrides?.placeholder ?? {})[manualKey]
    const options = element.enum
    const finalType =
        manualKey === 'birthday' || format === 'date'
            ? 'date'
            : options?.length
            ? 'enum'
            : !!oneOf
            ? 'oneOf'
            : type
    const childrenAreDoubleColumn = (overrides?.doubleColumn ?? {})[title]

    const currentPath = () => `${path}/${manualKey}`

    switch (finalType) {
        case 'object':
            return (
                <Grid item xs={12} md={doubleColumn ? 6 : 12} key={manualKey}>
                    <DynamicElementGroup
                        path={path}
                        level={level}
                        touched={touched}
                        disabled={disabled}
                        label={title || manualKey}
                        overrides={overrides}
                        oneOf={element.oneOf}
                        elements={element.properties}
                        requiredList={element.required}
                        values={values ? values[manualKey] : null}
                        doubleColumn={childrenAreDoubleColumn}
                        required={requiredList?.indexOf(manualKey) > -1}
                        showFullView={diagnosisKeys.includes(title)}
                        valueChanged={(newValue) => {
                            const obj = { ...values }
                            obj[manualKey] = newValue
                                ? { ...obj[manualKey], ...newValue }
                                : null
                            valueChanged(obj, manualKey)
                        }}
                    />
                </Grid>
            )
        case 'array':
            return (
                <Grid item xs={12} md={doubleColumn ? 6 : 12} key={manualKey}>
                    <ArrayElement
                        level={level}
                        touched={touched}
                        label={title ?? manualKey}
                        value={values ? values[manualKey] : null}
                        required={requiredList?.indexOf(manualKey) > -1}
                        valueChanged={(newValue) => {
                            const obj = { ...values }
                            obj[manualKey] = newValue ? [...newValue] : null
                            valueChanged(obj, manualKey)
                        }}
                        overrides={overrides}
                        items={element.items}
                    />
                </Grid>
            )
        case 'date':
            return (
                <Grid item xs={12} md={doubleColumn ? 6 : 12} key={manualKey}>
                    <DateElement
                        format={format}
                        touched={touched}
                        label={title ?? manualKey}
                        overrides={overrides}
                        aria-label={currentPath()}
                        placeholder={description}
                        value={values ? values[manualKey] : null}
                        required={requiredList?.indexOf(manualKey) > -1}
                        valueChanged={(newValue) => {
                            const obj = { ...values }
                            if (format === 'date') {
                                obj[manualKey] = newValue
                            } else {
                                obj[manualKey] = newValue
                                    ? { ...obj[manualKey], ...newValue }
                                    : null
                            }
                            valueChanged(obj, manualKey)
                        }}
                    />
                </Grid>
            )

        default:
            return (
                <Grid item xs={12} md={doubleColumn ? 6 : 12} key={manualKey}>
                    <DynamicElement
                        path={path}
                        oneOf={oneOf}
                        level={level}
                        type={finalType}
                        touched={touched}
                        options={options}
                        minimum={minimum}
                        maximum={maximum}
                        overrides={overrides}
                        placeholder={description}
                        label={title ?? manualKey}
                        value={values ? values[manualKey] : null}
                        required={requiredList?.indexOf(manualKey) > -1}
                        valueChanged={(newValue) => {
                            const obj = { ...values }
                            obj[manualKey] = newValue ?? null
                            valueChanged(obj, manualKey)
                        }}
                    />
                </Grid>
            )
    }
}
