/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core'
import BrokenImageIcon from '@material-ui/icons/BrokenImage'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { DynamicForm } from '../dynamicForm/DynamicForm'
import { fetchJsonSchema } from '../dynamicForm/fetchJsonSchema'

import HeaderMenu from '../utils/HeaderMenu'
import { fetchParticipantInfo } from './fetchParticipantInfo'
import { FacilitiesDropDown } from './ParticipantsList/FacilitiesDropDown'
import {
    createFacility,
    fetchFacilities,
    updateFacility,
} from './ParticipantsList/fetchFacilities'
import { updateParticipantInfo } from './updateParticipantInfo'

export default function DemographicData() {
    const { t } = useTranslation()
    const { p_id } = useParams()

    const [participantInfo, setInfo] = useState('')
    const [schema, setSchema] = useState(null)
    const [schemaVersion, setSchemaVersion] = useState(null)
    const [facilities, setFacilities] = useState([])
    const [facilityId, setFacilityId] = useState(null)
    const [updatingParticipant, setUpdatingParticipant] = useState(false)

    useEffect(() => {
        fetchFacilities(setFacilities, t)
        fetchJsonSchema(setSchema, setSchemaVersion, t)
    }, [])
    useEffect(() => {
        fetchParticipantInfo(p_id, setInfo, schemaVersion, t)
    }, [schemaVersion])
    useEffect(() => {
        setFacilityId(participantInfo.facilityId || 'default')
    }, [participantInfo])

    return (
        <>
            <HeaderMenu
                backButtonLabel={t('Back_Participants')}
                backButtonPath='/participants'
                menuInfo={t('Participant_Info')}
            />
            {schema === 0 ? (
                <span className='deactivated'>
                    <BrokenImageIcon color='disabled' fontSize='large' />
                </span>
            ) : (
                <></>
            )}
            {!schema || !schemaVersion || !participantInfo ? (
                <Loading />
            ) : (
                <div>
                    <div>
                        <FacilitiesDropDown
                            showAll={false}
                            facilityId={facilityId}
                            onFacilityChange={setFacilityId}
                            facilities={facilities}
                            createFacility={async (_facility) => {
                                await createFacility(_facility, t)
                                fetchFacilities(setFacilities, t)
                            }}
                            updateFacility={async (_facility) => {
                                await updateFacility(_facility, t)
                                fetchFacilities(setFacilities, t)
                            }}
                            t={t}
                        />
                        <div>
                            <DynamicForm
                                version={schemaVersion}
                                values={{
                                    ...participantInfo,
                                    facilityId: null,
                                }}
                                schema={schema}
                                loading={updatingParticipant}
                                overrides={{
                                    disabled: {
                                        'root/diagnosis/mci_diagnosis/other_neuro_diseases/parkinsonsDisease': true,
                                        'root/diagnosis/mci_diagnosis/general_comorbidities/liverFailure': true,
                                        'root/diagnosis/mci_diagnosis/general_comorbidities/renalFailure': true,
                                        'root/diagnosis/mci_diagnosis/general_comorbidities/obtrusiveSleepApnea': true,
                                        'root/diagnosis/mci_diagnosis/general_comorbidities/traumaticBrainInjury': true,
                                        'root/diagnosis/mci_diagnosis/general_comorbidities/ischemicStroke': true,
                                        'root/diagnosis/mci_diagnosis/psychiatric_disorders/depression': true,
                                        'root/diagnosis/mci_diagnosis/psychiatric_disorders/anxietyDisorder': true,
                                        'root/diagnosis/mci_diagnosis/other_neuro_diseases/spaceOccupyingLesionsOfTheBrain': true,
                                        'root/diagnosis/mci_diagnosis/drug_abuse/alcoholism': true,
                                    },
                                    confirmEdit: {
                                        participantId: true,
                                        birthday: true,
                                        gender: true,
                                        language: true,
                                    },
                                    doubleColumn: {
                                        medication: true,
                                        healthy_diagnosis: true,
                                        dementia_diagnosis: true,
                                        mci_diagnosis: true,
                                        other_diagnosis: true,
                                    },
                                    dateLimits: {
                                        clinical_examination_date: {
                                            min: new Date(
                                                participantInfo.birthday.year,
                                                participantInfo.birthday.month -
                                                    1,
                                                participantInfo.birthday.day +
                                                    1,
                                            ),
                                            max: new Date(),
                                        },
                                    },
                                }}
                                onSubmit={async (values) => {
                                    setUpdatingParticipant(true)
                                    await updateParticipantInfo(
                                        p_id,
                                        values,
                                        facilityId === 'default'
                                            ? null
                                            : facilityId,
                                        t,
                                    )
                                    setUpdatingParticipant(false)
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

function Loading() {
    return (
        <div align='center' style={{ marginTop: '30vh' }}>
            <CircularProgress />
        </div>
    )
}
