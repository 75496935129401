import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@material-ui/core'

export default function VisitsHeader() {
    const { t } = useTranslation()

    return (
        <>
            <TableRow>
                <TableCell width='50%' align='left'>
                    {t('visit_ID')}
                </TableCell>
                <TableCell align='left'> {t('visit_location')} </TableCell>
                <TableCell align='left'> {t('visit_date')} </TableCell>
                <TableCell align='left'> {t('visit_status')} </TableCell>
            </TableRow>
        </>
    )
}
