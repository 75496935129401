/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, makeStyles } from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'
import { typography } from '../utils/typography'

import { Recorder } from '../utils/Recorder'

const useStyles = makeStyles({
    root: {
        maxWidth: 600,
        margin: 'auto',
        marginTop: 20,
        marginBottom: 20,
        padding: 5,
        fontFamily: 'monospace',
    },
    pageTitle: {
        fontSize: '16px !important',
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
    },
    value: {
        textAlign: 'center',
    },
})

const audioTypes = ['flac', 'mp3', 'ogg', 'opus', 'wav', 'weba', 'webm']

const supportsAudioType = async (extension) => {
    return new Promise((resolve) => {
        const audio = document.createElement('audio')
        audio.onerror = (err) => {
            resolve({
                supported: 'no',
                player: audio,
                error: err.currentTarget.error.message,
            })
        }
        audio.oncanplay = (_) => {
            resolve({ supported: 'yes', player: audio })
        }

        audio.src = `/diagnostics/test-audio.${extension}`
    })
}

export const Diagnostics = () => {
    const [recDuration] = useState(10)
    const [supportedTypes, setSupportedTypes] = useState([])

    const classes = useStyles()

    useEffect(() => {
        const promises = audioTypes.map(async (value) => {
            const { supported, player, error } = await supportsAudioType(value)
            return {
                key: value,
                supported,
                player,
                error,
            }
        })
        Promise.all(promises).then((formats) => {
            setSupportedTypes(formats)
        })
    }, [])

    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} className={classes.pageTitle}>
                {typography('h4', null, 'Media format compatibility')}
            </Grid>
            {supportedTypes.map((type) => (
                <Grid
                    key={type.key}
                    item
                    container
                    xs={12}
                    className={`supported-format ${type.supported}`}
                >
                    <Grid item xs className={classes.title}>
                        {type.key}:
                    </Grid>
                    <Grid item xs className={classes.supported}>
                        {type.supported}
                    </Grid>
                    {type.error ? (
                        <Grid item xs>
                            {type.error}
                        </Grid>
                    ) : (
                        <Grid item xs>
                            <audio controls={true} src={type.player.src} />
                        </Grid>
                    )}
                </Grid>
            ))}
            <Grid item xs={12}>
                <Recorder
                    enableStart
                    displayType={true}
                    enableTimer={recDuration}
                    notifyAfter={recDuration - 5}
                    onStop={(blob) => {
                        // setBlob(blob)
                    }}
                    onDiscard={() => {
                        // setBlob(null)
                    }}
                />
            </Grid>
        </Grid>
    )
}
