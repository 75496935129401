/**
 * A helper function used to ensure that participant's
 * language is set before initiating the upload.
 *
 * @param {string} p_id Participant ID.
 *
 * @returns {Promise} Either resolve with the language of the participant or throw an error.
 */

import { getAuthenticatedProps } from '../customHooks/useRemoteAuth'

export function fetchParticipantLang(p_id, history) {
    return new Promise(function (ok, not_ok) {
        const url = `${window._env_.REACT_APP_BACKEND_URL}/participants/${p_id}`

        const { endpoint, headerz } = getAuthenticatedProps(url)

        const requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: headerz,
        }

        fetch(endpoint, requestOptions)
            .then((resp) => {
                resp.json().then((res) => {
                    if (resp.ok) {
                        return ok(res.language)
                    } else if (history && res.message === 'Invalid remote URL.') {
                        history.push('/invalid-url')
                    } else {
                        not_ok(
                            resp.status + ' : Error during participant data fetch.',
                        )
                    }
                })
            })
            .catch((err) => {
                console.error(err)
            })
    })
}
