import { IconButton, Menu, MenuItem } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useState } from 'react'

export const ThreeDotMenu = (props) => {
    const { onMenuItemSelected, options = [] } = props
    const [anchor, setAnchor] = useState()
    const { t } = useTranslation()

    const handleClick = (event) => {
        setAnchor(event.currentTarget)
    }

    const handleClose = (menuItemId) => {
        setAnchor(null)
        if (onMenuItemSelected && typeof menuItemId === 'string') {
            onMenuItemSelected(menuItemId)
        }
    }

    const menuItems = options.map((option) => (
        <MenuItem
            key={option.name}
            onClick={() => {
                handleClose(option.name)
            }}
            disabled={option.disabled}
        >
            {t(option.name)}
        </MenuItem>
    ))

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='simple-menu'
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
            >
                {menuItems}
            </Menu>
        </>
    )
}
