/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { LoadableButton } from '../../utils/LoadableButton'
import add_visit from './_add_visit'
import { fetchVisitOffset } from './fetchVisitOffset'
import { InfoButton } from './InfoButton'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        margin: 0,
    },
    text: {
        position: 'absolute',
        top: '-20px',
        textAlign: 'right',
        paddingRight: '4px',
        whiteSpace: 'nowrap',
    },
    visitActiveContainer: {
        height: '49px',
        display: 'flex',
        fontSize: '16px',
        textAlign: 'right',
        alignItems: 'center',
        fontWeight: 'bold',
        borderRadius: '8px',
        justifyContent: 'space-between',
        padding: '20px',
        background: '#ffc00020',
        color: '#FFC000',
    },
}))

const AddVisitButton = (props) => {
    const {
        visitList,
        setVisitList,
        loadingNewVisit,
        setLoadingNewVisit,
        activeVisitExists,
        setActiveVisitExists,
        participantId,
    } = props

    const { p_id } = useParams()
    const { t } = useTranslation()
    const [visitOffset, setVisitOffset] = useState(-1)

    const classes = useStyles()

    useEffect(() => {
        if (activeVisitExists) {
            return
        }
        fetchVisitOffset(participantId, setVisitOffset)
    }, [participantId])

    useEffect(() => {
        if (visitOffset < 1) {
            return
        }
        setTimeout(() => {
            setVisitOffset(visitOffset - 1)
        }, 1000)
    }, [visitOffset])

    const convertToTime = (seconds) => {
        return new Date(seconds * 1000).toISOString().substr(11, 8)
    }

    return (
        <div className={classes.container}>
            {activeVisitExists ? (
                <Grid item>
                    <div className={classes.visitActiveContainer}>
                        <Typography>{t('visit_is_active')}</Typography>
                        <InfoButton
                            fontSize='small'
                            style={{ color: '#FFC000' }}
                            message={t('visit_is_active_explanation')}
                        />
                    </div>
                </Grid>
            ) : visitOffset > 0 && activeVisitExists === false ? (
                <Grid item>
                    <div className={classes.visitActiveContainer}>
                        <Typography>{`${t(
                            'visit_creation_time',
                        )}: ${convertToTime(visitOffset)}`}</Typography>
                        <InfoButton
                            fontSize='small'
                            style={{ color: '#FFC000' }}
                            message={t('visit_is_active_explanation')}
                        />
                    </div>
                </Grid>
            ) : (
                <Grid container spacing={1} className={classes.textContainer}>
                    <Grid item>
                        <InfoButton
                            fontSize='small'
                            message={t('remote_visit_help')}
                        />
                        <LoadableButton
                            {...{
                                p_id,
                                visitList,
                                disabled:
                                    activeVisitExists || visitOffset !== 0,
                                loading: visitOffset === -1 || loadingNewVisit,
                                error: visitOffset === -2,
                                variant: 'contained',
                                label: t('addNewRemoteTest'),
                                onClick: () =>
                                    add_visit(
                                        p_id,
                                        visitList,
                                        setVisitList,
                                        setLoadingNewVisit,
                                        setActiveVisitExists,
                                        'remote',
                                    ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <LoadableButton
                            {...{
                                p_id,
                                visitList,
                                disabled:
                                    activeVisitExists || visitOffset !== 0,
                                loading: visitOffset === -1 || loadingNewVisit,
                                error: visitOffset === -2,
                                variant: 'outlined',
                                label: t('addNewTest'),
                                onClick: () =>
                                    add_visit(
                                        p_id,
                                        visitList,
                                        setVisitList,
                                        setLoadingNewVisit,
                                        setActiveVisitExists,
                                    ),
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export { AddVisitButton }
