import React, { useState } from 'react'
import { Route } from 'react-router-dom'

/**
 * A wrapper for <Route> that detects if an admin route is used.
*/
export default function AdminRoute({ children, ...rest }) {

	const [isAdmin, setIsAdmin] = useState(true)
	/*
		The idea is that 'isAdmin' will be set to true
		once successful authentication via user's
		LANGaware account is performed.
	*/

	return (
		<Route {...rest} render={() =>
			isAdmin ?
				React.cloneElement(children, { isAdmin: isAdmin })
				:
				<GoogleAuth setIsAdmin={setIsAdmin} />
		} />
	);

	/*
		What is returned?
		If the user is not authenticated, show an 'auth' button (the supposed OAuth that should take place).
		If authentication is already complete, render children with an extra 'isAdmin' prop.
	*/
}

function GoogleAuth(props) {

	const { setIsAdmin } = props

	return <button onClick={() => setIsAdmin(true)}>auth</button>
}