import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import DoneIcon from '@material-ui/icons/Done'
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Typography,
    Paper,
} from '@material-ui/core'
import _render_charts from './_render_charts'
import {
    admin_fetch_results,
    admin_fetch_visit_results,
    admin_update_assessment_status,
    admin_update_visit_assessments_status,
} from './_admin_fetch_results'

export default function AdminResultsPage() {
    const { p_id, v_id, assessment, assessment_id } = useParams()
    const classes = useStyles()
    const [results, setResults] = useState(null)
    const [result_color, setResultColor] = useState('lightgrey')
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()
    let history = useHistory()

    useEffect(() => {
        if (assessment && assessment_id) {
            // Administrative Assessment results endpoint
            admin_fetch_results(
                assessment_id,
                setResults,
                setResultColor,
                setLoading,
            )
        } else {
            // Administrative Visits results endpoint
            admin_fetch_visit_results(
                v_id,
                setResults,
                setResultColor,
                setLoading,
            )
        }
    }, [assessment, assessment_id, v_id])

    useEffect(() => {
        let container1 = document.getElementById('bar-chart')
        let container2 = document.getElementById('radar-chart')

        let _refs

        if (
            container1 !== null &&
            container2 !== null &&
            results !== null &&
            !results.message
        ) {
            _refs = _render_charts(results, container1, container2, t)
        }

        return function cleanupCharts() {
            if (_refs) {
                _refs.radarchart_ref.destroy()
                _refs.barchart_ref.destroy()
            }
        }
    }, [loading, results, t])

    return (
        <div>
            <Dialog fullScreen open={true}>
                <Box
                    display='flex'
                    justifyContent='flex-end'
                    alignItems='center'
                >
                    <Typography>{t('results_close_prompt')}</Typography>
                    <IconButton
                        onClick={() => {
                            history.replace(
                                '/participants/' + p_id + '/visits/' + v_id,
                            )
                        }}
                    >
                        <HighlightOffIcon />
                    </IconButton>
                </Box>

                {loading ? (
                    <div align='center' style={{ marginTop: 100 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <DialogTitle id='scroll-dialog-title'>
                            <Paper
                                style={{
                                    backgroundColor: result_color,
                                    padding: 10,
                                }}
                            >
                                {results.prediction ? (
                                    <Typography align='left'>
                                        {t(
                                            'results_output_' +
                                                results.prediction,
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography align='left'>
                                        {t('results_output_not_ready')}
                                    </Typography>
                                )}
                            </Paper>

                            <Paper
                                style={{
                                    backgroundColor: '#e5f6f5',
                                    padding: 10,
                                }}
                            >
                                {assessment && assessment_id ? (
                                    <>
                                        <Box
                                            height={1}
                                            width={1}
                                            display='flex'
                                            alignItems='stretch'
                                            flexDirection='row'
                                        >
                                            <Box
                                                minHeight={1}
                                                flexGrow={1}
                                                mx={0.5}
                                            >
                                                <Typography align='left'>
                                                    {"Participant's performed task: " +
                                                        results.task}
                                                </Typography>
                                                <AudioPlayer
                                                    assessment_uuid={
                                                        assessment_id
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Box
                                            height={1}
                                            width={1}
                                            display='flex'
                                            alignItems='stretch'
                                            flexDirection='row'
                                        >
                                            <Box
                                                minHeight={1}
                                                flexGrow={1}
                                                mx={0.5}
                                            >
                                                <Typography align='left'>
                                                    {'Aggregation Policy supported tasks: ' +
                                                        results.policy_tasks}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            height={1}
                                            width={1}
                                            display='flex'
                                            alignItems='stretch'
                                            flexDirection='row'
                                        >
                                            <Box
                                                minHeight={1}
                                                flexGrow={1}
                                                mx={0.5}
                                            >
                                                <Typography align='left'>
                                                    {"Participant's performed tasks: " +
                                                        results.performed_tasks}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </>
                                )}

                                <Box
                                    height={1}
                                    width={1}
                                    display='flex'
                                    alignItems='stretch'
                                    flexDirection='row'
                                >
                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'Participant: ' +
                                                results.participant_shortcode}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    height={1}
                                    width={1}
                                    display='flex'
                                    alignItems='stretch'
                                    flexDirection='row'
                                >
                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'True label (diagnosis or source data): ' +
                                                results.label}
                                        </Typography>
                                    </Box>

                                    <Box
                                        minHeight={1}
                                        flexGrow={1}
                                        mx={0.5}
                                        style={{
                                            backgroundColor: result_color,
                                        }}
                                    >
                                        <Typography align='left'>
                                            {'ML Prediction: ' +
                                                results.prediction}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    height={1}
                                    width={1}
                                    display='flex'
                                    alignItems='stretch'
                                    flexDirection='row'
                                >
                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'Age: ' + results.age}
                                        </Typography>
                                    </Box>

                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'MMSE score: ' + results.mmse}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    height={1}
                                    width={1}
                                    display='flex'
                                    alignItems='stretch'
                                    flexDirection='row'
                                >
                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'Confidence: ' +
                                                results.confidence}
                                        </Typography>
                                    </Box>

                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'AD Confidence: ' +
                                                results.ad_confidence}
                                        </Typography>
                                    </Box>

                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'Profiling Distance: ' +
                                                results.profiling_distance}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    height={1}
                                    width={1}
                                    display='flex'
                                    alignItems='stretch'
                                    flexDirection='row'
                                >
                                    <Box minHeight={1} flexGrow={1} mx={0.5}>
                                        <Typography align='left'>
                                            {'Status: ' + results.status}
                                        </Typography>
                                    </Box>
                                    {results.status === 'completed' ? null : (
                                        <MarkCompleteButton
                                            p_id={p_id}
                                            v_id={v_id}
                                            assessment={assessment}
                                            assessment_id={assessment_id}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </DialogTitle>

                        <DialogContent id='scroll-dialog-content'>
                            {/* https://material-ui.com/system/sizing/ */}
                            <Box
                                height={1}
                                width={1}
                                display='flex'
                                alignItems='center'
                                flexDirection='column'
                            >
                                <Box minHeight={1} flexGrow={1} mx={0.5}>
                                    <div
                                        id='radar-chart-container'
                                        className={classes.childChartContainer}
                                    >
                                        <canvas id='radar-chart' />
                                    </div>
                                </Box>

                                <Box minHeight={1} flexGrow={1} mx={0.5}>
                                    <div
                                        id='bar-chart-container'
                                        className={classes.childChartContainer}
                                    >
                                        <canvas id='bar-chart' />
                                    </div>
                                </Box>
                            </Box>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </div>
    )
}

/**
 * This snippet alters chart presentation
 * mode based on a width breakpoint.
 */
const useStyles = makeStyles((theme) => ({
    parentChartContainer: {
        [theme.breakpoints.down('md')]: { flexDirection: 'column' },
        [theme.breakpoints.up('md')]: { flexDirection: 'row' },
    },
    childChartContainer: {
        [theme.breakpoints.down('md')]: { position: 'relative', width: '90vw' },
        [theme.breakpoints.up('md')]: { position: 'relative', width: '40vw' },
    },
}))

function reload_page() {
    window.location.reload()
}

function MarkCompleteButton(props) {
    const [enabled, setEnabled] = useState(true)
    const { t } = useTranslation()

    const button_label =
        props.assessment && props.assessment_id
            ? 'Mark Task as Completed'
            : 'Mark Visit as Completed'

    const buttonCallback = () => {
        setEnabled(false)
        if (props.assessment && props.assessment_id) {
            admin_update_assessment_status(
                props.assessment_id,
                'completed',
                setEnabled,
            ).then(
                (value) => {
                    toast.success(t(value), {
                        autoClose: 1500,
                        hideProgressBar: false,
                        onClose: reload_page,
                    })
                },
                (reason) => {
                    toast.error(t(reason))
                },
            )
        } else {
            admin_update_visit_assessments_status(
                props.v_id,
                'completed',
                setEnabled,
            ).then(
                (value) => {
                    toast.success(t(value), {
                        autoClose: 1500,
                        hideProgressBar: false,
                        onClose: reload_page,
                    })
                },
                (reason) => {
                    toast.error(t(reason))
                },
            )
        }
    }

    const button = (
        <Button
            variant='contained'
            onClick={buttonCallback}
            startIcon={<DoneIcon />}
            disabled={!enabled}
        >
            {button_label}
        </Button>
    )

    return button
}

function AudioPlayer(props) {
    const { assessment_uuid } = props
    const audioUrlPrefix = useMemo(
        () =>
            `${window._env_.REACT_APP_BACKEND_URL}/assessments/admin/audio/${assessment_uuid}`,
        [assessment_uuid],
    )
    const [audioFilename, setAudioFilename] = useState('playback.flac')
    const [blobUrl, setBlobUrl] = useState(undefined)
    const [error, setError] = useState(undefined)
    const classes = useStyles()

    useEffect(() => {
        fetch(`${audioUrlPrefix}/${audioFilename}`).then((response) => {
            if (response.status === 200) {
                response
                    .blob()
                    .then((blob) => {
                        setBlobUrl(URL.createObjectURL(blob))
                    })
                    .catch((err) => {
                        setError(err)
                    })
            } else if (audioFilename === 'playback.flac') {
                setAudioFilename('audio.data')
            } else if (audioFilename === 'audio.data') {
                setAudioFilename('audio.ogg')
            } else {
                setError('No audio file available!')
            }
        })
    }, [assessment_uuid, audioFilename, audioUrlPrefix])

    return (
        <div>
            {!blobUrl && !error && <LinearProgress />}
            {blobUrl && (
                <audio
                    src={blobUrl}
                    style={{ width: '100%' }}
                    controls={true}
                ></audio>
            )}
            {error && (
                <div className={classes.error}>
                    Something went wrong. Try refreshing the page.
                </div>
            )}
        </div>
    )
}
