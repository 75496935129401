import { AppBar, Button, MenuItem, Select } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ReactComponent as LogoHead } from '../../assets/logos/logo_head.svg'
import { ReactComponent as LogoLetters } from '../../assets/logos/logo_letters.svg'
import auth from '../stateObjects/auth.state'
import ConsentModal from './ConsentModal'

const availableLanguages = ['en-US', 'el-GR', 'es-ES']
const languagesMap = {
    'en-US': 'EN',
    'el-GR': 'GR',
    'es-ES': 'ES',
}

export default function TopBar(props) {
    const { i18n } = useTranslation()
    const [language, setLanguage] = useState('')

    const userConsent = window.localStorage.getItem('LANGawareUserConsent')

    const changeLanguage = useCallback(
        (value) => {
            window.localStorage.setItem('LANGawareLocale', value)
            setTimeout(() => {
                i18n.changeLanguage(value)
            }, 0)
            setLanguage(value)
        },
        [i18n],
    )
    const handleChange = (event) => {
        const { value } = event.target
        changeLanguage(value)
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const langParameter = availableLanguages.find(
            (lang) => lang === queryParams.get('lang'),
        )
        const storageLanguage = window.localStorage.getItem('LANGawareLocale')
        changeLanguage(langParameter || storageLanguage || 'en-US')
    }, [changeLanguage])

    return (
        <section {...props}>
            <AppBar position='absolute' color='default'>
                <div className='top-bar-inner-container'>
                    <div className='logo-container'>
                        <a href='/'>
                            <LogoHead
                                style={{
                                    maxHeight: 58,
                                    maxWidth: 58,
                                }}
                            />
                            <LogoLetters
                                style={{
                                    maxHeight: 50,
                                    maxWidth: 200,
                                }}
                            />
                        </a>
                    </div>
                    <div className='header-actionable-containers'>
                        <Select
                            labelId='language-select-label'
                            value={language}
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDown}
                        >
                            {/* TODO: Get list of localization languages from
                            localization files instead of having a static list
                            here. */}
                            {availableLanguages.map((lang) => (
                                <MenuItem value={lang}>
                                    {languagesMap[lang]}
                                </MenuItem>
                            ))}
                        </Select>
                        <SignOutButton />
                    </div>
                </div>
            </AppBar>

            {userConsent ? null : <ConsentModal />}
        </section>
    )
}

function SignOutButton() {
    const history = useHistory()
    const { t } = useTranslation()

    const signoutCallback = () => {
        auth.state.signout(() => {
            history.push('/')

            /* in order to force this component to re-render */
            window.location.reload()
        })
    }

    return sessionStorage.getItem('token') ? (
        <Button onClick={signoutCallback} variant='text' className={'inverted'}>
            {t('sign_out')}
        </Button>
    ) : null
}
