/**
 * Ensure that all three arrays (AD,NC,Sample) under the
 * "profiling" property of the response contain the same
 * feature categories. If not, throw an error.
 * @param {Object} profiling 
 */
export default function validate_profiling(profiling) {
   
    const _AD = profiling.AD
    const _NC = profiling.NC
    const _Sa = profiling.Sample

    const _AD_Set = new Set()
    const _NC_Set = new Set()
    const _Sa_Set = new Set()
    
    /**
     * A handy one liner that allows set comparison.
     */
    const isSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value))

    _AD.forEach(entry => _AD_Set.add(entry.feature) )
    _NC.forEach(entry => _NC_Set.add(entry.feature) )
    _Sa.forEach(entry => _Sa_Set.add(entry.feature) )
    
    const AD_equals_NC = isSetsEqual(_AD_Set,_NC_Set)
    const AD_equals_Sa = isSetsEqual(_AD_Set,_Sa_Set)

    if (!(AD_equals_NC && AD_equals_Sa)) {
        throw new Error('validate_profiling function failed')
    }
}
