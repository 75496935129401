import { toast } from 'react-toastify'

export function fetchFacilities(setFacilities, t) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = window._env_.REACT_APP_BACKEND_URL + '/facility/list'
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    setFacilities(r)
                })
            } else {
                toast.error(t('FacilitiesListRetrievalFailure'))
            }
        })
        .catch((err) => {
            toast.error(t('FacilitiesListRetrievalFailure'))
        })
}

export function createFacility(facility, t) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = window._env_.REACT_APP_BACKEND_URL + '/facility/create'
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())
    headerz.append('Content-Type', 'application/json')

    let requestOptions = {
        method: 'POST',
        headers: headerz,
        body: JSON.stringify(facility),
    }

    return fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    toast.success(t('add_facility_success'))
                })
            } else {
                toast.error(t('add_facility_failure'))
            }
        })
        .catch((err) => {
            toast.error(t('add_facility_failure'))
        })
}

export function updateFacility(facility, t) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/facility/${facility.facility_id}/update`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())
    headerz.append('Content-Type', 'application/json')

    let requestOptions = {
        method: 'POST',
        headers: headerz,
        body: JSON.stringify(facility),
    }

    return fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    toast.success(t('update_facility_success'))
                })
            } else {
                toast.error(t('update_facility_failure'))
            }
        })
        .catch((err) => {
            toast.error(t('update_facility_failure'))
        })
}
