/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MicIcon from '@material-ui/icons/Mic'
import PauseIcon from '@material-ui/icons/Pause'
import StopIcon from '@material-ui/icons/Stop'
import DeleteIcon from '@material-ui/icons/Delete'

import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const audio = {
    tag: 'audio',
    gUM: { audio: true },
}

let chunks, media
media = audio

const useStyles = makeStyles({
    buttonsContainer: {
        padding: '0 10px',
    },
    buttonContainers: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        width: '100%',
    },
    mediaContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export const Recorder = (props) => {
    const { displayType } = props
    const { t } = useTranslation()

    const [state, setState] = useState({
        disableStart: !props.enableStart,
        disablePause: true,
        disableStop: true,
        disableDiscard: true,
        micColor: 'white',
        notifyAfter: props.notifyAfter,
        recorder: null,
    })
    const [recorder, setRecorder] = useState(null)
    const [timerId, setTimerId] = useState(null)
    const [timerReading, setTimerReading] = useState(props.enableTimer)

    const classes = useStyles()

    useEffect(() => {
        if (recorder) {
            discardRec()
            requestMediaPermission()
        }

        setState({
            ...state,
            disableStart: !props.enableStart,
            disablePause: true,
            disableStop: true,
            disableDiscard: true,
        })
    }, [])

    const requestMediaPermission = () => {
        // ================================================================================================================= //
        // SEE: https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts
        // AND: https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts/features_restricted_to_secure_contexts

        // window.isSecureContext
        // navigator.mediaDevices.enumerateDevices().then(r => { /* Inspect r */})
        // ================================================================================================================= //

        navigator.mediaDevices
            .getUserMedia(media.gUM)
            .then((stream) => {
                /*  create a new recording object
                    and enable the rec button ONLY
                    if permission is granted!
                */
                const recorder = new MediaRecorder(stream)

                // ===================== HIGH QUALITY VARIANT ========================= //
                // recorder = new MediaRecorder(stream,{ audioBitsPerSecond : 320000 })
                // ==================================================================== //

                recorder.ondataavailable = (e) => {
                    /* add audio chunk to array */
                    chunks.push(e.data)

                    if (recorder.state === 'inactive') {
                        const blob = new Blob(chunks, {
                            type: chunks[0]?.type,
                        })
                        const url = URL.createObjectURL(blob)
                        const innerContainer = document.createElement('div')
                        const mediaTag = document.createElement(media.tag)

                        mediaTag.onerror = (err) =>
                            toast.error(t('BrowserNotSupportsPlayback'), {
                                position: 'top-center',
                                autoClose: false,
                                hideProgressBar: true,
                            })

                        innerContainer.id = 'recPlayer'
                        mediaTag.src = url

                        props.onStop(blob)

                        mediaTag.id = 'audioPreview'
                        mediaTag.controls = true

                        const outerContainer =
                            document.getElementById('recordings-list')

                        if (displayType) {
                            const center = document.createElement('center')
                            center.classList.add('recorder-timer')
                            center.classList.add('noselect')
                            center.innerHTML = blob.type
                            innerContainer.appendChild(center)
                        }
                        innerContainer.appendChild(mediaTag)
                        outerContainer.appendChild(innerContainer)
                    }
                }

                setRecorder(recorder)
            })
            .catch((err) => {
                toast.error(`Something went wrong - ${err}`, {
                    position: 'top-right',
                    autoClose: false,
                    hideProgressBar: true,
                })
            })
    }

    const startRec = () => {
        setState({
            ...state,
            disableStart: true,
            disablePause: false,
            disableStop: false,
            micColor: 'red',
        })

        /*
            audio storage
            defined at the top of the file
        */
        chunks = []

        if (recorder.state === 'paused') {
            recorder.resume()
        } else {
            recorder.start()
        }

        if (props.enableTimer) {
            let display = document.querySelector('#time')
            startTimer(timerReading, state.notifyAfter, display, stopRec)
        }

        toast.info(t('RecordingStart'), {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
        })
    }

    const pauseRec = () => {
        let display

        if (props.enableTimer) {
            clearInterval(timerId)
            // setTimerId(null)
            display = document.querySelector('#time').innerHTML
            const timerParts = display.split(':').reverse()
            var secondsRemaining =
                (+timerParts[0] || 0) +
                (+timerParts[1] || 0) * 60 +
                (+timerParts[2] || 0) * 60 * 60
            setTimerReading(secondsRemaining)
        }

        /*
            TODO: this only remembers seconds
            one more slice is required for minutes
            along with a *60 factor and a sum
            to store the whole thing as seconds
        */

        setState({
            ...state,
            disableStart: false,
            disablePause: true,
            disableStop: false,
            micColor: 'white',
        })

        recorder.pause()

        toast.warn(t('RecordingPause'), {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
        })
    }

    const startTimer = (duration, notifyAfter, display, stopRec) => {
        var timer = duration - 1,
            minutes,
            seconds
        var translation = t

        const _timerId = setInterval(function () {
            minutes = parseInt(timer / 60, 10)
            seconds = parseInt(timer % 60, 10)

            minutes = minutes < 10 ? '0' + minutes : minutes
            seconds = seconds < 10 ? '0' + seconds : seconds

            display.textContent = minutes + ':' + seconds

            if (timer === duration - notifyAfter) {
                toast.warn(
                    `${duration - notifyAfter} ${translation(
                        'RecordingSecsRemaining',
                    )}`,
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                    },
                )
            }

            /*
                this is the timer's core operation!
                using prefix means decrement and THEN return the value
                https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Decrement#description
            */
            if (--timer < 0) {
                timer = 0
                stopRec()
                clearTimeout(_timerId)
            }
        }, 1100)

        setTimerId(_timerId)
    }

    const stopRec = () => {
        recorder.stop()
        clearTimeout(timerId)

        setState({
            ...state,
            disableStart: true,
            disableDiscard: false,
            disablePause: true,
            disableStop: true,
            micColor: 'white',
        })

        toast.info(t('RecordingStop'), {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
        })
    }

    const discardRec = () => {
        if (props.enableTimer) {
            /*
                TODO: this code chunk and its little brother
                at the start of render() could be merged into
                a single method, e.g. resetTimer()
            */

            const display = document.querySelector('#time')
            const timer = props.enableTimer
            let minutes = parseInt(timer / 60, 10)
            let seconds = parseInt(timer % 60, 10)
            minutes = minutes < 10 ? '0' + minutes : minutes
            seconds = seconds < 10 ? '0' + seconds : seconds

            const initialTimerDisplay = minutes + ':' + seconds

            display.textContent = initialTimerDisplay

            setTimerReading(props.enableTimer)
        }

        const innerContainer = document.getElementById('recPlayer')
        if (innerContainer) {
            innerContainer.remove()

            props.onDiscard()

            setState({
                ...state,
                disableStart: false,
                disablePause: true,
                disableStop: true,
                disableDiscard: true,
            })
        }

        toast(t('recording_deleted'), {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
        })
    }

    let initialTimerDisplay

    if (props.enableTimer) {
        const timer = props.enableTimer
        let minutes = parseInt(timer / 60, 10)
        let seconds = parseInt(timer % 60, 10)
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        initialTimerDisplay = minutes + ':' + seconds
    }

    return (
        <div className={classes.buttonsContainer}>
            {props.enableTimer ? (
                <div className='recorder-timer noselect'>
                    <span id='time'>{initialTimerDisplay}</span>
                </div>
            ) : (
                <></>
            )}

            <Grid container spacing={1}>
                {!recorder ? (
                    <>
                        <Grid item xs={12} className={classes.buttonContainers}>
                            <Button
                                id='start'
                                variant='outlined'
                                className={classes.button}
                                onClick={() => requestMediaPermission()}
                            >
                                {t('enable_microphone')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            id='recordings-list'
                            className={classes.mediaContainer}
                        ></Grid>
                    </>
                ) : (
                    <>
                        <Grid
                            item
                            xs={6}
                            lg={12}
                            className={classes.buttonContainers}
                        >
                            <Button
                                id='start'
                                variant='outlined'
                                onClick={() => startRec()}
                                className={classes.button}
                                disabled={state.disableStart}
                            >
                                <MicIcon style={{ color: state.micColor }} />
                                {t('RecorderStart')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            lg={12}
                            className={classes.buttonContainers}
                        >
                            <Button
                                id='pause'
                                variant='outlined'
                                onClick={pauseRec}
                                className={classes.button}
                                disabled={state.disablePause}
                            >
                                <PauseIcon />
                                {t('RecorderPause')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            lg={12}
                            className={classes.buttonContainers}
                        >
                            <Button
                                id='stop'
                                onClick={stopRec}
                                variant='outlined'
                                className={classes.button}
                                disabled={state.disableStop}
                            >
                                <StopIcon /> {t('RecorderStop')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            lg={12}
                            className={classes.buttonContainers}
                        >
                            <Button
                                id='discard'
                                variant='outlined'
                                onClick={discardRec}
                                className={classes.button}
                                disabled={state.disableDiscard}
                            >
                                <DeleteIcon /> {t('RecorderDiscard')}
                            </Button>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            id='recordings-list'
                            className={classes.mediaContainer}
                        ></Grid>
                    </>
                )}
            </Grid>
        </div>
    )
}

// export default withTranslation()(Recorder)

// Recorder.propTypes = {
//     enableStart: PropTypes.bool.isRequired,
//     onStop: PropTypes.func.isRequired,
//     onDiscard: PropTypes.func.isRequired,
//     enableTimer: PropTypes.number,
//     notifyAfter: PropTypes.number,
// }
