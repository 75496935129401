import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { NavigationAccordion } from '../../utils/NavigationAccordion'
import LinkToAssessment from './LinkToAssessment'

export default function AssessmentCategory(props) {
    const { t } = useTranslation()
    const {
        total,
        failed,
        sublist,
        pending,
        completed,
        enableLink = true,
        displayStatus = true,
    } = props
    const assessments = sublist.assessments

    const subTitle = displayStatus && (
        <>
            <span
                style={{ color: completed === total ? '#01a74b' : '#8b8b8b' }}
            >{`${t('completed')}: ${completed} | `}</span>
            {!!pending && (
                <span style={{ color: 'orange' }}>{`${t(
                    'pending',
                )}: ${pending} | `}</span>
            )}
            {!!failed && (
                <span style={{ color: 'red' }}>{`${t(
                    'failed',
                )}: ${failed} | `}</span>
            )}
            <span style={{ fontWeight: 'bolder' }}>{`${t(
                'total',
            )}: ${total} `}</span>
        </>
    )

    return (
        <NavigationAccordion
            direction='left'
            subtitle={subTitle}
            backlabel={t('Assessments')}
            label={t('category_' + sublist.category)}
        >
            <Grid container>
                {assessments.map((obj) => {
                    /*
                        the assessment category is one level above
                        in the JSON list, so we add it manually
                        to each assessment entry
                        */
                    const category = { category: sublist.category }
                    const _obj = { ...category, ...obj }
                    return (
                        <Grid item xs={12} key={obj.path}>
                            <LinkToAssessment
                                key={obj.path}
                                enableLink={enableLink}
                                displayStatus={displayStatus}
                                assessmentInfo={_obj}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </NavigationAccordion>
    )
}
