import { toast } from 'react-toastify'
import Retry from '../../utils/Retry'

export default function add_visit(
	p_id,
	visitList,
	setVisitList,
	setLoadingNewVisit,
	setActiveVisitExists,
	type = null
) {
	let authToken = sessionStorage.getItem('token')
	let endpoint =
		`${window._env_.REACT_APP_BACKEND_URL}/visits/${p_id}/create`
	let headerz = new Headers()
	headerz.append('X-Alzheimer-Service-Token', authToken.toString())
	headerz.append("Content-Type", "application/json")

	let requestOptions = {
		method: 'POST',
		headers: headerz,
		redirect: 'follow',
		body: JSON.stringify({ type })
	}

	setLoadingNewVisit(true)

	fetch(endpoint, requestOptions)
		.then((response) => {
			setLoadingNewVisit(false)

			if (response.status === 200) {
				response.json().then((r) => {
					setVisitList([...visitList, r])
					setActiveVisitExists(true)
				})
			} else {
				response.json().then((res) => {
					const message = res.message.indexOf(
						'Cannot create another visit so close to',
					) > -1
						? 'cannot_create_another_visit'
						: ''
					toast.error(<Retry messageCode={message} />)
				})
			}
		})
		.catch((error) => {
			// TODO: Inform user of error
			setLoadingNewVisit(false)
			toast.error(<Retry />)
		})
}
