import { toast } from 'react-toastify'

export async function updateParticipantInfo(p_id, values, facilityId, t) {
    const authToken = sessionStorage.getItem('token')
    const endpoint =
        window._env_.REACT_APP_BACKEND_URL + '/participants/' + p_id + '/update'
    const headerz = new Headers()

    headerz.append('X-Alzheimer-Service-Token', authToken.toString())
    headerz.append('Content-Type', 'application/json')

    var requestOptions = {
        method: 'POST',
        headers: headerz,
        body: JSON.stringify(
            {
                facility_id: facilityId,
                participant_form: values,
            },
            null,
            2,
        ),
        redirect: 'follow',
    }

    return fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                toast.success(t('ParticipantUpdateSuccess'))
            } else {
                toast.error(t('ParticipantUpdateFailure'))
            }
        })
        .catch((err) => {
            toast.error(t('ParticipantUpdateFailure'))
        })
}
