import {
    CircularProgress,
    Grid,
    makeStyles,
    Table,
    TableBody,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import assessment_paths from '../../../assets/assessment_paths'
import { assessments } from '../../stateObjects/_exports'
import HeaderMenu from '../../utils/HeaderMenu'
import Retry from '../../utils/Retry'
import { typography } from '../../utils/typography'
import active_visit_exists from './_active_visit_exists'
import VisitEntry from './_VisitEntry'
import VisitsHeader from './_VisitsHeader'
import { AddVisitButton as AddVisitButtons } from './AddVisitButton'
import { fetchVisits } from './fetchVisits'

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        overflowX: 'auto',
        overflowY: 'clip',
        marginTop: '20px',
    },
}))

export default function VisitsList() {
    let history = useHistory()
    const { t } = useTranslation()
    const { p_id } = useParams()

    const [loading, setLoading] = useState(true)
    const [shortcode, setShortcode] = useState('')
    const [visitList, setVisitList] = useState([])
    const [loadingNewVisit, setLoadingNewVisit] = useState(false)
    const [activeVisitExists, setActiveVisitExists] = useState(null)

    const classes = useStyles()

    useEffect(() => {
        fetchParticipantShortcode(p_id, setShortcode)
        fetchVisits(setLoading, setVisitList, p_id)

        /* reset assessment status and id's when selecting a new visit */
        assessment_paths.forEach((entry) =>
            assessments.status['set_' + entry](null),
        )
        assessment_paths.forEach((entry) =>
            assessments.ids['set_' + entry](null),
        )
    }, [p_id])

    useEffect(() => {
        if (!loading) {
            active_visit_exists(visitList, setActiveVisitExists)
        }
    }, [visitList, loading])

    return (
        <>
            <HeaderMenu
                backButtonLabel={t('Back_Participants')}
                backButtonPath='/participants'
                label={t('visit_header')}
            />

            <Grid
                container
                justifyContent='space-between'
                style={{ marginTop: '10px', marginBottom: '20px' }}
            >
                <Grid item>
                    {typography(
                        'inherit',
                        null,
                        shortcode,
                        'left',
                        { fontWeight: 'bold', padding: 0 },
                        '24px',
                    )}
                </Grid>
                <Grid item xs>
                    <AddVisitButtons
                        participantId={p_id}
                        visitList={visitList}
                        setVisitList={setVisitList}
                        loadingNewVisit={loadingNewVisit}
                        activeVisitExists={activeVisitExists}
                        setLoadingNewVisit={setLoadingNewVisit}
                        setActiveVisitExists={setActiveVisitExists}
                    />
                </Grid>

                <Grid item xs={12} className={classes.tableContainer}>
                    {loading ? (
                        <div align='center' style={{ marginTop: 100 }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <Table aria-label='visits table'>
                            <TableBody>
                                <VisitsHeader />
                                {visitList
                                    .sort((v1, v2) => {
                                        const date1 = new Date(v1.created_at)
                                        const date2 = new Date(v2.created_at)
                                        return date1 > date2 ? -1 : 1
                                    })
                                    .map((v) => (
                                        <VisitEntry
                                            key={v.visit_id.toString()}
                                            history={history}
                                            p_id={p_id}
                                            info={v}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

/*
	Given that someone can navigate directly to a page,
	passing the shortcode from an ancestor will not work.
	So we just get the whole participant info object and
	keep only the shortcode. Quite verbose, lots of back
	and forth, but the only way to be 100% safe.
*/
function fetchParticipantShortcode(p_id, setShortcode) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = window._env_.REACT_APP_BACKEND_URL + '/participants/' + p_id
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    setShortcode(r.shortcode)
                })
            } else {
                toast.error(<Retry />)
            }
        })
        .catch((err) => {
            // TODO: Inform user of error
        })
}
