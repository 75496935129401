export function mapResponseToData(participantInfo, diagnosisVersion) {
    return {
        facilityId: participantInfo.facility_id,
        identification: participantInfo.shortcode,
        birthday: {
            year: parseInt(participantInfo.year_of_birth),
            month: parseInt(participantInfo.month_of_birth || '1'),
            day: parseInt(participantInfo.day_of_birth || '1'),
        },
        gender: participantInfo.gender || null,
        language: participantInfo.language || null,
        education: participantInfo.education || null,
        occupation: mapOccupation(participantInfo.profession),
        medication: mapMedication(participantInfo.medication),
        diagnosis: mapDiagnosisResponseToData(
            participantInfo,
            diagnosisVersion,
        ),
    }
}

function mapOccupation(profession) {
    const validValues = [
        'Arts/Entertainment',
        'Civil Servant',
        'Consultant',
        'Designer',
        'Driver',
        'Engineer',
        'Finance',
        'Healthcare/Medicine',
        'Law Enforcement',
        'Lawyer',
        'Manager',
        'Manual Worker',
        'Manufacturing',
        'Military',
        'Private Employee',
        'Retired',
        'Scientist',
        'Teacher',
        'Unemployed',
    ]
    const occupation =
        profession?.other ||
        profession?.occupation ||
        (profession && typeof profession === 'string' ? profession : null)

    const res = validValues.includes(occupation)
        ? { occupation }
        : { other: occupation }

    return isObjectNotEmpty(res) ? res : null
}
function mapMedication(medication) {
    if (!medication?.length) {
        return isObjectNotEmpty(medication) ? medication : null
    }

    const otherMedication = medication
        .filter((m) => !['Memantine', 'Donepizil', 'Rivastigmine'].includes(m))
        .map((m) => {
            return { name: m }
        })

    const res = {
        memantine: medication.includes('Memantine'),
        donepezil: medication.includes('Donepizil'),
        rivastigmine: medication.includes('Rivastigmine'),
        other: otherMedication.length ? otherMedication : null,
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapDiagnosisResponseToData(args, diagnosisVersion) {
    const { diagnosis, comorbidities } = args
    if (!diagnosis?.length) {
        return diagnosis?.length === 0 ? null : diagnosis
    }

    const diagnosisType = (key) => inType(diagnosis, key)
    const diagnosisValue =
        diagnosis.find((value) => value.values === 'healthy') ||
        diagnosis.find((value) => value.values === 'mci') ||
        diagnosis.find((value) => value.values === 'dementia')

    const diagnosisKey = diagnosisValue?.values
    if (diagnosisKey === 'healthy') {
        return {
            type: 'Healthy',
            typeId: 1,
            drugAbuse: mapDrugAbuse(diagnosisType),
            generalComorbidities: mapGeneralComorbidities(
                comorbidities,
                diagnosisType,
            ),
            nonCognitiveDiseases: mapNonCognitiveDiseases(diagnosisType),
            screeningTestScores: mapScreeningTestScores(args),
            version: diagnosisVersion,
        }
    }
    if (diagnosisKey === 'mci') {
        return {
            type: 'MCI',
            typeId: 3,
            cognitiveDiseases: mapCognitiveDiseases(diagnosisType),
            cognitiveRelatedComorbidities:
                mapCognitiveRelatedComorbidities(diagnosisType),
            drugAbuse: mapDrugAbuse(diagnosisType),
            generalComorbidities: mapGeneralComorbidities(
                comorbidities,
                diagnosisType,
            ),
            mentalDysfunction: mapMentalDysfunction(diagnosisType),
            nonCognitiveDiseases: mapNonCognitiveDiseases(diagnosisType),
            otherNeuroDiseases: mapOtherNeuroDiseases(diagnosisType),
            psychiatricDisorders: mapPsychiatricDisorders(diagnosisType),
            screeningTestScores: mapScreeningTestScores(args),
            version: diagnosisVersion,
        }
    }
    if (diagnosisKey === 'dementia') {
        return {
            type: 'Dementia',
            typeId: 4,
            cognitiveDiseases: mapCognitiveDiseases(diagnosisType),
            cognitiveRelatedComorbidities:
                mapCognitiveRelatedComorbidities(diagnosisType),
            dementiaDiseases: mapDementiaDiseases(diagnosisType),
            dementiaStage: mapDementiaStage(diagnosisType),
            drugAbuse: mapDrugAbuse(diagnosisType),
            generalComorbidities: mapGeneralComorbidities(
                comorbidities,
                diagnosisType,
            ),
            nonCognitiveDiseases: mapNonCognitiveDiseases(diagnosisType),
            otherNeuroDiseases: mapOtherNeuroDiseases(diagnosisType),
            psychiatricDisorders: mapPsychiatricDisorders(diagnosisType),
            screeningTestScores: mapScreeningTestScores(args),
            version: diagnosisVersion,
        }
    }

    return null
}
const isObjectNotEmpty = (obj) => obj && !!Object.values(obj).find((v) => v)
function mapDementiaStage(diagnosisType) {
    switch (diagnosisType('dementia_degree')) {
        case '1st':
            return 'Mild'
        case '2nd':
            return 'Moderate'
        case '3rd':
            return 'Severe'

        default:
            return null
    }
}
function mapDementiaDiseases(diagnosisType) {
    const res = {
        alzheimersDisease: diagnosisType('diseases').includes('alzheimer'),
        vascularDementia:
            diagnosisType('diseases').includes('vascular_dementia'),
        mixedDementia: diagnosisType('diseases').includes('mixed_dementia'),
        parkinsonsDementia: diagnosisType('diseases').includes('parkinson'),
        lewyBodyDementia:
            diagnosisType('diseases').includes('lewy_body_dementia'),
        multipleSystemsAtrophy: diagnosisType('diseases').includes(
            'multiple_systems_atrophy',
        ),
        normalPrHydrocephalus:
            diagnosisType('diseases').includes('hydrocephalus'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapMentalDysfunction(diagnosisType) {
    const res = {
        memory: diagnosisType('mental_dysfunction').includes('memory'),
        executiveFunction:
            diagnosisType('mental_dysfunction').includes('executive_function'),
        visuospatioAbility: diagnosisType('mental_dysfunction').includes(
            'spatiotemporal_ability',
        ),
        speech: diagnosisType('mental_dysfunction').includes('speech'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapPsychiatricDisorders(diagnosisType) {
    const res = {
        depression: diagnosisType('psychiatric_disorders').includes(
            'depression',
        ),
        anxietyDisorder: diagnosisType('psychiatric_disorders').includes(
            'anxiety_disorder',
        ),
        schizoaffectiveDisorder: diagnosisType(
            'psychiatric_disorders',
        ).includes('schizoemotional_disorder'),
        schizophrenia: diagnosisType('psychiatric_disorders').includes(
            'schizophrenia',
        ),
        bipolarDisorder: mapBipolarDisorder(diagnosisType),
        other: diagnosisType('other_psychiatric_disorder').length
            ? diagnosisType('other_psychiatric_disorder')
            : null,
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapBipolarDisorder(diagnosisType) {
    if (diagnosisType('psychiatric_disorders').includes('bipolar_i')) {
        return 'Bipolar I'
    }
    if (diagnosisType('psychiatric_disorders').includes('bipolar_ii')) {
        return 'Bipolar II'
    }
    if (
        diagnosisType('psychiatric_disorders').includes('unspecified_bipolar')
    ) {
        return 'Unspecified Bipolar Disorder'
    }
    return null
}
function mapOtherNeuroDiseases(diagnosisType) {
    const res = {
        parkinsonsDisease: diagnosisType('diseases').includes('parkinson'),
        epilepsy: mapEpilepsy(diagnosisType),
        multipleSclerosis:
            diagnosisType('diseases').includes('multiple_sclerosis'),
        wernickeSyndrome: diagnosisType('drug_abuse').includes('wernicke'),
        remSleepBehaviorDisorder:
            diagnosisType('diseases').includes('rem_sleep_behavior'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapEpilepsy(diagnosisType) {
    if (diagnosisType('other_brain_disease').length) {
        return { other: 'other_brain_disease' }
    }
    switch (diagnosisType('diseases')) {
        case 'focal_frontal_lobe':
            return { epilepsy: 'Focal Frontal Lobe' }
        case 'focal_temporal_lobe':
            return { epilepsy: 'Focal Temporal Lobe' }
        case 'focal_occipital_lobe':
            return { epilepsy: 'Focal Occipital Lobe' }
        case 'focal_parietal_lobe':
            return { epilepsy: 'Focal Parietal Lobe' }
        default:
            return null
    }
}
function mapAutoimmuneDisease(diagnosisType) {
    const res = {
        'Systemic Lupus Erythematosus': diagnosisType('diseases').includes(
            'lupus_erythematosus',
        ),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapCognitiveRelatedComorbidities(diagnosisType) {
    const res = {
        autoimmuneDisease: mapAutoimmuneDisease(diagnosisType),
        hivInfection: diagnosisType('diseases').includes('aids'),
        other: diagnosisType('other_autoimmune')[0],
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapCognitiveDiseases(diagnosisType) {
    const res = {
        other: diagnosisType('disorders').find(
            (d) => d === 'mental_retardation',
        ),
        downSyndrome: diagnosisType('disorders').includes('down_syndrome'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapScreeningTestScores(args) {
    const res = {}

    args.mmse && (res['mmse'] = parseInt(args.mmse))
    args.moca && (res['moca'] = parseInt(args.moca))
    args.katz && (res['katz'] = parseInt(args.katz))
    args.lawton && (res['lawton'] = parseInt(args.lawton))
    args.ace_r && (res['acethree'] = parseInt(args.ace_r))

    return isObjectNotEmpty(res) ? res : null
}
function mapDevelopmentalDisabilities(diagnosisType) {
    const res = {
        autism: diagnosisType('disorders').includes('autism'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapSpecificLearningDisability(diagnosisType) {
    const res = { dyslexia: diagnosisType('disorders').includes('dyslexia') }

    return isObjectNotEmpty(res) ? res : null
}
function mapNonCognitiveDiseases(diagnosisType) {
    const res = {
        developmentalDisabilities: mapDevelopmentalDisabilities(diagnosisType),
        specificLearningDisability:
            mapSpecificLearningDisability(diagnosisType),
        adhd: diagnosisType('disorders').includes('adhd'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapGeneralComorbidities(comorbidities, diagnosisType) {
    if (!comorbidities) {
        return null
    }

    const res = {
        smoking: comorbidities.includes('smoking')
            ? 'Smoking'
            : 'No Smoking for ten years or more',
        hypercholesterolemia: comorbidities.includes('hypercholesterolemia'),
        diabetesMellitus: comorbidities.includes('diabetes'),
        coronaryArteryDisease: comorbidities.includes('coronary_heart_disease'),
        atrialFibrillation: comorbidities.includes('atrial_fibrillation'),
        ischemicStroke: comorbidities.includes('ischemic_stroke'),
        intracranialHemorrage: comorbidities.includes('hemorragic_stroke'),
        obesity: comorbidities.includes('obesity'),
        cardiacSurgery: comorbidities.includes('cardiac_surgery'),
        arterialHypertension: comorbidities.includes('arterial_hypertension'),
        heartAttack: comorbidities.includes('heart_attack'),
        obtrusiveSleepApnea: comorbidities.includes('obtrusive_sleep_apnea'),
        thyroidDisease: comorbidities.includes('thyroid_disease'),
        respriratoryFailure: comorbidities.includes('respriratory_failure'),
        renalFailure: comorbidities.includes('renal_failure'),
        liverFailure: comorbidities.includes('liver_failure'),
        traumaticBrainInjury: diagnosisType('diseases').includes(
            'traumatic_brain_injuries',
        ),
        hysterectomy: comorbidities.includes('traumatic_brain_injury'),
        malignancies: comorbidities.includes('malignancies'),
        drugsThatAffectCNS: comorbidities.includes('drugs_that_affect_CNS'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function mapDrugAbuse(diagnosisType) {
    const res = {
        alcoholism: diagnosisType('drug_abuse').includes('alcoholism'),
        cannabis: diagnosisType('drug_abuse').includes('marijuana'),
        heroin: diagnosisType('drug_abuse').includes('heroin'),
        cocaine: diagnosisType('drug_abuse').includes('cocaine'),
        amphetamines: diagnosisType('drug_abuse').includes('amphetamines'),
    }

    return isObjectNotEmpty(res) ? res : null
}
function inType(diagnosis, key) {
    const type = diagnosis.find((t) => t.type === key)
    return type?.values || []
}
