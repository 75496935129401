/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import list_of_assessments from '../../../assets/list_of_assessments.json'
import useRemoteAuth from '../../customHooks/useRemoteAuth'
import { assessments } from '../../stateObjects/_exports'
import HeaderMenu from '../../utils/HeaderMenu'
import { InfoButton } from '../VisitsList/InfoButton'
import { fetch_visit_results } from '../VizService/_fetch_assessment_results'
import AssessmentCategory from './AssessmentCategory'
import { AssessmentLinkShare } from './AssessmentLinkShare'
import fetch_assessment_status from './fetch_assessment_status'
import fetch_visit_status from './fetch_visit_status'
import set_visit_status from './set_visit_status'
import { AccordeonContext } from '../../utils/NavigationAccordion'
import classNames from 'classnames'

const useStyles = makeStyles((_) => ({
    buttonContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
    },
    buttonPrompt: {
        color: '#b10000',
        fontSize: 'smaller',
        fontStyle: 'italic',
        position: 'absolute',
        background: '#ffffff8a',
        zIndex: 1,
        textAlign: 'center',
    },
    resultsContainer: {
        marginTop: '20px',
        padding: '0 15px',
    },
    paddedRow: {
        padding: '0 15px',
    },
    linksContainers: {
        display: 'flex',

        paddingRight: '15px',
        justifyContent: 'flex-end',
    },
    link: {
        color: '#00a79d',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
    },
    activeNavigation: {
        width: '50%',
    },
    mainContainer: {
        transition: 'width 0.15s ease-in-out',
    },
}))

const languagesMap = {
    'en-US': 'EN',
    'el-GR': 'GR',
    'es-ES': 'ES',
}

export const AssessmentListRemote = () => {
    const { t, i18n } = useTranslation()
    const { v_id } = useParams()
    const [loading1, setLoading1] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [requiredTasks, set_required_tasks] = useState([])
    const [recommendedTasks, setRecommendedTasks] = useState([])
    const classes = useStyles()
    const history = useHistory()
    const [accordenActiveId, setAccordenActiveId] = useState('')
    const [pdfUrl, setPdfUrl] = useState('')

    useRemoteAuth()

    useEffect(() => {
        fetch_assessment_status(
            v_id,
            t,
            set_required_tasks,
            null,
            setLoading1,
            setRecommendedTasks,
            history,
        )
        fetch_visit_status(v_id, t, null, null, setLoading2, history)
    }, [])

    useEffect(() => {
        const languageShort = languagesMap[i18n.language]
        setPdfUrl(
            `https://storage.googleapis.com/langaware-data/docs/user_manuals/LANGaware%20Remote%20Assessment%20Manual%20${languageShort}.pdf`,
        )
    }, [i18n.language])

    return (
        <>
            {!(loading1 || loading2) ? (
                <AccordeonContext.Provider
                    value={{ accordenActiveId, setAccordenActiveId }}
                >
                    <Grid
                        container
                        className={classNames(
                            classes.mainContainer,
                            accordenActiveId ? classes.activeNavigation : '',
                        )}
                    >
                        <Grid item xs={12} className={classes.linksContainers}>
                            <a
                                rel='noreferrer'
                                href={pdfUrl}
                                style={{ paddingTop: '15px' }}
                                target='_blank'
                                className={classes.link}
                            >
                                <PictureAsPdfIcon /> &nbsp;
                                {t('remote_visit_instructions')}
                            </a>
                        </Grid>
                        <Grid item xs={12}>
                            <AssessmentLists
                                {...{
                                    t,
                                    enableLink: false,
                                    displayStatus: false,
                                    requiredTasks,
                                    recommendedTasks,
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordeonContext.Provider>
            ) : (
                <>
                    <Typography style={{ color: 'black' }}>
                        {t('visit_loading')}
                    </Typography>
                    <div
                        align='center'
                        style={{
                            marginTop: 100,
                        }}
                    >
                        <CircularProgress />
                    </div>
                </>
            )}
        </>
    )
}

export const AssessmentsList = () => {
    const { t } = useTranslation()
    const { p_id, v_id } = useParams()
    const classes = useStyles()
    const [loading1, set_loading1] = useState(true)
    const [loading2, set_loading2] = useState(true)
    const [requiredTasks, set_required_tasks] = useState([])
    const [recommendedTasks, setRecommendedTasks] = useState([])
    const [required_tasks_done, set_required_tasks_done] = useState(false)
    const [isActiveVisit, setIsActiveVisit] = useState(true)
    const [isRemoteVisit, setIsRemoteVisit] = useState(false)
    const [results, setResults] = useState(null)
    const [accordenActiveId, setAccordenActiveId] = useState('')

    useEffect(() => {
        fetch_assessment_status(
            v_id,
            t,
            set_required_tasks,
            set_required_tasks_done,
            set_loading1,
            setRecommendedTasks,
        )
        fetch_visit_status(
            v_id,
            t,
            setIsActiveVisit,
            setIsRemoteVisit,
            set_loading2,
        )
        fetch_visit_results(v_id, setResults, null, null, t, true)
    }, [])

    let location = useLocation()
    let history = useHistory()

    const goToResults = () => history.push(`/results${location.pathname}`)

    const closeVisitClick = async (evt) => {
        set_loading1(true)
        await set_visit_status(v_id, 'inactive', t)
        set_loading1(false)
        toast.success(t('asssessment_was_updated'), {
            autoClose: 1500,
            hideProgressBar: false,
            onClose: () => {
                history.replace(`/participants/${p_id}/visits`)
            },
        })
    }

    return (
        <>
            <HeaderMenu
                backButtonLabel={t('Back_Visits')}
                backButtonPath={`/participants/${p_id}/visits/`}
            />
            <AccordeonContext.Provider
                value={{ accordenActiveId, setAccordenActiveId }}
            >
                <Grid
                    container
                    className={classNames(
                        classes.mainContainer,
                        accordenActiveId ? classes.activeNavigation : '',
                    )}
                >
                    <Grid item xs={12}>
                        <div className='top-title'>
                            <Typography
                                variant='h1'
                                align='center'
                                component='h1'
                                className='top-title-text'
                            >
                                {t('Assessments')}
                            </Typography>
                        </div>
                    </Grid>
                    {isActiveVisit && !(loading1 || loading2) && (
                        <Grid
                            container
                            item
                            spacing={1}
                            justifyContent='flex-end'
                            className={classes.paddedRow}
                        >
                            {isRemoteVisit && (
                                <Grid item className={classes.buttonContainer}>
                                    <AssessmentLinkShare
                                        participantId={p_id}
                                        visitId={v_id}
                                    />
                                </Grid>
                            )}
                            <Grid item className={classes.buttonContainer}>
                                {!required_tasks_done && (
                                    <InfoButton
                                        style={{ fontSize: '22px' }}
                                        message={t(
                                            'need_to_finish_all_required_tasks',
                                        )}
                                    />
                                )}
                                <Button
                                    color='primary'
                                    variant='outlined'
                                    disabled={!required_tasks_done}
                                    alt={'Test'}
                                    onClick={closeVisitClick}
                                >
                                    {t('close_visit')}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {loading1 || loading2 ? (
                        <Grid item xs style={{ cursor: 'default' }}>
                            <div
                                align='center'
                                style={{
                                    marginTop: 100,
                                }}
                            >
                                <CircularProgress />
                            </div>
                        </Grid>
                    ) : (
                        // if visit is inactive
                        required_tasks_done &&
                        results && (
                            <>
                                {results.message ? (
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        className={classes.resultsContainer}
                                    >
                                        <Grid item xs>
                                            <Typography
                                                style={{ color: 'orange' }}
                                            >
                                                {t(results.message)}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <AssessmentOutlinedIcon
                                                style={{
                                                    fontSize: '30px',
                                                    color: 'orange',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        onClick={() => {
                                            goToResults()
                                        }}
                                        className={classes.resultsContainer}
                                    >
                                        <Grid
                                            item
                                            xs
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <Typography
                                                style={{ color: 'green' }}
                                            >
                                                {t('show_final_visit_results')}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <AssessmentOutlinedIcon
                                                style={{
                                                    fontSize: '30px',
                                                    color: 'green',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}

                                {required_tasks_done && (
                                    <Grid
                                        xs
                                        item
                                        className={classes.resultsContainer}
                                    >
                                        <Typography style={{ color: 'black' }}>
                                            {t('visit_extra_prompt')}
                                        </Typography>
                                    </Grid>
                                )}
                            </>
                        )
                    )}
                </Grid>
                {/* Each element of the list is a group of assessments. Notice that the key attr must be string. */}
                {!loading1 && !loading2 && (
                    <AssessmentLists
                        {...{ t, requiredTasks, recommendedTasks }}
                    />
                )}
            </AccordeonContext.Provider>
        </>
    )
}

const AssessmentLists = ({
    t,
    requiredTasks,
    recommendedTasks,
    enableLink = true,
    displayStatus = true,
}) => {
    const getAssessmentsCount = (_assessments, statuses) =>
        _assessments?.filter(
            (a) => statuses.indexOf(assessments.status[a.path]) > -1,
        )?.length

    return (
        <>
            {/* Required assessments */}
            <Card elevation={0}>
                <CardContent>
                    <Typography gutterBottom className='group-header'>
                        {t('required_assessments')}
                    </Typography>
                    {filterAssessments(list_of_assessments, requiredTasks).map(
                        (obj) => (
                            <AssessmentCategory
                                key={obj.category}
                                sublist={obj}
                                enableLink={enableLink}
                                displayStatus={displayStatus}
                                completed={getAssessmentsCount(
                                    obj.assessments,
                                    ['completed'],
                                )}
                                pending={getAssessmentsCount(obj.assessments, [
                                    'submitted',
                                    'processed',
                                    'in-review',
                                ])}
                                failed={getAssessmentsCount(obj.assessments, [
                                    'failed',
                                ])}
                                total={obj.assessments?.length}
                            />
                        ),
                    )}
                </CardContent>
            </Card>
            <br />
            {/* Recommended assessments */}
            <Card elevation={0}>
                <CardContent>
                    <Typography className='group-header' gutterBottom>
                        {t('recommended_assessments')}
                    </Typography>
                    {filterAssessments(
                        list_of_assessments,
                        recommendedTasks,
                    ).map((obj) => (
                        <AssessmentCategory
                            key={obj.category}
                            sublist={obj}
                            enableLink={enableLink}
                            displayStatus={displayStatus}
                            completed={getAssessmentsCount(obj.assessments, [
                                'completed',
                            ])}
                            pending={getAssessmentsCount(obj.assessments, [
                                'submitted',
                                'processed',
                                'in-review',
                            ])}
                            failed={getAssessmentsCount(obj.assessments, [
                                'failed',
                            ])}
                            total={obj.assessments?.length}
                        />
                    ))}
                </CardContent>
            </Card>
            <br />
            {/* Optional assessments */}
            <Card elevation={0}>
                <CardContent>
                    <Typography className='group-header' gutterBottom>
                        {t('optional_assessments')}
                    </Typography>
                    {filterAssessments(
                        list_of_assessments,
                        [...requiredTasks, ...recommendedTasks],
                        'exclude',
                    ).map((obj) => (
                        <AssessmentCategory
                            key={obj.category}
                            sublist={obj}
                            enableLink={enableLink}
                            displayStatus={displayStatus}
                            completed={getAssessmentsCount(obj.assessments, [
                                'completed',
                            ])}
                            pending={getAssessmentsCount(obj.assessments, [
                                'submitted',
                                'processed',
                                'in-review',
                            ])}
                            failed={getAssessmentsCount(obj.assessments, [
                                'failed',
                            ])}
                            total={obj.assessments?.length}
                        />
                    ))}
                </CardContent>
            </Card>
        </>
    )
}

function filterAssessments(list, filterlist, filterMode = 'keep') {
    const filterFunc =
        filterMode === 'keep'
            ? (assessment) => filterlist.indexOf(assessment.path) > -1
            : (assessment) => filterlist.indexOf(assessment.path) < 0

    const newList = list.filter((category) =>
        category.assessments.some(filterFunc),
    )
    return newList.map((category) => {
        return {
            ...category,
            assessments: category.assessments.filter(filterFunc),
        }
    })
}
