import { Box } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import render_charts from './_render_charts'
import { useStyles } from './useStyles'

export const BarChart = (props) => {
	const { results, t } = props

	const [visitInfoLoading] = useState(true)
	const [shortcodeLoading] = useState(true)
	const [resultsLoading] = useState(true)
	// CSS classes for charts
	const classes = useStyles()

	useEffect(() => {
		let _refs, _refs_pdf
		const pdf_container = document.getElementById('bar-chart-pdf')
		if (pdf_container !== null && results !== null && !results.message) {
			_refs_pdf = render_charts(results, pdf_container, null, t)
		}

		const container = document.getElementById('bar-chart')
		if (container !== null && results !== null && !results.message) {
			_refs = render_charts(results, container, null, t)
		}

		return function cleanupCharts() {
			if (_refs) {
				_refs.barchart_ref?.destroy()
			}

			if (_refs_pdf) {
				_refs_pdf.barchart_ref?.destroy()
			}
		}
	}, [shortcodeLoading, visitInfoLoading, resultsLoading, results, t])

	return (
		<Box
			height={1}
			width={1}
			display='flex'
			alignItems='center'
			flexDirection='column'>
			<div id='bar-chart-container' className={classes.childChartContainer}>
				<canvas id='bar-chart' />
			</div>
		</Box>
	)
}
