import React from 'react'
import ReactDOM from 'react-dom'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './styling/theme'
import './styling/index.scss'

import AppEntryPoint from './components/AppEntryPoint'

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppEntryPoint />
    </ThemeProvider>,
    document.getElementById('root'),
)
