/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CircularProgress,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core'
import { PictureAsPdf } from '@material-ui/icons'
import * as html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import font_Dosis_SemiBold from '../../../assets/fonts/Dosis-SemiBold-normal'
import font_OpenSans_Regular from '../../../assets/fonts/OpenSans-Regular-normal'
import font_Static_Bold from '../../../assets/fonts/Static-Bold-normal'
import { ReactComponent as LogoHead } from '../../../assets/logos/logo_head.svg'
import { ReactComponent as LogoLetters } from '../../../assets/logos/logo_letters.svg'
import { BadgePanel } from '../../utils/BadgePanel'
import { ExpandablePanel } from '../../utils/ExpandablePanel'
import HeaderMenu from '../../utils/HeaderMenu'
import { fetchVisits } from '../VisitsList/fetchVisits'
import {
    fetch_assessment_results,
    fetch_visit_results,
} from './_fetch_assessment_results'
import fetch_participant_shortcode from './_fetch_participant_shortcode'
import fetch_visit_info from './_fetch_visit_info'
import { ResultsPageHeader } from './ResultsPageHeader'
import { VisitationResults } from './VisitationResults'

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        bottom: '0px',
        width: '100%',
        top: '123px',
        left: 0,
        right: 0,
        // paddingRight: '20px',
        // paddingLeft: '20px',
    },
}))

export const GLOBAL_SPACING = 0
// 3rd party libraries
// See https://github.com/niklasvh/html2canvas/issues/1440 for import usage
// Assets
// Fonts
// Data fetching with REST API calls
export default function ResultsPage() {
    // Get the basic information from the URL path
    const { p_id, v_id, assessment, assessment_id } = useParams()

    // Localization
    const { t } = useTranslation()
    const history = useHistory()

    // Fetched information
    const [visitInfo, setVisitInfo] = useState('')
    const [visitInfoLoading, setVisitInfoLoading] = useState(true)
    const [participant, setParticipant] = useState(null)
    const [shortcode, setShortcode] = useState('')
    const [shortcodeLoading, setShortcodeLoading] = useState(true)
    const [results, setResults] = useState(null)
    const [resultsLoading, setResultsLoading] = useState(true)
    const [visitList, setVisitList] = useState(null)
    const [visitListLoading, setvisitListLoading] = useState(true)
    const [topOffset, setTopOffset] = useState(0)
    const [printing, setPrinting] = useState(false)
    const [loading, setLoading] = useState(
        visitInfoLoading ||
            shortcodeLoading ||
            resultsLoading ||
            visitListLoading,
    )
    // Use this color to paint components according to result:
    // low, medium, high risk
    const [result_color, setResultColor] = useState('lightgrey')
    // Fetch information
    useEffect(() => {
        // Visit information
        fetch_visit_info(v_id, setVisitInfo, setVisitInfoLoading, t)
        // Participant information
        fetch_participant_shortcode(
            p_id,
            setShortcode,
            setParticipant,
            setShortcodeLoading,
            t,
        )
        fetchVisits(setvisitListLoading, setVisitList, p_id)

        // Fetch results
        if (assessment && assessment_id) {
            // Specific task results
            fetch_assessment_results(
                assessment_id,
                setResults,
                setResultColor,
                setResultsLoading,
                t,
            )
        } else {
            // Whole visit results
            fetch_visit_results(
                v_id,
                setResults,
                setResultColor,
                setResultsLoading,
                t,
            )
        }

        const listener = (_) => setTopOffset(window.pageYOffset)
        window.addEventListener('scroll', listener)

        return () => {
            window.removeEventListener('scroll', listener)
        }
    }, [])
    useEffect(() => {
        setLoading(
            visitInfoLoading ||
                shortcodeLoading ||
                resultsLoading ||
                visitListLoading,
        )
    }, [visitInfoLoading, shortcodeLoading, resultsLoading, visitListLoading])
    useEffect(() => {
        if (!results?.message) {
            return
        }

        if (
            results.message.includes(
                'Prediction not ready. Please try again later.',
            )
        ) {
            const returnUrl = `/participants/${p_id}/visits/${v_id}`
            const url = `/not-ready?return_url=${returnUrl}`
            history.replace(encodeURI(url))
        } else {
            history.replace('/error')
        }
    }, [results])

    const classes = useStyles()

    return (
        <>
            {/* Invisible UI */}
            <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
                <div
                    style={{
                        width: '1200px',
                        height: '1000px',
                        overflow: 'hidden',
                    }}
                >
                    <canvas id='radar-chart-pdf' width='1200' height='1000' />
                </div>

                <div
                    style={{
                        width: '1200px',
                        height: '1000px',
                        overflow: 'hidden',
                    }}
                >
                    <canvas id='bar-chart-pdf' width='1200' height='1000' />
                </div>

                <div
                    id='logo-head-pdf'
                    style={{
                        width: '200px',
                        height: '200px',
                        backgroundColor: '#00a79d',
                    }}
                >
                    <LogoHead
                        style={{
                            width: '200px',
                            height: '200px',
                        }}
                    />
                </div>

                <div
                    id='logo-letters-pdf'
                    style={{
                        width: '400px',
                        height: '200px',
                        backgroundColor: '#00a79d',
                    }}
                >
                    <LogoLetters
                        style={{
                            width: '400px',
                            height: '200px',
                        }}
                    />
                </div>
            </div>

            {/* Visible UI */}
            <HeaderMenu
                backButtonLabel={`< ${t('Assessments')}`}
                backButtonPath={`/participants/${p_id}/visits/${v_id}`}
                label={'LANGaware Cognitive Score'}
            />
            <Box id='results-page-container' className={classes.mainContainer}>
                <ResultsPageHeader
                    v_id={v_id}
                    p_id={p_id}
                    results={results}
                    loading={loading}
                    shortcode={shortcode}
                    visitInfo={visitInfo}
                    printStarted={() => setPrinting(true)}
                    printFinished={() => setPrinting(false)}
                />
                {
                    // Loading has not finished
                    loading ? (
                        <div
                            align='center'
                            style={{ margin: 'auto', marginTop: 100 }}
                        >
                            <CircularProgress />
                        </div>
                    ) : (
                        // Loading finished
                        <div id='results'>
                            <div id='scroll-dialog-content'>
                                {results && (
                                    <ResultsPanel
                                        t={t}
                                        results={results}
                                        showAll={printing}
                                        visitInfo={visitInfo}
                                        shortcode={shortcode}
                                        visitList={visitList}
                                        participant={participant}
                                        result_color={result_color}
                                        topOffset={topOffset > 100}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }
            </Box>
        </>
    )
}

export function SaveAsPDFButton(props) {
    const { t } = useTranslation()
    const { p_code, v_id, v_created_at, results, printStarted, printFinished } =
        props

    const v_date = new Date(v_created_at)
    const v_timestamp =
        v_date.getFullYear() +
        '-' +
        v_date.getMonth() +
        '-' +
        v_date.getDate() +
        '_' +
        v_date.getHours() +
        v_date.getMinutes()
    const v_pretty_timestamp =
        v_date.getFullYear() +
        '/' +
        v_date.getMonth() +
        '/' +
        v_date.getDate() +
        ' - ' +
        v_date.getHours() +
        ':' +
        v_date.getMinutes()

    var pdf_result_color = 'lightgrey'

    if (results != null) {
        if (results.prediction === 'low') {
            pdf_result_color = '#BEB744'
        } else if (results.prediction === 'medium') {
            pdf_result_color = '#939598'
        } else if (results.prediction === 'high') {
            pdf_result_color = '#AC519F'
        }
    }

    // HACK: In order to support i18n (e.g. Greek) we need to manually add a
    // font... I have not done this since 1999
    const callAddFont = function () {
        this.addFileToVFS('font_Dosis_SemiBold.ttf', font_Dosis_SemiBold.base64)
        this.addFont('font_Dosis_SemiBold.ttf', 'font_Dosis_SemiBold', 'normal')
        this.addFileToVFS(
            'font_OpenSans_Regular.ttf',
            font_OpenSans_Regular.base64,
        )
        this.addFont(
            'font_OpenSans_Regular.ttf',
            'font_OpenSans_Regular',
            'normal',
        )
        this.addFileToVFS('font_Static_Bold.ttf', font_Static_Bold.base64)
        this.addFont('font_Static_Bold.ttf', 'font_Static_Bold', 'normal')
    }
    jsPDF.API.events.push(['addFonts', callAddFont])

    const buttonCallback = async () => {
        printStarted && printStarted()
        // Create PDF document object that holds the captured UI elements
        var pdf_doc = new jsPDF({
            orientation: 'portrait',
            compress: true,
            format: 'a4', // A4 page = 210 x 297 mm
            unit: 'mm',
            putOnlyUsedFonts: true,
        })
        pdf_doc.setFont('font_OpenSans_Regular', 'normal')
        await addDocumentUserData(
            pdf_doc,
            t,
            pdf_result_color,
            results,
            v_id,
            p_code,
            v_pretty_timestamp,
        )

        // Render canvas options for UI image captures
        const canvas_options = {
            scale: 1.0,
        }
        // Information
        let accummulatedHeight = 90
        const elements = document.getElementsByClassName('printable')
        const elementWidth = 200
        for (const element of elements) {
            element.classList.add('printing')
            const canvas = await html2canvas(element, canvas_options)
            const aspect_ratio = canvas.width / canvas.height
            const rationedHeight = elementWidth / aspect_ratio
            if (rationedHeight + accummulatedHeight > 297) {
                accummulatedHeight = 5
                pdf_doc.addPage()
                addFooter(pdf_doc, t)
            }
            element.classList.remove('printing')

            const image_data = canvas.toDataURL('image/png')
            pdf_doc.addImage(
                image_data,
                'PNG',
                5,
                accummulatedHeight,
                elementWidth,
                elementWidth / aspect_ratio,
            )
            accummulatedHeight += rationedHeight + 5
        }
        // Charts
        addRadarChart(pdf_doc, t)
        addBarChart(pdf_doc, t)
        addDomainDefinitions(pdf_doc, t)

        // Save the document
        pdf_doc.save(v_id + '_' + p_code + '_' + v_timestamp + '.pdf')
        printFinished && printFinished()
    }

    return (
        <Button
            onClick={async (evt) => {
                try {
                    printStarted && printStarted()
                    setTimeout(async () => {
                        await buttonCallback()
                    }, 100)
                } catch (error) {
                    console.error(error)
                    printFinished && printFinished()
                }
            }}
            variant='outlined'
            size='medium'
            startIcon={<PictureAsPdf />}
        >
            {t('results_save_pdf')}
        </Button>
    )
}

export const ResultsPanel = (props) => {
    const { interpretation } = props?.results || {}
    const { t } = useTranslation()

    return (
        <Paper elevation={0} className='expandable-container'>
            <div>
                <Grid container direction='row' spacing={GLOBAL_SPACING}>
                    <Grid item xs={12}>
                        <VisitationResults {...props} />
                    </Grid>
                </Grid>
            </div>
            <div className='expandable-panel'>
                <ExpandablePanel
                    elevation={0}
                    expanded={false}
                    title={t('DefinitionsCognitiveDomains')}
                >
                    <DefinitionsCognitiveDomains categories={interpretation} />
                </ExpandablePanel>
            </div>
        </Paper>
    )
}

export const DefinitionsCognitiveDomains = (props) => {
    const { categories } = props
    const { t } = useTranslation()

    const contentList = categories.map((category) => (
        <Grid item xs={12} key={category.feature}>
            <Card variant='outlined'>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant='h6' component='h6'>
                            {t(category.feature)}
                        </Typography>
                        <Typography
                            variant='body2'
                            color='textSecondary'
                            component='p'
                        >
                            {t(`${category.feature} description`)}
                        </Typography>
                        <Typography
                            variant='body2'
                            color='textSecondary'
                            component='p'
                        >
                            {t(`${category.feature} impact`)}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    ))

    return (
        <BadgePanel elevation={0} style={{ minWidth: '350px' }}>
            <Grid container spacing={2} elevation={0}>
                {contentList}
            </Grid>
        </BadgePanel>
    )
}

const addBarChart = (pdf_doc, t) => {
    if (!document.getElementById('bar-chart')) {
        return false
    }
    pdf_doc.addPage()

    setTitle(pdf_doc, t('cognitive_domain_bar_chart'))
    const bar_chart = document.getElementById('bar-chart-pdf')
    const aspect_ratio = bar_chart.width / bar_chart.height
    // Add canvas as image to PDF, A4 page is 210 mm wide
    const bar_chart_data = bar_chart.toDataURL('image/png')
    pdf_doc.addImage(bar_chart_data, 'PNG', 5, 20, 200, 200 / aspect_ratio)

    setRect(pdf_doc, {
        color: 'rgb(230, 230, 230)',
        x: 5,
        y: 200 / aspect_ratio + 35,
        width: 200,
        height: 12,
    })
    setText(pdf_doc, {
        text: t('the_cognitive_domain_bar_chart_illustrates'),
        fontSize: 10,
        fontColor: 'black',
        x: 10,
        y: 200 / aspect_ratio + 40,
        maxWidth: 190,
    })

    // Footer
    setText(pdf_doc, {
        text: t('results_footer') + ' ' + new Date().getFullYear(),
        fontColor: 'black',
        x: 105,
        y: 295,
        maxWidth: 190,
        align: 'center',
    })
    return true
}

const addRadarChart = (pdf_doc, t) => {
    if (!document.getElementById('radar-chart')) {
        return false
    }
    pdf_doc.addPage()

    setTitle(pdf_doc, t('cognitive_profile'))

    const radar_chart = document.getElementById('radar-chart-pdf')
    const aspect_ratio = radar_chart.width / radar_chart.height
    // Add canvas as image to PDF, A4 page is 210 mm wide
    const radar_chart_data = radar_chart.toDataURL('image/png')
    pdf_doc.addImage(radar_chart_data, 'PNG', 5, 20, 200, 200 / aspect_ratio)

    setRect(pdf_doc, {
        color: 'rgb(230, 230, 230)',
        x: 5,
        y: 200 / aspect_ratio + 35,
        width: 200,
        height: 12,
    })
    setText(pdf_doc, {
        text: t('the_cognitive_profiling_chart_illustrates_where'),
        fontSize: 10,
        fontColor: 'black',
        x: 10,
        y: 200 / aspect_ratio + 40,
        maxWidth: 190,
    })

    // Footer
    setText(pdf_doc, {
        text: t('results_footer') + ' ' + new Date().getFullYear(),
        fontColor: 'black',
        x: 105,
        y: 295,
        maxWidth: 190,
        align: 'center',
    })
    return true
}

const addDomainDefinitions = (pdf_doc, t) => {
    const domains = [
        'Lexical Variation',
        'Syntactic Complexity',
        'Syntactic errors',
        'Lexical Repetition',
        'Content words',
        'Open words/ Confrontation naming',
        'Open Words (nouns)/Confrontation Naming',
        'Open Words (simple verbs)',
        'Function Words',
        'Prepositions/Conjunctions/Adverbs',
        'Determiners/Pronouns',
        'Grammatical Constituents',
        'Pauses silence duration',
        'General duration',
        'Use of here and there',
        'Concepts Recall',
        'Concepts recall',
        'Similarity',
        'Extralinguistic',
        'SpectrumR',
    ]

    for (let index = 0; index < domains.length; index++) {
        if (index % 8 === 0) {
            pdf_doc.addPage()
            setTitle(pdf_doc, t('DefinitionsCognitiveDomains'))
        }
        const y = 20 + (index % 8) * 35
        const domain = domains[index]
        setRect(pdf_doc, {
            color: 'rgb(230, 230, 230)',
            x: 10,
            y: y + 3,
            width: 190,
            height: 25,
        })
        setText(pdf_doc, {
            text: t(`${domain}`),
            x: 10,
            y,
        })
        setText(pdf_doc, {
            text: t(`${domain} description`),
            x: 15,
            y: y + 10,
            maxWidth: 185,
        })
        setText(pdf_doc, {
            text: t(`${domain} impact`),
            x: 15,
            y: y + 20,
            maxWidth: 185,
        })
    }
}

const setTitle = (pdf_doc, titleText) => {
    pdf_doc.setDrawColor('rgb(0, 167, 157)')
    pdf_doc.setFillColor('rgb(0, 167, 157)')
    pdf_doc.rect(0, 5, 210, 10, 'F')
    pdf_doc.setFontSize(18)
    pdf_doc.setTextColor('white')
    pdf_doc.text(titleText, 100, 12, { align: 'center' })
}

const setRect = (pdf_doc, options) => {
    const { color, x, y, width, height } = options
    pdf_doc.setDrawColor(color)
    pdf_doc.setFillColor(color)
    pdf_doc.rect(x, y, width, height, 'F')
}

const setText = (pdf_doc, options) => {
    const {
        text = '',
        fontSize = 10,
        fontColor = 'black',
        x,
        y,
        align,
        maxWidth = 190,
    } = options
    pdf_doc.setFontSize(fontSize)
    pdf_doc.setTextColor(fontColor)
    pdf_doc.text(text, x, y, { align, maxWidth })
}

const addDocumentUserData = async (
    pdf_doc,
    t,
    pdf_result_color,
    results,
    v_id,
    p_code,
    v_pretty_timestamp,
) => {
    // Render canvas options for UI image captures
    const canvas_options = {
        scale: 1.0,
    }
    let aspect_ratio, canvas, image_data

    const logo_head = document.getElementById('logo-head-pdf')
    canvas = await html2canvas(logo_head, canvas_options)
    aspect_ratio = canvas.width / canvas.height
    image_data = canvas.toDataURL('image/png')
    pdf_doc.addImage(image_data, 'PNG', 0, 0, 40, 40 / aspect_ratio)
    const logo_letters = document.getElementById('logo-letters-pdf')
    canvas = await html2canvas(logo_letters, canvas_options)
    aspect_ratio = canvas.width / canvas.height
    image_data = canvas.toDataURL('image/png')
    pdf_doc.addImage(image_data, 'PNG', 40, 0, 80, 80 / aspect_ratio)
    pdf_doc.setDrawColor('rgb(0, 167, 157)')
    pdf_doc.setFillColor('rgb(0, 167, 157)')
    pdf_doc.rect(119, 0, 100, 80 / aspect_ratio, 'F')

    pdf_doc.setFontSize(14)
    pdf_doc.text(t('visit_ID') + ': ' + v_id, 10, 55)
    pdf_doc.text(t('results_participant') + ': ' + p_code, 10, 65)
    pdf_doc.text(t('results_date') + ': ' + v_pretty_timestamp, 10, 75)
    // Footer
    addFooter(pdf_doc, t)
    return { canvas, aspect_ratio, image_data }
}

const addFooter = (pdf_doc, t) => {
    pdf_doc.setFontSize(10)
    pdf_doc.text(
        t('results_footer') + ' ' + new Date().getFullYear(),
        105,
        295,
        null,
        null,
        'center',
    )
}
