import { toast } from 'react-toastify'

import { Retry } from './DynamicForm'

import jsonRefParser from 'json-schema-ref-parser'

export function fetchJsonSchema(setJsonSchema, setJsonSchemaVersion, t) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/participants/participant_form_schema`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((resp) => {
                    // Now fetch schema contents
                    fetchJsonSchemaContents(resp.schema, setJsonSchema, t)
                    setJsonSchemaVersion(resp.version)
                })
            } else {
                setJsonSchema(0)
                setJsonSchemaVersion(0)
                toast.error(Retry('JSON schema could not be found', t))
            }
        })
        .catch((err) => {
            setJsonSchema(0)
            setJsonSchemaVersion(0)
            toast.error(Retry('JSON schema could not be found', t))
        })
}

function fetchJsonSchemaContents(endpoint, setJsonSchema, t) {
    fetch(endpoint)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((resp) => {
                    jsonRefParser.dereference(resp, (err, schema) => {
                        if (err) {
                            setJsonSchema(0)
                            toast.error(t(`json_schema_is_not_valid - ${err}`))
                        } else {
                            setJsonSchema(schema)
                        }
                    })
                })
            } else {
                setJsonSchema(0)
                toast.error(Retry('JSON schema could not be found', t))
            }
        })
        .catch((err) => {
            setJsonSchema(0)
            toast.error(Retry('JSON schema could not be found', t))
        })
}
