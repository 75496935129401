import Chart from 'chart.js/auto'
import { toast } from 'react-toastify'

import array_intersection from '../../utils/array_intersection'
import ErrorMsg from '../../utils/ErrorMsg'

export default function _render_charts(vizData, container1, container2, t) {
  try {
    if (vizData.length === 0) throw new Error('empty vizData')
    /*
        sort in non-increasing order,
        returning new array instead of mutating the original
    */
    const sortedInterpretation = [...vizData.interpretation].sort((e1, e2) => e2.value - e1.value)
    /*
        pass the sorted categories array to the radar chart
        so that it's in the same order as in the bar chart
    */
    const sortedCategories = sortedInterpretation.map(e => e.feature)

    const profilingFeatures = [...vizData.profiling.AD].map(e => e.feature)

    const featureIntersection = array_intersection(sortedCategories, profilingFeatures)

    let radarchart_ref, barchart_ref
    if (container2) {
      radarchart_ref = setupRadarChart(container2, featureIntersection, vizData.profiling, t)
    }
    if (container1) {
      barchart_ref = setupBarChart(container1, sortedInterpretation, t)
    }

    return { radarchart_ref, barchart_ref }
  } catch (err) {
    console.error(err)
    toast.error(<ErrorMsg err={t('VisualizationResultError')} />, { autoClose: false })
  }
}

function setupRadarChart(container, sortedCategories, profiling, t) {

  let _dataset_AD = []
  let _dataset_NC = []
  let _dataset_Sample = []

  sortedCategories.forEach(d => {
    const _AD = profiling.AD.find(obj => obj.feature === d)
    _dataset_AD.push((_AD.value + 0.1) * 100) // Adding 0.1 in order to have zero values fill the first polygon, instead of being around the center, for aesthetics

    const _NC = profiling.NC.find(obj => obj.feature === d)
    _dataset_NC.push((_NC.value + 0.1) * 100) // Same as previous

    const _Sample = profiling.Sample.find(obj => obj.feature === d)
    _dataset_Sample.push((_Sample.value + 0.1) * 100) // Same as previous
  })

  const _data = {
    labels: sortedCategories
      .map(cat => t(cat))
      .map(cat => splitEveryNCharacter(cat, 15)),
    datasets: [
      {
        label: t('results_participant'),
        data: _dataset_Sample,
        borderColor: 'rgba(0, 167, 157, 0.7)',
        backgroundColor: 'rgba(0, 167, 157, 0.1)',
      },
      {
        label: t('results_legend_AD'),
        data: _dataset_AD,
        borderColor: 'rgba(167, 0, 157, 0.7)',
        backgroundColor: 'rgba(167, 0, 157, 0.1)',
      },
      {
        label: t('results_legend_NC'),
        data: _dataset_NC,
        borderColor: 'rgba(167, 157, 0, 0.7)',
        backgroundColor: 'rgba(167, 157, 0, 0.1)',
      }
    ]
  }

  const { clientWidth } = window.document.getElementById("radar-chart-container") || {}
  const font_size = clientWidth >= 768 ? 16 : 11

  const CHART_CONFIG = {
    type: 'radar',
    data: _data,
    options: {
      responsive: true,
      aspectRatio: 1.2,
      scales: {
        r: {
          pointLabels: { font: { size: font_size } },
          beginAtZero: true,
          max: 110,
          ticks: {
            font: { size: font_size },
            callback: (value, index, values) => `${value - 10}%`
          }
        }
      },
      plugins: {
        title: {
          display: false,
          text: 'Cognitive Profiling',
          font: { size: font_size }
        },
        legend: {
          labels: { font: { size: font_size } },
          callbacks: {
            title: (context) => {
              return `${t(context[0].label)}`
            }
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => `${context.dataset.label}: ${(context.parsed.r - 10).toFixed(1)}%`
          }
        }
      }
    },
  }

  let radar_chart = new Chart(container, CHART_CONFIG)

  return radar_chart
}

function splitEveryNCharacter(phrase, limit) {
  const group = []
  for (var i = 0, j = 0, currentLimit = limit; i < phrase.length; i++) {
    if (i >= currentLimit && phrase[i] === ' ') {
      j++
      currentLimit = i + limit
      continue
    }
    // group[j] = group[j] || [];
    group[j] = `${group[j] || ''}${phrase[i]}`
  }
  return group
}

function setupBarChart(container, interpretation, t) {

  const _categories = interpretation
    .map(x => t(x.feature))
  const _values = interpretation.map(x => (x.value * 100).toFixed(1))

  const { clientWidth } = window.document.getElementById("bar-chart-container") || {}
  const font_size = clientWidth >= 768 ? 16 : 11

  const CHART_CONFIG = {
    type: 'bar',
    data: {
      labels: _categories,
      datasets: [{
        label: '',
        data: _values,
        backgroundColor: 'rgba(0, 167, 157, 0.7)'
      }]
    },
    options: {
      responsive: true,
      aspectRatio: 1.2,
      indexAxis: 'y',
      scales: {
        y: {
          ticks: { font: { size: font_size } },
        },
        x: {
          beginAtZero: true,
          max: 100,
          ticks:
          {
            font: { size: font_size },
            callback: (value, index, values) => {
              const val = value
              return val + '%'
            }
          }
        }
      },
      plugins: {
        title: {
          display: false,
          text: 'Category Contribution',
          font: { size: font_size }
        },
        legend: { display: false },
        tooltip: {
          callbacks: {
            label: (context) => `${(context.parsed.x).toFixed(1)}%`
          }
        }
      }
    }
  }

  let bar_chart = new Chart(container, CHART_CONFIG)

  return bar_chart
}
