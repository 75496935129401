import { makeStyles } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { Grid } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Link from './Link'

export const useStyles = makeStyles((theme) => ({
    container: {
        color: '#6e6e6e',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        left: 0,
        right: 0,
        display: 'flex',
        top: '150px',
        bottom: 0,
    },
    errorIcon: {
        fontSize: '60px',
    },
    text: {
        fontFamily: 'monospace',
    },
    rowContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    linkContainer: {
        padding: '40px 5px',
    },
}))

export const ErrorPage = (props) => {
    const { icon, message, color } = props
    const { search } = useLocation()
    const returnUrl = new URLSearchParams(search).get('return_url') || '/'
    const { t } = useTranslation()

    const classes = useStyles()

    return (
        <div style={{ color }} className={classes.container}>
            <Grid container>
                <Grid item xs={12} className={classes.rowContainer}>
                    <div>
                        {icon || (
                            <ErrorOutlineIcon className={classes.errorIcon} />
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.rowContainer}>
                    <h4 className={classes.text}>{message}</h4>
                </Grid>
                {returnUrl && (
                    <Grid item xs={12} className={classes.rowContainer}>
                        <div className={classes.linkContainer}>
                            <Link
                                label={t('go_back')}
                                to={decodeURI(returnUrl)}
                            />
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
