import { IconButton, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import React, { useState } from 'react'

export const useStyles = makeStyles((theme) => ({
    info: {
        color: '#808080',
        display: 'block',
        padding: '15px 20px 30px 20px',
        fontSize: '14px',
        maxWidth: '180px',
        fontFamily: 'open-sans',
    },
    lockButton: {
        height: '40px',
        width: '40px',
    },
}))

export const InfoButton = (props) => {
    const { message, visible = true, style } = props
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [anchor, setAnchor] = useState(null)
    const lockButtonRef = React.createRef()
    const classes = useStyles()

    return (
        <span style={{ opacity: visible ? 1 : 0, ...style }}>
            <IconButton
                ref={lockButtonRef}
                className={classes.lockButton}
                onClick={(evt) => {
                    setAnchor(evt.currentTarget)
                    setPopoverOpen(true)
                }}
            >
                <InfoIcon
                    {...{ fontSize: props.fontSize, style: props.style }}
                />
            </IconButton>
            <Popover
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <span className={classes.info}>{message}</span>
            </Popover>
        </span>
    )
}
