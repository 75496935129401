import { Grid, IconButton, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DynamicForm } from '../../dynamicForm/DynamicForm'
import DialogWrapper from '../../utils/DialogWrapper'

const useStyles = makeStyles({
    root: {
        color: 'black',
        width: 'fit-content',
        fontSize: '24px',
        fontFamily: 'open-sans',
        fontWeight: 'bold',
        '&:before': {
            border: '0px',
        },
    },
    dialogRoot: {
        width: '100%',
        maxWidth: '600px',
        position: 'relative',
    },
    formRoot: {
        width: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    listItem: {
        width: '100%',
        margin: '0 10px',
        minHeight: '48px',
    },
    newFacilityButton: {
        alignSelf: 'center',
        borderRadius: '5px',
        color: 'black',
        border: '2px solid #f5f5f5',
        margin: '10px',
    },
})

export const FacilitiesDropDown = (props) => {
    const {
        showAll = true,
        facilities,
        facilityId,
        onFacilityChange,
        createFacility,
        updateFacility,
    } = props
    const [dialogOpenState, setDialogOpenState] = useState('')
    const [facilityToUpdate, setFacilityToUpdate] = useState(null)
    const [working, setWorking] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false)
    const classes = useStyles()
    const { t } = useTranslation()

    const getActiveFacility = () =>
        facilities?.find((f) => f.facility_id === facilityId)

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <Select
                        value={facilityId || 'default'}
                        className={classes.root}
                        id='facilities-select'
                        labelId='facilities-select-label'
                        open={selectOpen}
                        onOpen={() => setSelectOpen(true)}
                        onClose={() => setSelectOpen(false)}
                        onChange={(evt) => {
                            const { value } = evt.target
                            onFacilityChange &&
                                value !== 'new_facility' &&
                                onFacilityChange(value)
                        }}
                        IconComponent={() =>
                            selectOpen ? (
                                <IconButton
                                    onMouseDown={(evt) => {
                                        setSelectOpen(false)
                                    }}
                                >
                                    <KeyboardArrowUp />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onMouseDown={(evt) => {
                                        setSelectOpen(true)
                                    }}
                                >
                                    <KeyboardArrowDown />
                                </IconButton>
                            )
                        }
                    >
                        <MenuItem
                            disabled={!createFacility}
                            value='new_facility'
                            style={{ borderBottom: '1px solid #F2F2F2' }}
                            onClick={() => setDialogOpenState('create')}
                        >
                            <Grid container className={classes.listItem}>
                                <Grid item xs={12}>
                                    {t('new_facility')}
                                </Grid>
                            </Grid>
                        </MenuItem>
                        {showAll && (
                            <MenuItem value='show_all'>
                                <Grid container className={classes.listItem}>
                                    <Grid item xs={12}>
                                        {t('show_all')}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        )}
                        <MenuItem value='default'>
                            <Grid container className={classes.listItem}>
                                <Grid item xs>
                                    {t('default')}
                                </Grid>
                            </Grid>
                        </MenuItem>
                        {facilities?.map((fac) => (
                            <MenuItem
                                key={fac.facility_id}
                                value={fac.facility_id}
                            >
                                <Grid container className={classes.listItem}>
                                    <Grid item xs>
                                        {t(fac.title)}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        ))}
                    </Select>
                    {updateFacility && (
                        <IconButton
                            disabled={!getActiveFacility()}
                            onClick={(evt) => {
                                evt.stopPropagation()
                                evt.preventDefault()
                                setFacilityToUpdate(getActiveFacility())
                                setDialogOpenState('update')
                            }}
                        >
                            <BorderColorIcon />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <DialogWrapper
                title={
                    dialogOpenState === 'create'
                        ? t('new_facility')
                        : dialogOpenState === 'update'
                        ? t('edit_facility')
                        : ''
                }
                open={!!dialogOpenState}
                setOpen={() => setDialogOpenState('')}
            >
                <NewFacilityForm
                    working={working}
                    values={
                        dialogOpenState === 'update' ? facilityToUpdate : null
                    }
                    onSubmit={async (facility) => {
                        setWorking(true)
                        dialogOpenState === 'create'
                            ? createFacility &&
                              (await createFacility(facility, t))
                            : updateFacility &&
                              (await updateFacility(facility, t))
                        setDialogOpenState('')
                        setWorking(false)
                    }}
                />
            </DialogWrapper>
        </>
    )
}

const NewFacilityForm = ({ working, values, onSubmit }) => {
    const classes = useStyles()

    const schema = {
        type: 'object',
        properties: {
            title: {
                type: 'string',
            },
            location: {
                type: 'object',
                properties: {
                    address: {
                        type: 'string',
                    },
                    postalCode: {
                        type: 'string',
                    },
                    city: {
                        type: 'string',
                    },
                    state: {
                        type: 'string',
                    },
                    country: {
                        type: 'string',
                        enum: [
                            'us',
                            'greece',
                            'Argentina',
                            'Belize',
                            'Bolivia',
                            'Brazil',
                            'Chile',
                            'Colombia',
                            'Costa Rica',
                            'Cuba',
                            'Dominican Republic',
                            'Ecuador',
                            'El Salvador',
                            'Falkland Islands',
                            'French Guiana',
                            'Guatemala',
                            'Guyana',
                            'Haiti',
                            'Honduras',
                            'Jamaica',
                            'Mexico',
                            'Nicaragua',
                            'Panama',
                            'Paraguay',
                            'Peru',
                            'Saint Lucia',
                            'Suriname',
                            'Uruguay',
                            'Venezuela',
                        ],
                    },
                },
            },
        },
        required: ['title'],
    }

    return (
        <div className={classes.dialogRoot}>
            <DynamicForm
                values={values}
                schema={schema}
                loading={working}
                onSubmit={onSubmit}
                className={classes.formRoot}
            />
        </div>
    )
}
