import '../../styling/slider.css'

const Slider = (props) => {
	const { value } = props
	return (
		<div className='container'>
			<div className='slider-container'>
				<div
					className='pointer'
					style={{
						left: `calc(${Math.min(2 + Number(value), 100)}% - 20px)`,
					}}></div>
				<input type='range' min='0' max='100' value={value} disabled={true} />
			</div>
			<div className='slider-value'>{value}%</div>
		</div>
	)
}

export { Slider }
