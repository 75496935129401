/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'

import { Grid, makeStyles, Typography } from '@material-ui/core'

import { assessments } from '../../stateObjects/_exports'

import { ExpandMore } from '@material-ui/icons'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
    row: {
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '2px solid #f2f2f2',
        height: '84px',
        margin: '-1px',
        padding: '0 10px',
    },
    label: {
        fontSize: '16px',
        fontWeight: '600',
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'baseline',
    },
    tag: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
    },
}))

export default function LinkToAssessment(props) {
    const { enableLink = true, displayStatus = true } = props
    const { t } = useTranslation()
    let location = useLocation()
    let history = useHistory()
    const [disabled, setDisabled] = useState(true)

    const classes = useStyles()

    /**
     * List of resources of the selected assessment.
     * If a field is null, it will be ignored and the
     * corresponding component will not be rendered.
     *
     * @typedef	{Object} assessmentInfo
     *
     * @property	{string} category
     * @property	{string} path
     * @property	{(string|null)} [image_URL]
     * @property	{(string|null)} [audio_sample_EN_URL]
     * @property	{(string|null)} [audio_sample_GR_URL]
     */

    /** @type {assessmentInfo} */
    const assessmentInfo = props.assessmentInfo

    const isCompleted = assessments.status[assessmentInfo.path] === 'completed'
    const isFailed =
        assessments.status[assessmentInfo.path] === 'failed' &&
        assessments.failure_reason[assessmentInfo.path] !== 'no_available_model'
    const isSubmitted =
        assessments.status[assessmentInfo.path] === 'submitted' ||
        assessments.status[assessmentInfo.path] === 'processed' ||
        assessments.status[assessmentInfo.path] === 'in-review'
    const isNotSupported =
        assessments.status[assessmentInfo.path] === 'failed' &&
        assessments.failure_reason[assessmentInfo.path] === 'no_available_model'

    const go_to_assessment = (editable) => {
        history.push(location.pathname + '/' + assessmentInfo.path, {
            assessmentInfo: assessmentInfo,
            editable: editable,
        })
    }

    const not_submitted_yet =
        !isSubmitted && !isCompleted && !isFailed && !isNotSupported
    const can_submit = !assessments.readonly.bool && not_submitted_yet
    const can_view =
        (not_submitted_yet || enableLink) &&
        (!not_submitted_yet || !assessments.readonly.bool)

    useEffect(() => {
        setDisabled(!can_view)
    }, [])

    return (
        <Grid
            item
            container
            style={{
                cursor: can_view ? 'pointer' : 'default',
                opacity: disabled ? 0.38 : 1,
            }}
            className={classes.row}
            onClick={() => {
                if (can_view) {
                    go_to_assessment(can_submit)
                }
            }}
        >
            <Grid item className={classes.flex}>
                <span className={classes.label} style={{ marginRight: '30px' }}>
                    {t('label_' + assessmentInfo.path)}
                </span>
                {displayStatus && (
                    <span>
                        {isCompleted && (
                            <Typography className={classes.tag}>
                                {t('task_status_completed')}
                                <CheckCircleOutlineIcon
                                    fontSize='small'
                                    style={{ color: 'green' }}
                                />
                            </Typography>
                        )}

                        {isSubmitted && (
                            <Typography className={classes.tag}>
                                {t('task_status_processing')}
                                <HourglassEmptyIcon
                                    fontSize='small'
                                    style={{ color: 'orange' }}
                                />
                            </Typography>
                        )}

                        {isFailed && (
                            <Typography className={classes.tag}>
                                {t('task_status_failed')}
                                <ErrorOutlineOutlinedIcon
                                    fontSize='small'
                                    style={{ color: 'red' }}
                                />
                            </Typography>
                        )}

                        {isNotSupported && (
                            <Typography className={classes.tag}>
                                {t('task_status_not_supported')}
                                <BlockOutlinedIcon
                                    fontSize='small'
                                    style={{ color: 'orange' }}
                                />
                            </Typography>
                        )}

                        {not_submitted_yet &&
                            (assessments.readonly.bool ? (
                                <Typography className={classes.tag}>
                                    {t('task_status_closed')}
                                    <BlockOutlinedIcon
                                        fontSize='small'
                                        style={{ color: 'black' }}
                                    />
                                </Typography>
                            ) : (
                                <Typography className={classes.tag}>
                                    {t('task_status_ready')}
                                    <PublishOutlinedIcon
                                        fontSize='small'
                                        style={{ color: 'blue' }}
                                    />
                                </Typography>
                            ))}
                    </span>
                )}
            </Grid>
            <Grid item className={classes.flex}>
                <ExpandMore style={{ transform: 'rotate(-90deg)' }} />
            </Grid>
        </Grid>
    )
}
