import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DynamicForm } from '../dynamicForm/DynamicForm'
import auth from '../stateObjects/auth.state'

export default function LoginPage() {
    const [loading, setLoading] = useState(false)
    let history = useHistory()
    let location = useLocation()
    const { t } = useTranslation()

    let { from } = location.state || { from: { pathname: '/participants' } }

    let login = () => {
        auth.state.authenticate(() => {
            history.replace(from)
        })
    }

    const schema = {
        type: 'object',
        properties: {
            email: {
                type: 'string',
                format: 'email',
            },
            password: {
                type: 'password',
            },
        },
        required: ['email', 'password'],
    }

    return (
        <Grid id='login-form-container' container>
            <Grid item xs={12}>
                <Typography variant='h1' component='h1' style={{ flexGrow: 1 }}>
                    <Box fontFamily='open-sans' fontSize='h6.fontSize' m={1}>
                        {t('LoginHeader')}
                    </Box>
                </Typography>
                <DynamicForm
                    schema={schema}
                    loading={loading}
                    onSubmit={(values) => {
                        authAttempt(values, setLoading, login, t)
                    }}
                />
            </Grid>
        </Grid>
    )
}

function authAttempt(values, setLoading, login, t) {
    setLoading(true)

    // mock endpoint
    // let endpoint = 'http://127.0.0.1:3004/login'

    const endpoint = `${window._env_.REACT_APP_BACKEND_URL}/users/login`
    const headerz = new Headers()
    headerz.append('Content-Type', 'application/json')
    let body = JSON.stringify({
        email: values.email,
        password: values.password,
    })

    let requestOptions = {
        method: 'POST',
        headers: headerz,
        redirect: 'follow',
        body: body,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                setLoading(false)

                response.json().then((r) => {
                    sessionStorage.setItem('token', r.token)
                    auth.state.authenticate(login)
                })
            } else {
                setLoading(false)
                toast.error(t('LoginFailure'))
            }
        })
        .catch((err) => {
            setLoading(false)
            // TODO: Inform user of error
        })
}
