/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { DynamicForm } from '../../dynamicForm/DynamicForm'
import { fetchJsonSchema } from '../../dynamicForm/fetchJsonSchema'
import DialogWrapper from '../../utils/DialogWrapper'
import { SearchBar } from '../../utils/SearchBar'
import ParticipantEntry, { formatDateToLocaleString } from './_ParticipantEntry'
import { FacilitiesDropDown } from './FacilitiesDropDown'
import {
    createFacility,
    fetchFacilities,
    updateFacility,
} from './fetchFacilities'
import {
    fetchParticipants,
    fetchParticipantSupportedLanguages,
} from './fetchParticipants'

const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    formControl: {
        marginTop: '20px',
        marginBottom: '20px',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    marginSearchbar: {
        margin: '5px 10px 9px 0',
    },
    marginButton: {
        margin: '5px 0px 9px 0',
    },
    formContainer: {
        top: '5px',
        bottom: '600px',
        position: 'relative',
    },
    miniForm: {
        position: 'relative',
        maxWidth: '650px',
    },
    loaderContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        background: 'white',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchBarContainer: {
        marginBottom: '20px',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    centerContent: {
        alignContent: 'center',
        display: 'flex',
    },
    linksContainers: {
        display: 'flex',
        paddingTop: '10px',
        justifyContent: 'flex-end',
    },
    link: {
        color: '#00a79d',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
    },
}))

const languagesMap = {
    'en-US': 'EN',
    'el-GR': 'GR',
    'es-ES': 'ES',
}

const ParticipantsListContainer = () => {
    const { t, i18n } = useTranslation()
    const classes = useStyles()

    const cookieName = 'selectedFacility'
    const storedFacility = (value) => {
        if (value) {
            const oneYearLater = new Date(
                new Date().setFullYear(new Date().getFullYear() + 1),
            )
            setCookie(cookieName, value, {
                expires: oneYearLater,
            })
        } else {
            return cookies[cookieName]
        }
    }
    const [loading, setLoading] = useState(true)
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [participantList, setParticipantList] = useState(null)
    const [supported_languages, setSupportedLanguages] = useState([])
    const [facilities, setFacilities] = useState([])
    const [cookies, setCookie] = useCookies([cookieName])
    const [facilityId, setFacilityId] = useState(storedFacility() || 'show_all')
    const [participantFilter, setParticipantFilter] = useState('')
    const [schemaVersion, setSchemaVersion] = useState(null)
    const [pdfUrl, setPdfUrl] = useState('')

    useEffect(() => {
        setParticipantFilter('')
        setLoading(true)
        fetchFacilities(setFacilities, t)
        setParticipantList(null)
        fetchParticipants(setLoading, setParticipantList, t, facilityId)
        fetchParticipantSupportedLanguages(setLoading, setSupportedLanguages, t)
        fetchJsonSchema(() => {}, setSchemaVersion, t)
    }, [facilityId])

    useEffect(() => {
        const languageShort = languagesMap[i18n.language]
        setPdfUrl(
            `https://storage.googleapis.com/langaware-data/docs/user_manuals/LANGaware%20Platform%20Web%20App%20Manual%20${languageShort}.pdf`,
        )
    }, [i18n.language])

    useEffect(() => {
        if (!facilityId || facilityId === 'default' || !facilities?.length) {
            return
        }

        const facility = facilities?.find((f) => f.facility_id === facilityId)
        if (!facility) {
            storedFacility('show_all')
            setFacilityId('show_all')
        }
    }, [facilityId, facilities])

    return (
        <>
            <DialogWrapper
                title={t('new_participant')}
                open={dialogIsOpen}
                setOpen={setDialogIsOpen}
            >
                <ParticipantAdditionMiniForm
                    facility={facilityId}
                    participantList={participantList}
                    supported_languages={supported_languages}
                    setParticipantList={setParticipantList}
                    facilities={facilities}
                    setOpen={setDialogIsOpen}
                    schemaVersion={schemaVersion}
                />
            </DialogWrapper>

            {loading ? (
                <div
                    id='loading-participant-list'
                    align='center'
                    style={{ marginTop: 100 }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <Grid container>
                    <Grid item xs={12} className={classes.linksContainers}>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            className={classes.link}
                            href={pdfUrl}
                        >
                            <PictureAsPdfIcon /> &nbsp;
                            {t('langaware_manual')}
                        </a>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item>
                            <FormControl
                                variant='standard'
                                className={classes.formControl}
                            >
                                <FacilitiesDropDown
                                    facilityId={facilityId}
                                    facilities={facilities}
                                    onFacilityChange={(fId) => {
                                        storedFacility(fId)
                                        setFacilityId(fId)
                                    }}
                                    createFacility={async (_facility) => {
                                        await createFacility(_facility, t)
                                        fetchFacilities(setFacilities, t)
                                    }}
                                    updateFacility={async (_facility) => {
                                        await updateFacility(_facility, t)
                                        fetchFacilities(setFacilities, t)
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        className={classes.searchBarContainer}
                    >
                        <Grid item xs={12} sm>
                            <SearchBar
                                label={t('search_participants')}
                                className={classes.marginSearchbar}
                                options={(participantList ?? []).map(
                                    (participant) => participant.shortcode,
                                )}
                                onInputChange={(_, value) => {
                                    setParticipantFilter(value)
                                }}
                            />
                        </Grid>
                        <Grid item className={classes.centerContent}>
                            <Button
                                size='large'
                                variant='outlined'
                                className={classes.marginButton}
                                onClick={() => setDialogIsOpen(true)}
                            >
                                + {t('NewPart')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.tableContainer}>
                        <ParticipantsList
                            participants={participantList}
                            filter={participantFilter}
                            facilities={facilities}
                            facility_id={facilityId}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )
}

const ParticipantsList = (props) => {
    const { participants, filter = '', facilities, facility_id } = props

    const { t, i18n } = useTranslation()

    const matchesFilter = (participant) => {
        const p_id = participant.participant_id
        const shortcode = participant.shortcode
        const gender = t(participant.gender)
        const birthday = formatDateToLocaleString(participant, i18n.language)
        const language = t(participant.language)
        const textToFilter =
            `${p_id} ${shortcode} ${gender} ${birthday} ${language}`.toLowerCase()

        return textToFilter.indexOf(filter.toLowerCase()) > -1
    }

    const filterParticipants = (participant) => {
        if (!matchesFilter(participant)) {
            return false
        }

        return (
            facility_id === 'show_all' ||
            facility_id === participant.facility_id ||
            (!facility_id && !participant.facility_id) ||
            (facility_id === 'default' && !participant.facility_id)
        )
    }

    return (
        <Table aria-label='participants table' id='participant-list'>
            {participants?.length ? (
                <TableBody>
                    <ParticipantsHeader />
                    {participants.filter(filterParticipants).map((entry) => (
                        <ParticipantEntry
                            facilities={facilities}
                            entry={entry}
                            key={entry.participant_id.toString()}
                        />
                    ))}
                </TableBody>
            ) : (
                participants === null && (
                    <div
                        id='loading-participant-list'
                        align='center'
                        style={{ marginTop: 100, padding: 10 }}
                    >
                        <CircularProgress />
                    </div>
                )
            )}
        </Table>
    )
}

const ParticipantsHeader = () => {
    const { t } = useTranslation()

    return (
        <TableRow>
            <TableCell align='left' style={{ width: '50%' }}>
                ID
            </TableCell>
            <TableCell align='left'>{t('Lang')}</TableCell>
            <TableCell align='left'>{t('Gend')}</TableCell>
            <TableCell align='left'>{t('birthday')}</TableCell>
            <TableCell align='left'>{t('facility')}</TableCell>
            <TableCell align='center'></TableCell>
            <TableCell style={{ width: '50px' }}></TableCell>
        </TableRow>
    )
}

const ParticipantAdditionMiniForm = (props) => {
    const {
        participantList,
        setParticipantList,
        supported_languages,
        facility,
        facilities,
        setOpen,
        schemaVersion,
    } = props
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [facilityId, setFacilityId] = useState(
        !facility || facility === 'show_all' ? 'default' : facility,
    )
    const classes = useStyles()

    const ageLimit = 3
    const maximumBirthDate = new Date(
        new Date().setDate(new Date().getDate() - 360 * ageLimit),
    )
    const schema = {
        type: 'object',
        properties: {
            identification: {
                title: 'participant_identification',
                description:
                    'Optional string for client side identification. This can be used by client application in order to add a unique identifier for the created participant.',
                type: 'string',
                maxLength: 64,
            },
            gender: {
                type: 'string',
                description: "Pick your participant's gender",
                enum: ['male', 'female', 'other'],
            },
            language: {
                type: 'string',
                enum: supported_languages,
            },
            birthday: {
                type: 'object',
                properties: {
                    year: {
                        type: 'integer',
                        format: 'int32',
                        minimum: 1900,
                        maximum: maximumBirthDate.getFullYear(),
                    },
                    month: {
                        type: 'integer',
                        format: 'int32',
                        minimum: 1,
                        maximum: 12,
                    },
                    day: {
                        type: 'integer',
                        format: 'int32',
                        minimum: 1,
                        maximum: 31,
                    },
                },
            },
        },
        required: ['gender', 'language', 'birthday', 'version'],
    }
    const values = {
        language: i18n.language,
    }
    const overrides = {
        placeholder: { identification: t('could_be_left_empty') },
        dateLimits: {
            birthday: {
                min: new Date(1900, 0, 2),
                max: new Date(),
            },
        },
    }

    return (
        <>
            <div align='center' className={classes.miniForm}>
                <FormControl className={classes.formControl}>
                    <FacilitiesDropDown
                        t={t}
                        showAll={false}
                        facilityId={facilityId}
                        facilities={facilities}
                        onFacilityChange={setFacilityId}
                    />
                </FormControl>
                <div className={classes.formContainer}>
                    <DynamicForm
                        version={schemaVersion}
                        schema={schema}
                        values={values}
                        loading={loading}
                        overrides={overrides}
                        onSubmit={(pValues) => {
                            const facility_id =
                                facilityId === 'default' ? null : facilityId
                            createParticipant(
                                pValues,
                                facility_id,
                                participantList,
                                setParticipantList,
                                setLoading,
                                setOpen,
                                t,
                            )
                        }}
                    />
                </div>
            </div>
        </>
    )
}

const createParticipant = (
    _input,
    facility_id,
    participantList,
    setParticipantList,
    setLoading,
    setOpen,
    t,
) => {
    setLoading(true)

    let authToken = sessionStorage.getItem('token')
    let endpoint = window._env_.REACT_APP_BACKEND_URL + '/participants/create'
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())
    headerz.append('Content-Type', 'application/json')

    let body = {
        facility_id,
        participant_form: _input,
    }
    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(body),
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            setLoading(false)
            if (response.status === 200) {
                response.json().then((r) => {
                    const newbie = {
                        day_of_birth: _input.birthday.day,
                        month_of_birth: _input.birthday.month,
                        year_of_birth: _input.birthday.year,
                        facility_id: facility_id,
                        gender: _input.gender,
                        language: _input.language,
                        participant_id: r.participant_id,
                        shortcode: r.shortcode,
                    }
                    setParticipantList([...(participantList ?? []), newbie])
                    setOpen(false)
                    toast.success(
                        t('ParticipantCreationSuccess') + ' ' + r.shortcode,
                    )
                })
            } else if (response.status === 400) {
                toast.error(
                    t('ParticipantCreationFailure') +
                        ' ' +
                        t('ParticipantAlreadyExists'),
                )
            } else {
                toast.error(t('ParticipantCreationFailure'))
            }
        })
        .catch((err) => {
            toast.error(t('ParticipantCreationFailure'))
            setLoading(false)
        })
}

export { ParticipantsListContainer, ParticipantsList }
