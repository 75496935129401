/**
 * Find and replace any illegal/special characters
 * with default values in order to render a valid json
 * response.
 * 
 * @param {String} results - Stringified JSON input
 * @returns {Object}
*/
export default function sanitize_results(results) {
   
    /*
        This is the only safeguard for now but as new
        corner cases emerge, multiple methods can be chained.
     */
    const sanitized_results = results.replace('NaN','0')
    
    try {
        return JSON.parse(sanitized_results)
    }
    catch (err) {
        throw new Error('JSON sanitization attempt failed!')
    }
    
}