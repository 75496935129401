import { toast } from 'react-toastify';
import Retry from '../../utils/Retry';

export function fetchVisits(setLoading, setVisitList, p_id) {

  let authToken = sessionStorage.getItem('token');
  let endpoint = window._env_.REACT_APP_BACKEND_URL + '/visits/' + p_id + '/list';
  let headerz = new Headers(); headerz.append("X-Alzheimer-Service-Token", authToken.toString());

  let requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: headerz
  };

  fetch(endpoint, requestOptions).then(response => {
    if (response.status === 200) {
      setLoading(false);

      response.json().then(r => setVisitList(r));
    }
    else {
      setLoading(false);
      toast.error(<Retry />);
    }

  }).catch(err => {
    setLoading(false);
    toast.error(<Retry />)
  });
}
