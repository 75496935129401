import {
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { BadgePanel } from '../../utils/BadgePanel'
import { typography } from '../../utils/typography'

export const ParticipantDetailsPanel = (properties) => {
    const { visitInfo, t, participant, shortcode, visitList } = properties
    const { i18n } = useTranslation()
    const allowedComorbidities = [
        'smoking',
        'obesity',
        'arterial_hypertension',
        'no_smoking_10_yrs_or_more',
        'arterialHypertension',
    ]

    const getClass = (value) => {
        if (value === 'smoking') {
            return 'high-risk'
        } else if (value === 'no_smoking_10_yrs_or_more') {
            return 'low-risk'
        }
        return 'high-risk'
    }

    const age = new Date().getFullYear() - Number(participant.year_of_birth)
    const diagnoses =
        (participant.diagnosis?.length &&
            participant.diagnosis
                ?.filter(
                    (diagnosis) =>
                        diagnosis.type === 'diseases' ||
                        diagnosis.type === 'disorders',
                )
                .map((diagnosis) => diagnosis.values)
                .flat(2)) ||
        null

    const onlyUnique = (value, index, self) => self.indexOf(value) === index
    let drugAbuseList =
        participant.diagnosis?.length &&
        participant.diagnosis
            ?.filter((diagnosis) => diagnosis.type === 'drug_abuse')
            ?.map((diagnosis) => diagnosis.values)
            ?.flat(2)
            ?.map((value) => (value === 'alcoholism' ? value : 'drug_abuse'))
            ?.filter(onlyUnique)

    let listBehaviourItems = participant.comorbidities?.length
        ? participant.comorbidities
        : Object.entries(participant.comorbidities || {})
              .filter(([_, entry]) => entry === 'Smoking' || entry === true)
              .map(([key, _]) => key)

    // Make sure they are not null
    listBehaviourItems = listBehaviourItems || []
    drugAbuseList = drugAbuseList || []
    listBehaviourItems = [...listBehaviourItems, ...drugAbuseList]
        .filter((value) => allowedComorbidities.indexOf(value) > -1)
        .map((value) => (
            <ListItem style={{ padding: 0 }} key={value}>
                <ListItemText primary={t(value)} />
                <ListItemIcon>
                    <Box
                        style={{ marginLeft: '10px' }}
                        component='span'
                        className={`circle ${getClass(value)}`}
                    />
                </ListItemIcon>
            </ListItem>
        ))

    const handleCopyValueClick = (value) => {
        navigator.clipboard.writeText(value)
    }

    const convertToLocalDate = (dateString) =>
        dateString
            ? new Date(dateString).toLocaleDateString(i18n.language, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
              })
            : ''

    const profession =
        participant.profession?.other ||
        participant.profession?.occupation ||
        participant.profession

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    {typography('body1', null, shortcode)}
                </Grid>
                <Grid item xs>
                    {typography(
                        'body1',
                        `${t('user_uuid')}:`,
                        participant.participant_id,
                        'right',
                    )}
                </Grid>
                <Grid item>
                    <IconButton
                        title={t('Copy the participan id')}
                        onClick={() => {
                            handleCopyValueClick(participant.participant_id)
                            toast.success(
                                t('Participant id was copied to the clipboard'),
                            )
                        }}
                    >
                        <FileCopy fontSize='small' />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    {typography(
                        'body1',
                        `${t('visit_uuid')}:`,
                        visitInfo.visit_id,
                        'right',
                    )}
                </Grid>
                <Grid item>
                    <IconButton
                        title={t('Copy the visit info id')}
                        variant='contained'
                        onClick={() => {
                            handleCopyValueClick(visitInfo.visit_id)
                            toast.success(
                                t('Visit id was copied to the clipboard'),
                            )
                        }}
                    >
                        <FileCopy fontSize='small' />
                    </IconButton>
                </Grid>
            </Grid>
            {typography(
                'body1',
                `${t('last_visit')}:`,
                convertToLocalDate(visitInfo.created_at),
                'left',
                {
                    textTransform: 'capitalize',
                },
            )}
            <br />
            <BadgePanel
                backgroundcolor='#c9c9c9'
                title={t('demographics_')}
                xs={12}
                elevation={0}
                style={{ width: '100%' }}
            >
                {typography('body1', `${t('age_')}:`, age || 'N/A')}
                {typography(
                    'body1',
                    `${t('gender_')}:`,
                    t(participant.gender) || 'N/A',
                )}
                {typography(
                    'body1',
                    `${t('education_')}:`,
                    t(participant.education) || 'N/A',
                )}
                {typography(
                    'body1',
                    `${t('profession_')}:`,
                    t(profession) || 'N/A',
                )}
            </BadgePanel>
            <br />
            <BadgePanel
                backgroundcolor='#c9c9c9'
                title={t('history_')}
                xs={12}
                elevation={0}
                style={{ width: '100%' }}
            >
                {typography(
                    'body1',
                    `${t('other_diagnosis')}:`,
                    diagnoses?.join(', ') || 'N/A',
                )}
                {typography(
                    'body1',
                    `${t('previous_visit')}:`,
                    convertToLocalDate(
                        visitList?.sort((v1, v2) => {
                            const date1 = new Date(v1.created_at)
                            const date2 = new Date(v2.created_at)
                            return date1 > date2 ? -1 : 1
                        })?.[1]?.created_at,
                    ) || 'N/A',
                    'left',
                    {
                        textTransform: 'capitalize',
                    },
                )}
                {typography(
                    'body1',
                    `${t('total_visits')}:`,
                    visitList?.length || 'N/A',
                )}
            </BadgePanel>
            <br />
            <BadgePanel
                backgroundcolor='#c9c9c9'
                title={t('lifestyle_behaviors')}
                xs={12}
                elevation={0}
                style={{ width: '100%' }}
            >
                {listBehaviourItems?.length ? (
                    <List disablePadding style={{ width: '100%' }}>
                        {listBehaviourItems}
                    </List>
                ) : (
                    typography('body2', null, 'N/A', 'center')
                )}
            </BadgePanel>
        </>
    )
}
