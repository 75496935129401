import React from 'react'
import { useTranslation } from 'react-i18next'
import { Replay, ArrowBack } from '@material-ui/icons'
import { Grid } from '@material-ui/core'

export default function ErrorMsg(props) {
	const { t } = useTranslation()

	return (
		<div>
			{t('Retry_Message')}
			<Grid container>
				<Grid item xs={12}>
					({props.err})
				</Grid>
				<Grid item xs={6} style={{ textAlign: 'center' }}>
					<ArrowBack title='Go back' onClick={() => window.history.back()} />
				</Grid>
				<Grid item xs={6} style={{ textAlign: 'center' }}>
					<Replay title='Reload' onClick={() => window.location.reload()} />
				</Grid>
			</Grid>
		</div>
	)
}
