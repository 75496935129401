import { Button } from '@material-ui/core'
import { Cached, Error } from '@material-ui/icons'
import React from 'react'

export const LoadableButton = (props) => {
    const { label, disabled, loading, error, variant, onClick } = props

    return (
        <Button
            color='primary'
            variant={variant}
            onClick={(evt) => !loading && onClick(evt)}
            disabled={disabled}
        >
            {loading ? (
                <span className='rotate'>
                    <Cached />
                </span>
            ) : error ? (
                <>
                    <span className='error-button'>
                        <Error />
                    </span>
                </>
            ) : (
                <>+ {label}</>
            )}
        </Button>
    )
}
