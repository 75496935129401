import { toast } from 'react-toastify'
import { assessments } from '../../stateObjects/_exports'
import { getAuthenticatedProps } from '../../customHooks/useRemoteAuth'

export default function fetch_visit_status(
    v_id,
    t,
    setVisitActive,
    setIsRemoteVisit,
    setLoading,
    history
) {
    const url = `${window._env_.REACT_APP_BACKEND_URL}/visits/${v_id}`
    const { endpoint, headerz } = getAuthenticatedProps(url)

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            response
                .json()
                .then((res) => {
                    setLoading(false)
                    if (response.status === 200) {
                        set_readonly_mode(
                            res,
                            setVisitActive,
                            setIsRemoteVisit,
                        )
                    } else if (history && res.message === 'Invalid remote URL.') {
                        history.push('/invalid-url')
                    } else {
                        toast.error(t('VisitRetrievalFailure'))
                    }
                })
                .catch((err) => {
                    toast.error(t('VisitRetrievalFailure'))
                    setLoading(false)
                })
        })
}

/**
 * Set assessment list to read-only mode if the visit is inactive.
 * @param {Object} visit - Response from the /visits/(visit_id) endpoint.
 */
function set_readonly_mode(visit, setVisitActive, setIsRemoteVisit) {
    const active = visit.state !== 'inactive'
    const remote = visit.type === 'remote'

    assessments.readonly.setBool(!active)
    setVisitActive && setVisitActive(active)
    setIsRemoteVisit && setIsRemoteVisit(remote)
}
