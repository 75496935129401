import {
    Backdrop,
    Button,
    Card,
    CardActions,
    CardContent,
    Fade,
    Grid,
    makeStyles,
    Modal,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        width: '680px',
        margin: 'auto',
    },
    root: {
        boxShadow: theme.shadows[5],
        minWidth: 275,
        maxWidth: 1252,
        margin: 'auto',
        marginTop: 20,
        marginBottom: 20,
        padding: '10px 20px',
        background: '#F7F7F7',
        maxHeight: '100vh',
        overflow: 'auto',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        height: '54px',
        fontSize: '18px',
        color: '#B3B3B3',
        lineHeight: '54px',
        textTransform: 'uppercase',
    },
    bullets: {
        fontSize: '14px',
        color: '#808080',
        paddingLeft: '20px',
    },
    caption: {
        fontSize: '14px !important',
        width: '100% !important',
    },
    pos: {
        marginBottom: 12,
        paddingLeft: 20,
    },
}))

export default function ConsentModal() {
    const { t } = useTranslation()
    const classes = useStyles()

    /* .indexOf() returns -1 when the string is not found. */
    const isAdminURL = window.location.href.indexOf('/admin') !== -1

    /* Do not bother opening the user consent modal if an admin route is used. */
    const [consentModal, setConsentModal] = useState(!isAdminURL)

    const handleClose = () => {
        window.localStorage.setItem('LANGawareUserConsent', true)
        setConsentModal(false)
    }

    return (
        <div>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                className={classes.modal}
                open={consentModal}
                onClose={handleClose}
                closeAfterTransition
                disableEscapeKeyDown
                disableBackdropClick
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={consentModal}>
                    <Card elevation={0} className={classes.root}>
                        <CardContent
                            style={{ borderBottom: '1px solid #E6E6E6' }}
                        >
                            <Typography
                                className={classes.title}
                                color='textSecondary'
                                gutterBottom
                            >
                                {t('consent_header')}
                            </Typography>
                            <Typography className={classes.paper}>
                                {t('consent_list_header')}
                            </Typography>
                            <ul className={classes.bullets}>
                                <li>{t('consent_list_1')}</li>
                                <li>{t('consent_list_2')}</li>
                                <li>{t('consent_list_3')}</li>
                                <li>{t('consent_list_4')}</li>
                                <li>{t('consent_list_5')}</li>
                                <li>{t('consent_list_6')}</li>
                            </ul>
                            <br />
                            <Typography
                                variant='caption'
                                component='p'
                                className={classes.caption}
                            >
                                {t('consent_disclaimer_1')}
                                {t('consent_disclaimer_2')}
                            </Typography>
                            <br />
                            <Typography
                                variant='caption'
                                component='p'
                                className={classes.caption}
                            >
                                {t('consent_disclaimer_3')}
                                {t('consent_disclaimer_4')}
                            </Typography>
                            <br />
                            <Typography
                                variant='caption'
                                component='p'
                                className={classes.caption}
                            >
                                {t('Supported Browsers')}: Chrome 47+, Edge 79+,
                                Firefox 25+, Safari 3.2+
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={handleClose}
                                        variant='contained'
                                        color='primary'
                                    >
                                        {t('Consent Ok Button')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </Fade>
            </Modal>
        </div>
    )
}
