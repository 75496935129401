import { Grid, Typography } from '@material-ui/core'
import React from 'react'

export const typography = (
    variant,
    label,
    value,
    align = 'left',
    style = {},
    fontSize,
) => (
    <Grid container style={style} className='typography-container'>
        {label && (
            <Grid item xs style={{ width: '100%' }}>
                <Typography
                    variant={variant}
                    align={align}
                    style={{
                        flex: 1,
                        width: '100%',
                        color: 'grey',
                        marginRight: '5px',
                        whiteSpace: 'pre',
                        fontSize,
                        ...style,
                    }}
                    component='label'
                >
                    {label}
                </Typography>
            </Grid>
        )}
        <Grid item style={{ width: '100%' }}>
            <Typography
                variant={variant}
                align={align}
                style={{
                    flex: 2,
                    width: '100%',
                    minWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize,
                }}
                component='div'
            >
                {value}
            </Typography>
        </Grid>
    </Grid>
)
