/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { Cached, FileCopy } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import DialogWrapper from '../../utils/DialogWrapper'
import { generateShareLink } from './generateShareLink.js'

export const useStyles = makeStyles((_) => ({
    root: {
        minWidth: 275,
        maxWidth: 600,
        width: '80vw',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    boxContainer: {
        padding: '20px',
        textAlign: 'center',
        background: 'whitesmoke',
        minHeight: 113,
        justifyContent: 'center',
        display: 'flex',
        '& p': {
            fontFamily: 'monospace !important',
            fontSize: '14px',
            userSelect: 'none',
            wordBreak: 'break-all',
            whiteSpace: 'normal',
        },
    },
}))

export const AssessmentLinkShare = (props) => {
    const { participantId, visitId } = props
    const { t } = useTranslation()
    const [dialogOpen, setDialogOpen] = useState(false)

    return (
        <>
            <Button
                color='primary'
                variant='outlined'
                alt={'Test'}
                onClick={(_) => setDialogOpen(true)}
            >
                {t('share_visit')}
            </Button>
            <AssessmentLinkShareDialog
                {...{ dialogOpen, setDialogOpen, participantId, visitId }}
            />
        </>
    )
}
export const AssessmentLinkShareDialog = (props) => {
    const { dialogOpen, setDialogOpen, participantId, visitId } = props
    const { t, i18n } = useTranslation()
    const [link, setLink] = useState('')
    const classes = useStyles()

    useEffect(() => {
        if (dialogOpen) {
            generateShareLink(participantId, visitId, setLink)
        } else {
            setLink('')
        }
    }, [dialogOpen])

    const copyValueToClipboard = (value) => {
        navigator.clipboard.writeText(value)
    }

    return (
        <DialogWrapper
            title={t('share_remote_visit')}
            open={dialogOpen}
            setOpen={setDialogOpen}
        >
            <Card className={classes.root} elevation={0}>
                <div className={classes.boxContainer}>
                    <Grid container>
                        <Grid container item xs={10}>
                            <Grid item xs={12}>
                                <IconButton
                                    title={'Generate share link'}
                                    aria-label='generate share link'
                                    disabled={!!link && link !== 'FAIL'}
                                    onClick={() => {
                                        setLink('')
                                        generateShareLink(
                                            participantId,
                                            visitId,
                                            setLink,
                                        )
                                    }}
                                >
                                    <Cached className={!link ? 'rotate' : ''} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                {link === 'FAIL' ? (
                                    <Typography>
                                        {t('something_went_wrong_try_again')}
                                    </Typography>
                                ) : (
                                    <span>
                                        <Typography>
                                            {link &&
                                                `${link}&lang=${i18n.language}`}
                                        </Typography>
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid
                            container
                            item
                            xs={1}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <IconButton
                                aria-label='copy to clipboard'
                                title={'Copy link to clipboard'}
                                disabled={!link || link === 'FAIL'}
                                style={{
                                    height: 'fit-content',
                                    border: '1px solid rgb(233 233 233)',
                                    background: 'white',
                                    color: '#00a79d',
                                }}
                                onClick={() => {
                                    copyValueToClipboard(
                                        `${link}&lang=${i18n.language}`,
                                    )
                                    toast.success(t('link_copied_to_clipboard'))
                                }}
                            >
                                <FileCopy />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
                <CardContent>
                    <Typography
                        variant='body2'
                        color='textSecondary'
                        component='p'
                    >
                        {t('share_this_link')}
                    </Typography>
                </CardContent>
            </Card>
        </DialogWrapper>
    )
}
