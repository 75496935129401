import { Button } from '@material-ui/core'
import { Cached } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const ButtonWithLoader = (props) => {
    const { label, className, disabled, loading, onClick } = props
    const { t } = useTranslation()

    return (
        <Button
            fullWidth
            type='submit'
            color='primary'
            onClick={onClick}
            variant='outlined'
            className={className}
            style={{ maxHeight: '40px' }}
            disabled={disabled || loading}
        >
            {loading ? (
                <span className='rotate'>
                    <Cached />
                </span>
            ) : (
                <span>{t(label)}</span>
            )}
        </Button>
    )
}
