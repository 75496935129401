/*
  HOW TO USE IN COMPONENTS:

  1. import { useTranslation } from 'react-i18next'
  2. const { t, i18n } = useTranslation()
  3. JSX: {t("some_valid_entry_key")}
*/

import assessment_categories from './assessment.categ.json'
import assessment_descriptions from './assessment.desc.json'
import assessment_labels from './assessment.labels.json'
import assessment_status from './assessment.status.json'
import button_labels from './button_labels.json'
import comorbidities from './comorbidities.json'
import demographics_labels from './demographics.labels.json'
import demographics_values from './demographics.values.json'
import diagnoses from './diagnoses.json'
import miscellaneous from './miscellaneous.json'
import occupations from './occupations.json'
import user_consent from './user_consent.json'
import visits from './visits.json'
import results_labels from './results.labels.json'

const resources = {
  "en-US": {
    translation: {
      ...assessment_categories["en-US"],
      ...assessment_descriptions["en-US"],
      ...assessment_labels["en-US"],
      ...assessment_status["en-US"],
      ...button_labels["en-US"],
      ...comorbidities["en-US"],
      ...demographics_labels["en-US"],
      ...demographics_values["en-US"],
      ...diagnoses["en-US"],
      ...miscellaneous["en-US"],
      ...occupations["en-US"],
      ...user_consent["en-US"],
      ...visits["en-US"],
      ...results_labels["en-US"]
    }
  },

  "el-GR": {
    translation: {
      ...assessment_categories["el-GR"],
      ...assessment_descriptions["el-GR"],
      ...assessment_labels["el-GR"],
      ...assessment_status["el-GR"],
      ...button_labels["el-GR"],
      ...comorbidities["el-GR"],
      ...demographics_labels["el-GR"],
      ...demographics_values["el-GR"],
      ...diagnoses["el-GR"],
      ...miscellaneous["el-GR"],
      ...occupations["el-GR"],
      ...user_consent["el-GR"],
      ...visits["el-GR"],
      ...results_labels["el-GR"]
    }
  },

  "es-ES": {
    translation: {
      ...assessment_categories["es-ES"],
      ...assessment_descriptions["es-ES"],
      ...assessment_labels["es-ES"],
      ...assessment_status["es-ES"],
      ...button_labels["es-ES"],
      ...comorbidities["es-ES"],
      ...demographics_labels["es-ES"],
      ...demographics_values["es-ES"],
      ...diagnoses["es-ES"],
      ...miscellaneous["es-ES"],
      ...occupations["es-ES"],
      ...user_consent["es-ES"],
      ...visits["es-ES"],
      ...results_labels["es-ES"]
    }
  }
}

export default resources;

/* UNUSED RESOURCES

diagnosis icd10

"Healthy/Control":"Υγιής",
"Alzheimer's":"G30: Νόσος Alzheimer",
"Unspecified Dementia":"F03: Απροσδιόριστη άνοια",
"Dementia/Parkinson's":"F02.3: Άνοια / Νόσος Parkinson",
"Vascular Dementia":"F01: Αγγειακή άνοια",
"Mild Cognitive Impairment":"G31.84: Ήπια Γνωστική Έκπτωση",
"Parkinson's Disease":"G20: Νόσος Parkinson",
"Parkinsonism": "G21: Παρκινσονισμός",
"Multiple Sclerosis":"G35: Πολλαπλή σκλήρυνση",
"Other diseases of CNS": "G37: Άλλες ασθένειες του ΚΝΣ",
"Huntington's disease":"G10: Νόσος Huntington",

"Healthy/Control":"Healthy/Control",
"Alzheimer's":"G30: Alzheimer's",
"Unspecified Dementia":"F03: Unspecified Dementia",
"Dementia/Parkinson's":"F02.3: Dementia/Parkinson's",
"Vascular Dementia":"F01: Vascular Dementia",
"Mild Cognitive Impairment":"G31.84: Mild Cognitive Impairment",
"Parkinson's Disease":"G20: Parkinson's Disease",
"Parkinsonism":"G21: Parkinsonism",
"Multiple Sclerosis":"G35: Multiple Sclerosis",
"Other diseases of CNS":"G37: Other diseases of CNS",
"Huntington's disease":"G10: Huntington's disease",

=================================================================
comorbidities

'ankylosingSpondylitis':'Αγκυλοποιητική Σπονδυλίτιδα',
'anxiety': 'Ανησυχία',
'atrialFibrillation': 'Κολπική Μαρμαρυγή',
'cancer': 'Καρκίνος',
'cholesterol':'Χοληστερίνη',
'depression':'Κατάθλιψη',
'diabetes':'Διαβήτης',
'epilepsy':'Επιληψία',
'hypertension':'Υπέρταση',
'osteoarthritis':'Οστεοαρθρίτιδα',
'smoking':'Κάπνισμα',
'stroke':'Εγκεφαλικό επεισόδιο',

'ankylosingSpondylitis': 'Ankylosing Spondylitis',
'anxiety':'Anxiety',
'atrialFibrillation': 'Atrial Fibrillation',
'cancer': 'Cancer',
'cholesterol':'Cholesterol',
'depression':'Depression',
'epilepsy':'Epilepsy',
'hypertension':'Hypertension',
'osteoarthritis':'Osteoarthritis',
'smoking':'Smoking',
'stroke':'Stroke',

*/