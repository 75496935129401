import { toast } from 'react-toastify'
import { getAuthenticatedProps } from '../../customHooks/useRemoteAuth'
import { assessments } from '../../stateObjects/_exports'

export default function fetch_assessment_status(
    v_id,
    t,
    setRequiredTasks,
    setRequiredTasksDone,
    setLoading,
    setRecommendedTasks,
    history
) {
    const url = `${window._env_.REACT_APP_BACKEND_URL}/assessments/${v_id}/list`
    const { endpoint, headerz } = getAuthenticatedProps(url)

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            response.json().then((res) => {
                if (response.status === 200) {
                    assign_assessment_status(
                        res,
                        setRequiredTasks,
                        setRequiredTasksDone,
                        setRecommendedTasks,
                    )
                } else if (history && res.message === 'Invalid remote URL.') {
                    history.push('/invalid-url')
                } else {
                    toast.error(t('VisitAssessmentsRetrievalFailure'))
                }
            })

            setLoading(false)
        })
        .catch((err) => {
            toast.error(t('VisitAssessmentsRetrievalFailure'))
            setLoading(false)
        })
}

/**
 * Set state of assessments according to response.
 * @param {Object[]} Result - Response from the /assessments/(visit_id)/list endpoint.
 *
 * @param {string} Result.list.assessment_id
 * @param {string} Result.list.type
 * @param {string} Result.list.status
 * @param {string} Result.list.failure_reason
 * @param {Array} Result.required
 */
function assign_assessment_status(
    Result,
    setRequiredTasks,
    setRequiredTasksDone,
    setRecommendedTasks,
) {
    // Assign values
    Result.list.forEach((obj) => {
        assessments.status['set_' + obj.type](obj.status)
        assessments.ids['set_' + obj.type](obj.assessment_id)
        assessments.failure_reason['set_' + obj.type](obj.failure_reason)
    })
    Result.required.forEach((obj) => {
        assessments.required['set_' + obj](true)
        setRequiredTasks((required_tasks) => [...required_tasks, obj])
    })
    Result.recommended?.forEach((obj) => {
        setRecommendedTasks((recommendedTasks) => [...recommendedTasks, obj])
    })

    // Set the required tasks done flag
    var flag = true
    Result.required.forEach((obj) => {
        // If task required but not done yet, set to false
        if (assessments.required[obj] && assessments.ids[obj] == null) {
            flag = false
        }
    })

    setRequiredTasksDone && setRequiredTasksDone(flag)
}
