

import React from "react";
import { toast } from 'react-toastify';
import Retry from '../../utils/Retry';

export async function fetchVisitOffset(p_id, setVisitOffset) {

    let authToken = sessionStorage.getItem('token');
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/visits/${p_id}/offset`;
    let headerz = new Headers();
    headerz.append("X-Alzheimer-Service-Token", authToken.toString());

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz
    };

    return fetch(endpoint, requestOptions).then(response => {
        if (response.status === 200) {
            response.json().then(r => setVisitOffset(r.time_offset));
        }
        else {
            setVisitOffset(-2)
            toast.error(<Retry messageCode={'could_not_fetch_offset'} />);
        }

    }).catch(err => {
        setVisitOffset(-2)
        toast.error(<Retry messageCode={'could_not_fetch_offset'} />)
    });
}