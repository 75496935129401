/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { styled } from '@material-ui/styles'
import { Grid, Badge, Paper } from '@material-ui/core'
import { useRef } from 'react'
import { useEffect } from 'react'

const StyledBadge = styled(Badge)((props) => ({
    '& .MuiBadge-anchorOriginTopLeftRectangle': {
        overflow: 'hidden',
        background: props.background,
    },
}))

const BadgePanel = (props) => {
    const { color, backgroundcolor, title, hidden, tooltip } = props
    const containerRef = useRef(null)
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if (!hidden && initialized) {
            setTimeout(() => {
                containerRef.current?.scrollIntoView()
            }, 10)
        }
        setInitialized(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hidden])

    return hidden ? (
        <></>
    ) : (
        <Grid item {...props} ref={containerRef}>
            {title ? (
                <StyledBadge
                    color={color || 'secondary'}
                    background={backgroundcolor}
                    badgeContent={title}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                ></StyledBadge>
            ) : (
                <></>
            )}
            <Paper
                elevation={props.elevation}
                style={{
                    backgroundColor: 'rgb(246 246 246)',
                    padding: 20,
                    borderRadius: '4px',
                    overflow: 'hidden',
                    minHeight: '100%',
                    border: '1px solid #dfdfdf',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    ...props.style,
                }}
                title={tooltip}
            >
                {props.children}
            </Paper>
        </Grid>
    )
}

export { BadgePanel }
