import { useLocation } from 'react-router-dom'

export default function useRemoteAuth() {
    const { search } = useLocation()
    const remoteToken = new URLSearchParams(search).get('remote_token')
    if (remoteToken) {
        sessionStorage.setItem('remote_token', remoteToken)
    }
}

export function getAuthenticatedProps(endpoint) {
    const headerz = new Headers()
    const authToken = sessionStorage.getItem('token')
    const remote_token = sessionStorage.getItem('remote_token')

    if (remote_token) {
        endpoint = endpoint.concat(`/remote/${remote_token}`)
    } else if (authToken) {
        headerz.append('X-Alzheimer-Service-Token', authToken?.toString())
    }

    return { endpoint, headerz, remoteToken: remote_token }
}
