import React from 'react'
import { toast } from 'react-toastify'

import ErrorMsg from '../../utils/ErrorMsg'
import sanitize_results from '../../utils/sanitize_results'
import validate_profiling from '../../utils/validate_profiling'

function fetch_assessment_results(
    assessment_id,
    setResults,
    setResultColor,
    setLoading,
    t,
) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/assessments/poll/${assessment_id}`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.text().then((results) => {
                    try {
                        const _results = sanitize_results(results)

                        /*
              If the AD,NC and Sample do not have the same categories
              an exception will be thrown.
          */
                        validate_profiling(_results.profiling)

                        setResults(_results)

                        if (_results.prediction === 'low') {
                            setResultColor('lightgreen')
                        } else if (_results.prediction === 'medium') {
                            setResultColor('lightyellow')
                        } else if (_results.prediction === 'high') {
                            setResultColor('lightcoral')
                        }

                        setLoading(false)
                    } catch (err) {
                        setLoading(false)
                        toast.error(
                            <ErrorMsg
                                err={t('AssessmentResultParseFailure')}
                            />,
                            { autoClose: false },
                        )
                    }
                })
            } else {
                setLoading(false)
                toast.error(
                    <ErrorMsg err={t('AssessmentResultRetrievalFailure')} />,
                    { autoClose: false },
                )
            }
        })
        .catch((err) => {
            setLoading(false)
            toast.error(
                <ErrorMsg err={t('AssessmentResultRetrievalFailure')} />,
                { autoClose: false },
            )
        })
}

function fetch_visit_results(
    visit_uuid,
    setResults,
    setResultColor,
    setLoading,
    t,
    mute = false,
) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/assessments/poll/visit/${visit_uuid}`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.text().then((results) => {
                    try {
                        const _results = sanitize_results(results)

                        if (_results.message) {
                            setResults(_results)

                            setLoading && setLoading(false)
                        } else {
                            // If the AD,NC and Sample do not have the same categories
                            // an exception will be thrown.

                            validate_profiling(_results.profiling)

                            setResults(_results)

                            if (_results.prediction === 'low') {
                                setResultColor && setResultColor('lightgreen')
                            } else if (_results.prediction === 'medium') {
                                setResultColor && setResultColor('lightyellow')
                            } else if (_results.prediction === 'high') {
                                setResultColor && setResultColor('lightcoral')
                            }

                            setLoading && setLoading(false)
                        }
                    } catch (err) {
                        setLoading && setLoading(false)
                        !mute &&
                            toast.error(
                                <ErrorMsg err={t('VisitResultParseFailure')} />,
                                { autoClose: false },
                            )
                    }
                })
            } else {
                setLoading && setLoading(false)
                !mute &&
                    toast.error(
                        <ErrorMsg err={t('VisitResultRetrievalFailure')} />,
                        { autoClose: false },
                    )
            }
        })
        .catch((err) => {
            setLoading(false)
            !mute &&
                toast.error(
                    <ErrorMsg err={t('VisitResultRetrievalFailure')} />,
                    {
                        autoClose: false,
                    },
                )
        })
}

export { fetch_assessment_results, fetch_visit_results }
