import { toast } from 'react-toastify'

import ErrorMsg from '../../utils/ErrorMsg'

export default function _fetch_participant_shortcode(p_id, setShortcode, setParticipant, setLoading, t) {

  let authToken = sessionStorage.getItem('token')
  let endpoint = window._env_.REACT_APP_BACKEND_URL + '/participants/' + p_id
  let headerz = new Headers(); headerz.append("X-Alzheimer-Service-Token", authToken.toString())

  let requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: headerz
  }

  fetch(endpoint, requestOptions).then(response => {
    if (response.status === 200) {
      response.json().then(r => {
        setShortcode(r.shortcode)
        setParticipant(r)
        setLoading(false)
      })
    }
    else {
      setLoading(false)
      toast.error(<ErrorMsg err={t('ParticipantInfoRetrievalFailure')} />,
        { autoClose: false })
    }
  }).catch(err => {
    setLoading(false)
    toast.error(<ErrorMsg err={t('ParticipantInfoRetrievalFailure')} />,
      { autoClose: false })
  })
}
