import React from 'react'

export const Stack = (props) => {
	const { direction, style } = props
	return (
		<div
			{...props}
			style={{
				display: 'flex',
				flexDirection: direction,
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: 5,
				width: '100%',
				position: 'relative',
				...style,
			}}>
			{props.children}
		</div>
	)
}
