import { Link as RouterLink } from 'react-router-dom'
import { Link as MaterialUiLink} from '@material-ui/core'

export default function Link(props){

    const { label, to } = props

    return (
        <MaterialUiLink component={RouterLink} to={to}>
            {label}
        </MaterialUiLink>
    )
}