import { makeStyles } from '@material-ui/core/styles'

/**
 * This snippet alters chart presentation
 * mode based on a width breakpoint.
 */

export const useStyles = makeStyles((theme) => ({
	parentChartContainer: {
		[theme.breakpoints.down('md')]: { flexDirection: 'column' },
		[theme.breakpoints.up('md')]: { flexDirection: 'row' },
	},
	childChartContainer: {
		// less than sm
		[theme.breakpoints.down('sm')]: { position: 'relative', width: '100%' },
		// sm = 600px
		[theme.breakpoints.only('sm')]: { position: 'relative', width: '100%' },
		// md = 900px
		[theme.breakpoints.only('md')]: { position: 'relative', width: '100%' },
		// lg = 1200px
		[theme.breakpoints.only('lg')]: { position: 'relative', width: '100%' },
		// xl = 1536px
		[theme.breakpoints.only('xl')]: { position: 'relative', width: '100%' },
	},
}))
