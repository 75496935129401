import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    container: {
        height: '56px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F7F7F7',
        borderRadius: '8px',
        paddingLeft: '15px',
    },
    icon: {
        margin: '0 10px 0 10px',
    },
    input: {
        border: 'none',
        background: 'none',
        marginTop: '0px',
        marginBottom: '0px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}))

export const SearchBar = (props) => {
    const { label, options, onInputChange } = props

    const classes = useStyles()

    return (
        <Autocomplete
            freeSolo
            disableClearable
            options={options}
            className='searchbar'
            id='participant-list-search'
            onInputChange={onInputChange}
            renderInput={(params) => (
                <Box className={props.className}>
                    <Box className={classes.container}>
                        <SearchIcon className={classes.icon} />
                        <TextField
                            {...params}
                            label={label}
                            margin='normal'
                            variant='filled'
                            className={classes.input}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                    </Box>
                </Box>
            )}
        />
    )
}
