import { Box, CircularProgress, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { typography } from '../../utils/typography'
import { SaveAsPDFButton } from './ResultsPage'

export const ResultsPageHeader = (props) => {
    const {
        results,
        shortcode,
        v_id,
        visitInfo,
        loading,
        printStarted,
        printFinished,
    } = props
    const [printing, setPrinting] = useState(false)
    const { t } = useTranslation()

    return (
        <Box display='flex' justifyContent='flex-end' margin='20px 0 20px 0'>
            {results && results.prediction && !loading && (
                <SaveAsPDFButton
                    p_code={shortcode}
                    v_id={v_id}
                    v_created_at={visitInfo.created_at}
                    results={results}
                    printStarted={() => {
                        setPrinting(true)
                        printStarted && printStarted()
                    }}
                    printFinished={() => {
                        setPrinting(false)
                        printFinished && printFinished()
                    }}
                />
            )}
            {printing && (
                <div className='loader'>
                    <Grid container>
                        <Grid item xs={12}>
                            {typography(
                                'h5',
                                null,
                                t('printing_your_document'),
                                'center',
                                { justifyContent: 'center' },
                                '16px',
                            )}
                        </Grid>
                        <Grid
                            item
                            xs
                            style={{ maxWidth: '50px', margin: 'auto' }}
                        >
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </div>
            )}
        </Box>
    )
}
