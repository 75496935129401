import '../../styling/gauge.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GaugeIcon } from '../../assets/resultsPage/gauge.svg'
import { ReactComponent as Needle } from '../../assets/resultsPage/gauge-needle.svg'
import { typography } from '../utils/typography'

const Gauge = (props) => {
    const { riskLevel } = props
    const { t } = useTranslation()

    return (
        <div className='gauge-container'>
            <GaugeIcon style={{ width: '100%', height: '100%' }} />
            <div className={riskLevel + ' needle-container'}>
                <Needle style={{ width: '100%' }} />
            </div>
            <div className={'legend'}>
                <p>{t('high_score')}</p>
                <p>{t('medium_score')}</p>
                <p>{t('low_score')}</p>
            </div>
            <div className={`text-container ${riskLevel}-risk-score`}>
                {typography(
                    'h5',
                    null,
                    t(`${riskLevel}-risk-score`),
                    'center',
                    { justifyContent: 'center' },
                )}
            </div>
        </div>
    )
}

export { Gauge }
