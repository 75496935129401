/**
 * Get two arrays and return a new one
 * with their common values. Throw exception
 * if the intersection is empty.
 * @param {Array} arr1 
 * @param {Array} arr2 
 * @returns {Array}
 */
export default function array_intersection(arr1, arr2) {
   
    try {
        let intersection = arr1.filter(val => arr2.includes(val) )
        
        if (intersection.length === 0) throw new Error('empty_intersection')
        else return intersection
    }
    catch (err) {
        throw new Error('array_intersection function failed')
    }
}
