import '../../styling/expandable-panel.css'

import { Button, Paper } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import React, { useState } from 'react'

import { typography } from './typography'

const ExpandablePanel = (props) => {
	const { expanded, onExpandedChanged, title } = props
	const [internalExpanded, setInternalExpanded] = useState(expanded)

	const onExpandedClickHandler = () => {
		setInternalExpanded(!internalExpanded)
		if (onExpandedChanged) {
			onExpandedChanged(!internalExpanded)
		}
	}

	return (
		<Paper
			elevation={props.elevation}
			className={`expandable-panel-control ${
				internalExpanded ? 'expanded' : 'not-expanded'
			}`}>
			<div
				style={{
					cursor: 'pointer',
				}}
				className='expandable-label-container transition-opacity'
				onClick={onExpandedClickHandler}>
				{typography('body1', title, null, 'right', { cursor: 'pointer' })}
			</div>
			<div style={{ maxWidth: '100%', minWidth: 'fit-content' }}>
				<div>
					<Button
						onClick={onExpandedClickHandler}
						style={{
							width: '64px',
							height: '64px',
							padding: '5px 0',
							border: '1px solid #dfdfdf',
						}}>
						<Menu />
					</Button>
				</div>
				<div className='children-container'>
					{internalExpanded ? props.children : <></>}
				</div>
			</div>
		</Paper>
	)
}

export { ExpandablePanel }
