import { toast } from 'react-toastify'
import ReplayIcon from '@material-ui/icons/Replay'
import { mapResponseToData } from './mapResponseToData'

export function Retry(i18n_message, t) {
    return (
        <div>
            {t(i18n_message)}
            <div align='center'>
                <ReplayIcon onClick={() => window.location.reload()} />
            </div>
        </div>
    )
}

export function fetchParticipantInfo(p_id, setInfo, diagnosisVersion, t) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = window._env_.REACT_APP_BACKEND_URL + '/participants/' + p_id
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())

    let requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: headerz,
    }

    fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((r) => {
                    setInfo(mapResponseToData(r, diagnosisVersion))
                })
            } else {
                toast.error(Retry('ParticipantInfoNotFound', t))
            }
        })
        .catch((err) => {
            toast.error(Retry('ParticipantInfoNotFound', t))
        })
}
