import { toast } from 'react-toastify'

export default async function set_visit_status(v_id, state, t) {
    let authToken = sessionStorage.getItem('token')
    let endpoint = `${window._env_.REACT_APP_BACKEND_URL}/visits/${v_id}/update`
    let headerz = new Headers()
    headerz.append('X-Alzheimer-Service-Token', authToken.toString())
    headerz.append('Content-Type', 'application/json')

    let requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: headerz,
        body: JSON.stringify(
            {
                state,
            },
            null,
        ),
    }

    return fetch(endpoint, requestOptions)
        .then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                toast.error(t('VisitUpdateFailure'))
            }
        })
        .catch((err) => {
            toast.error(t('VisitUpdateFailure'))
        })
}
