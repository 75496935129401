import { useState } from "react"

export default function useAuth() {

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const authenticate = (cb) => {
        setIsAuthenticated(true)
        cb()
    }

    const signout = (cb) => {
        setIsAuthenticated(false)
		sessionStorage.clear()
        cb()
    }
    return { isAuthenticated, authenticate, signout }
}