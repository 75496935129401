import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    LinearProgress,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { recordings } from '../stateObjects/_exports'
import HeaderMenu from '../utils/HeaderMenu'
import { Recorder } from '../utils/Recorder'
import fetchAudioSampleURL from './fetchAudioSampleURL'
import uploadRec from './uploadRec'

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        maxWidth: 1252,
        margin: 'auto',
        marginTop: 20,
        marginBottom: 20,
        padding: '10px 20px',
        background: '#F7F7F7',
        maxHeight: '100vh',
        overflow: 'auto',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        height: '54px',
        fontSize: '12px',
        color: '#B3B3B3',
        lineHeight: '54px',
        textTransform: 'uppercase',
    },
    mainTitle: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#808080',
    },
    bullets: {
        fontSize: '12px',
        color: '#808080',
        paddingLeft: '20px',
    },
    caption: {
        fontSize: '12px !important',
        width: '100% !important',
    },
    pos: {
        marginBottom: 12,
        paddingLeft: 20,
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
})

export function AssessmentContainer() {
    const { p_id, v_id } = useParams()
    const { t } = useTranslation()
    let location = useLocation()
    const { path } = location.state?.assessmentInfo || {}

    return (
        <>
            <HeaderMenu
                backButtonLabel={t('Back_Assessments')}
                backButtonPath={`/participants/${p_id}/visits/${v_id}`}
                menuInfo={t(`label_${path}`)}
            />

            <Assessment />
        </>
    )
}

export function AssessmentContainerRemote() {
    const { p_id, v_id } = useParams()
    const { t } = useTranslation()
    let location = useLocation()
    const { path } = location.state?.assessmentInfo || {}

    return (
        <>
            <HeaderMenu
                backButtonLabel={t('Back_Assessments')}
                backButtonPath={`/remote/participants/${p_id}/visits/${v_id}`}
                menuInfo={t(`label_${path}`)}
            />

            <Assessment />
        </>
    )
}

export function Assessment() {
    const { t, i18n } = useTranslation()
    let location = useLocation()
    let history = useHistory()
    const { v_id } = useParams()

    const {
        path,
        category,
        image_URL,
        rec_duration,
        audio_sample_EN_URL,
        audio_sample_GR_URL,
    } = location.state?.assessmentInfo || {}

    const descr_plaintext = t('description_' + path)
    const assessment_description = render_Multiline_Text(descr_plaintext)

    const toggle_fullscreen = () => {
        if (document.fullscreenEnabled && document.fullscreenElement === null) {
            document.getElementById('assessment-picture').requestFullscreen()
        } else {
            document.exitFullscreen()
        }
    }

    const image = image_URL ? (
        <img src={image_URL} style={{ width: '100%' }} alt={path} />
    ) : null

    const audio =
        i18n.language === 'en-US' ? audio_sample_EN_URL : audio_sample_GR_URL
    const audioPlayer = audio ? (
        <audio src={audio} style={{ width: '100%' }} controls={true}></audio>
    ) : null

    const [audioSampleURL, setAudioSampleURL] = useState(null)
    useEffect(() => {
        if (!location.state?.editable && !audioSampleURL) {
            fetchAudioSampleURL(setAudioSampleURL, v_id, category, path, t)
        }
    }, [location.state?.editable, audioSampleURL, v_id, category, path, t])
    const audioSamplePrompt = !location.state?.editable
        ? render_Multiline_Text(t('AssessmentAudioPrompt'))
        : null

    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Disclaimer />
                <Grid key='leftside' item xs={12} lg={8}>
                    <Paper
                        elevation={0}
                        align='center'
                        style={{ backgroundColor: '#e5f6f5' }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                {assessment_description}
                            </Grid>
                            <Grid item xs={12}>
                                {audioPlayer}
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    id='assessment-picture'
                                    onClick={toggle_fullscreen}
                                >
                                    {image}
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item container xs={12} lg={4}>
                    <Paper
                        elevation={0}
                        style={{
                            padding: 20,
                            backgroundColor: '#e5f6f5',
                            width: '100%',
                        }}
                    >
                        {location.state?.editable ? (
                            <Grid key='rightside' item xs={12}>
                                <Recorder
                                    enableStart
                                    enableTimer={rec_duration}
                                    notifyAfter={rec_duration - 10}
                                    onStop={save_rec_cb}
                                    onDiscard={() => discard_rec_cb(path)}
                                />
                                <SubmitButton
                                    endpoint={`${category}/${path}`}
                                    history={history}
                                />
                            </Grid>
                        ) : (
                            <>
                                <Grid key='rightside' item xs={12}>
                                    {audioSamplePrompt}
                                </Grid>
                                <Grid item xs={12}>
                                    <AudioPlayer url={audioSampleURL} />
                                </Grid>
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

//==========================================//
/*	Abstractions for more declarative code. */
//==========================================//

function SubmitButton(props) {
    const { assessment, p_id, v_id } = useParams()
    const { endpoint, history } = props
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const storage_assessment = recordings.storage[assessment]

    const upload_params = {
        recording: storage_assessment,
        endpoint: endpoint,
        p_id: p_id,
        v_id: v_id,
        setLoading: setLoading,
        history: history,
    }

    useEffect(() => {
        storage_assessment === null ? setDisabled(true) : setDisabled(false)
    }, [storage_assessment, assessment])

    return (
        <div align='center'>
            {loading ? (
                <CircularProgress />
            ) : (
                <Button
                    variant='contained'
                    color='primary'
                    disabled={disabled}
                    onClick={() => uploadRec(upload_params, t)}
                >
                    {t('SubmitButtonLabel')}
                </Button>
            )}
        </div>
    )
}

function discard_rec_cb(assessment) {
    if (!recordings.storage['set_' + assessment]) {
        return
    }
    recordings.storage['set_' + assessment](null)
}

function save_rec_cb(blob) {
    if (document.fullscreenElement) {
        document.exitFullscreen()
    }
    const path = window.location.pathname.split('/')
    const [assessment] = path.slice(-1)
    recordings.storage['set_' + assessment](blob)
}

function AudioPlayer(props) {
    const { url } = props

    const [blobUrl, setBlobUrl] = useState(undefined)
    const [error, setError] = useState(undefined)
    const classes = useStyles()

    useEffect(() => {
        if (!url) {
            return
        }
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const url = URL.createObjectURL(blob)
                setBlobUrl(url)
            })
            .catch((err) => setError(err))
    }, [url])

    return (
        <div>
            {!blobUrl && !error && <LinearProgress />}
            {blobUrl && (
                <audio
                    src={blobUrl}
                    style={{ width: '100%' }}
                    controls={true}
                ></audio>
            )}
            {error && (
                <div className={classes.error}>
                    Something went wrong. Try refreshing the page.
                </div>
            )}
        </div>
    )
}

function render_Multiline_Text(txt) {
    const line_arr = txt.split('\n')

    return (
        <Typography align='center' style={{ padding: 10 }}>
            {line_arr.map((line) => (
                <React.Fragment key={line}>
                    {' '}
                    {line}
                    <br />{' '}
                </React.Fragment>
            ))}
        </Typography>
    )
}

const Disclaimer = (props) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const cookieName = 'disclaimer_hidden'
    const [cookies, setCookie] = useCookies([cookieName])
    const [dismissed, setDismissed] = useState(cookies[cookieName])

    return (
        <>
            {!dismissed ? (
                <Grid xs={12} item>
                    <Card elevation={0} className={classes.root}>
                        <CardContent
                            style={{ borderBottom: '1px solid #E6E6E6' }}
                        >
                            <Typography
                                className={classes.title}
                                color='textSecondary'
                                gutterBottom
                            >
                                {t('disclaimer')}
                            </Typography>
                            <Typography
                                variant='h2'
                                component='h2'
                                className={classes.mainTitle}
                            >
                                {t('assessment_disclaimer_1')}
                            </Typography>
                            <br />
                            <ul className={classes.bullets}>
                                <li>{t('assessment_disclaimer_2')}</li>
                                <li>{t('assessment_disclaimer_3')}</li>
                                <li>{t('assessment_disclaimer_4')}</li>
                                <li>{t('assessment_disclaimer_5')}</li>
                            </ul>
                            <br />
                            <Typography
                                variant='caption'
                                component='p'
                                className={classes.caption}
                            >
                                {t('assessment_disclaimer_6')}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                size='small'
                                color='primary'
                                onClick={(_) => setDismissed(true)}
                            >
                                {t('dismiss')}
                            </Button>
                            <Button
                                size='small'
                                color='primary'
                                onClick={(_) => {
                                    setDismissed(true)
                                    const twoWeeks = 12096e5
                                    setCookie(cookieName, true, {
                                        expires: new Date(
                                            Date.now() + twoWeeks,
                                        ),
                                    })
                                }}
                            >
                                {t('dont_show_again')}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ) : (
                <></>
            )}
        </>
    )
}
