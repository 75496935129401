/**
 * A react state setter that changes the 'activeVisitExists' state var in VisitsList.jsx.
 *
 * @callback setActiveVisitExists
 * @param {boolean}
 */

/**
 * Return true if there is a visit whose state is "active".
 * @param {Array} visitList
 * @param {setActiveVisitExists}
 */
export default function active_visit_exists(visitList, setActiveVisitExists) {
    setActiveVisitExists(visitList.some((visit) => visit.state === 'active'))
}
