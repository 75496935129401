import { Backdrop, Fade, Grid, IconButton, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

import { typography } from '../../components/utils/typography'

const useStyles = makeStyles((theme) => ({
    modalHeader: {
        padding: '10px 20px 10px 20px',
    },
    modalBody: {
        padding: '14px 20px 20px 20px',
        overflow: 'auto',
        width: '100%',
        maxHeight: '70vh',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px',
    },
    paper: {
        maxWidth: '90vw',
        maxHeight: '90vh',
        borderRadius: '4px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
    },
}))

/**
 * @typedef {object} Props
 * @prop {boolean} open
 * @prop {function} setOpen
 *
 * @extends {Component<Props>}
 */
export default function DialogWrapper(props) {
    const { open, title, children, setOpen } = props
    const classes = useStyles()

    return (
        <div>
            <Modal
                open={open}
                className={classes.modal}
                BackdropComponent={Backdrop}
                BackdropProps={{ transitionDuration: 500 }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.modalHeader}>
                            <Grid
                                container
                                style={{ borderBottom: '1px solid #F2F2F2' }}
                            >
                                <Grid item xs={10}>
                                    {typography(
                                        'h5',
                                        title,
                                        null,
                                        'left',
                                        { color: 'black', fontWeight: '600' },
                                        '16px',
                                    )}
                                </Grid>
                                <Grid item xs={2} align='right'>
                                    <IconButton
                                        style={{
                                            marginRight: '-12px',
                                            color: 'black',
                                        }}
                                        onClick={() => setOpen(false)}
                                    >
                                        <CloseIcon fontSize='small' />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.modalBody}>{children}</div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}

DialogWrapper.propTypes = {
    /** open/close switch */
    setOpen: PropTypes.func.isRequired,
    /** modal's state */
    open: PropTypes.bool.isRequired,
}
