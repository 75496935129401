import { toast } from 'react-toastify'

import ErrorMsg from '../../utils/ErrorMsg'

export default function _fetch_visit_info(v_id, setVisitInfo, setLoading, t) {

  let authToken = sessionStorage.getItem('token')
  let endpoint = window._env_.REACT_APP_BACKEND_URL + '/visits/' + v_id
  let headerz = new Headers(); headerz.append("X-Alzheimer-Service-Token", authToken.toString())

  let requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: headerz
  }

  fetch(endpoint, requestOptions).then(response => {
    if (response.status === 200) {
      response.json().then(r => {
        setVisitInfo(r)
        setLoading(false)
      })
    }
    else {
      setLoading(false)
      toast.error(<ErrorMsg err={t('VisitRetrievalFailure')} />,
        { autoClose: false })
    }
  }).catch(err => {
    setLoading(false)
    toast.error(<ErrorMsg err={t('VisitRetrievalFailure')} />,
      { autoClose: false })
  })
}
