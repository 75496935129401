import { toast } from 'react-toastify'
import { getAuthenticatedProps } from '../customHooks/useRemoteAuth'
import { fetchParticipantLang } from './fetchParticipantLang'

export default async function uploadRec(params, t) {
    const { recording, endpoint, p_id, v_id, setLoading, history } = params

    setLoading(true)

    const language = await fetchParticipantLang(p_id, history)
    const url = `${window._env_.REACT_APP_BACKEND_URL}/assessments/${endpoint}/create`

    const {
        endpoint: _endpoint,
        headerz,
        remoteToken,
    } = getAuthenticatedProps(url)

    let formdata = new FormData()
    formdata.append('upload_file', recording, 'audio.data')
    formdata.append('language', language)
    formdata.append('participant_id', p_id)
    formdata.append('visit_id', v_id)

    const requestOptions = {
        method: 'POST',
        headers: headerz,
        redirect: 'follow',
        body: formdata,
    }

    fetch(_endpoint, requestOptions)
        .then((response) => {
            setLoading(false)
            response.json().then((res) => {
                if (response.status === 200) {
                    toast.success(t('AssessmentUploadSuccess'), {
                        autoClose: 1500,
                        hideProgressBar: false,
                        onClose: () => {
                            history.replace(
                                remoteToken
                                    ? `/remote/participants/${p_id}/visits/${v_id}`
                                    : `/participants/${p_id}/visits/${v_id}`,
                            )
                        },
                    })
                } else if (history && res.message === 'Invalid remote URL.') {
                    history.push('/invalid-url')
                } else {
                    toast.error(t('AssessmentUploadError'))
                }
            })
        })
        .catch((err) => {
            setLoading(false)
            // TODO: Inform user of error
        })
}
