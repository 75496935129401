/**
 * Reduce an array of strings with the 
 * AddState() callback and the corresponding 
 * [ x, set_x ] pairs will be created and
 * added to an accumulator object. 
 */
import { useState } from "react"

export default function useStateFactory(arr) {

	const AddState = (acc, curr) => {

		const [ x, set_x ] = useState(null)

		acc[curr] = x
		acc['set_' + curr] = set_x

		return acc
	}

	const res = arr.reduce(AddState, {})

	return res
}