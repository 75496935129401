import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    div: {
      fontSize: 14
    },
    caption: {
      position: 'absolute',
      right: '10%',
      fontSize: 12,
    },
    fontSize: 16,
    fontFamily: [
      'open-sans',
      'dosis-semibold',
      'static-bold',
      'Arial',
      'monospace',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#00a79d',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: 'white',
    },
  },
});

export default theme
