/* eslint-disable react-hooks/exhaustive-deps */
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import React, { useEffect, useState } from 'react'

import { ReactComponent as Icon1 } from '../../../assets/icons/icon1.svg'
import { ReactComponent as Icon2 } from '../../../assets/icons/icon2.svg'
import { ReactComponent as Icon3 } from '../../../assets/icons/icon3.svg'
import { ReactComponent as Icon4 } from '../../../assets/icons/icon4.svg'
import { ReactComponent as Icon8 } from '../../../assets/icons/icon8.svg'
import { BadgePanel } from '../../utils/BadgePanel'
import { Gauge } from '../../utils/Gauge'
import { Slider } from '../../utils/Slider'
import { Stack } from '../../utils/Stack'
import { typography } from '../../utils/typography'
import { GLOBAL_SPACING } from './ResultsPage'
import { BarChart } from './BarChart'
import { ParticipantDetailsPanel } from './ParticipantDetailsPanel'
import { RadarChart } from './RadarChart'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const cognitiveScoreExplanationMap = {
    low: 'cognitiveScore_low',
    medium: 'cognitiveScore_medium',
    high: 'cognitiveScore_high',
}

const VisitationResults = (props) => {
    const {
        t,
        results = {},
        suggestedActions,
        topOffset,
        showAll,
        isAdmin = false,
        visiblePanels,
    } = props
    const { profiling_distances } = results
    const [internalVisiblePanels, setVisiblePanels] = useState(
        () => visiblePanels || ['riskFactor', 'profilingChart'],
    )
    const scoreAnalysis = t(cognitiveScoreExplanationMap[results.prediction])
    const actionList = suggestedActions?.map((action) => (
        <ListItem key={action}>
            <ListItemText primary={action} />
        </ListItem>
    ))
    const overallDistance =
        (results.profiling_distance * 100)?.toFixed(1) || 'N/A'
    const toggleVisibility = (event, newVisibility) => {
        setVisiblePanels(newVisibility)
    }
    const panelHidden = (key) =>
        !showAll && internalVisiblePanels.indexOf(key) < 0

    return (
        <Grid container spacing={GLOBAL_SPACING}>
            <Grid item xs={12} className='visibility-buttons-container'>
                <VisibilityButtons
                    t={t}
                    isAdmin={isAdmin}
                    topOffset={topOffset}
                    actionList={actionList}
                    onValuesChanged={toggleVisibility}
                    visiblePanels={internalVisiblePanels}
                />
            </Grid>
            <BadgePanel
                xs={12}
                elevation={0}
                title={t('ParticipantProfile')}
                style={{ marginBottom: '20px' }}
                hidden={panelHidden('participantProfile')}
            >
                <ParticipantDetailsPanel {...props} />
            </BadgePanel>
            <BadgePanel
                xs
                elevation={0}
                className='printable'
                style={{ marginBottom: '20px' }}
                hidden={panelHidden('riskFactor')}
                title={t('cognitive_decline_risk_factor')}
            >
                <Stack direction='column'>
                    <Gauge riskLevel={results.prediction} />
                    <br />
                    <Divider variant='middle' style={{ minWidth: '80%' }} />
                    <br />
                    {typography('caption', null, scoreAnalysis, 'center')}
                </Stack>
            </BadgePanel>
            <BadgePanel
                xs={12}
                elevation={0}
                title={t('cognitive_profile')}
                style={{ marginBottom: '20px' }}
                hidden={panelHidden('profilingChart')}
            >
                <RadarChart results={results} t={t} />
                {typography(
                    'caption',
                    null,
                    t('the_cognitive_profiling_chart_illustrates_where'),
                    'center',
                )}
            </BadgePanel>
            <BadgePanel
                xs
                elevation={0}
                title={t('suggested_actions')}
                style={{ marginBottom: '20px' }}
                hidden={panelHidden('suggestedActions') || !actionList?.length}
            >
                {typography('h5', null, 'Title')}
                {typography(
                    'body1',
                    null,
                    'Text explaining possible actions/recommendations',
                )}
                <List>{actionList}</List>
            </BadgePanel>
            <BadgePanel
                xs={12}
                elevation={0}
                style={{ marginBottom: '20px' }}
                hidden={!isAdmin || panelHidden('overallIndex')}
                className='printable'
            >
                <Grid container>
                    <Grid item xs>
                        {typography(
                            'h6',
                            null,
                            t('overall_cognitive_comparison_index', {
                                flexBasis: 'max-content',
                            }),
                        )}
                    </Grid>
                    <Grid item xs>
                        <Slider value={overallDistance} />
                    </Grid>
                </Grid>
            </BadgePanel>
            <BadgePanel
                xs={12}
                elevation={0}
                style={{ marginBottom: '20px' }}
                title={t('cognitive_comparison_table')}
                hidden={
                    panelHidden('comparisonTable') ||
                    !profiling_distances.length
                }
                className='printable'
            >
                <CognitiveDeclineTable t={t} results={results} />
                <br />
                <br />
                {typography(
                    'caption',
                    null,
                    t('the_cognitive_comparison_table_illustrates'),
                    'center',
                )}
            </BadgePanel>
            <BadgePanel
                xs={12}
                elevation={0}
                style={{ marginBottom: '20px' }}
                title={t('cognitive_domain_bar_chart')}
                hidden={panelHidden('domainChart')}
                tooltip={t('cognitive_domain_bar_chart_tooltip')}
            >
                <BarChart results={results} t={t} />
                <br />
                {typography(
                    'caption',
                    null,
                    t('the_cognitive_domain_bar_chart_illustrates'),
                    'center',
                )}
            </BadgePanel>
            <BadgePanel
                elevation={0}
                backgroundcolor='orange'
                title={t('disclaimer')}
                xs={12}
                className='printable'
            >
                {typography('caption', null, t('disclaimer_message'), 'center')}
            </BadgePanel>
        </Grid>
    )
}

const VisibilityButtons = ({
    visiblePanels,
    onValuesChanged,
    topOffset,
    isAdmin,
    t,
}) => {
    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        setIsSticky(topOffset)
    }, [topOffset])

    return (
        <Grid
            item
            xs={12}
            className={
                isSticky ? 'sticky-container sticky' : 'sticky-container'
            }
        >
            <ToggleButtonGroup
                value={visiblePanels}
                className='sticky-content'
                onChange={onValuesChanged}
                style={{ padding: '5px', border: '1px solid #efefef' }}
                aria-label='text alignment'
            >
                <ToggleButton
                    value='participantProfile'
                    style={{ marginRight: '5px', border: 'none' }}
                    title={t('ParticipantProfile')}
                    aria-label='participant profile'
                >
                    <Person style={{ color: '#00a79d' }} />
                </ToggleButton>
                <ToggleButton
                    value='riskFactor'
                    aria-label='risk factor'
                    style={{ marginRight: '5px', border: 'none' }}
                    title={t('cognitive_decline_risk_factor')}
                >
                    <Icon1 />
                </ToggleButton>
                <ToggleButton
                    value='profilingChart'
                    aria-label='profilingChart'
                    style={{ marginRight: '5px', border: 'none' }}
                    title={t('cognitive_profile')}
                >
                    <Icon2 />
                </ToggleButton>
                {isAdmin ? (
                    <ToggleButton
                        hidden={isAdmin}
                        value='overallIndex'
                        aria-label='overall index'
                        style={{ marginRight: '5px', border: 'none' }}
                        title={t('overall_cognitive_comparison_index')}
                    >
                        <Icon8 />
                    </ToggleButton>
                ) : (
                    ''
                )}
                <ToggleButton
                    value='comparisonTable'
                    style={{ marginRight: '5px', border: 'none' }}
                    aria-label='comparison table'
                    title={t('cognitive_comparison_table')}
                >
                    <Icon3 />
                </ToggleButton>
                <ToggleButton
                    value='domainChart'
                    aria-label='domain chart'
                    style={{ border: 'none' }}
                    title={t('cognitive_domain_bar_chart')}
                >
                    <Icon4 />
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    )
}

const CognitiveDeclineTable = (props) => {
    const { t, i18n } = useTranslation()
    const { results } = props
    const { profiling_distances, interpretation } = results

    const pad = useCallback((num, size) => {
        num = num.toFixed(1)
        while (num.length < size) num = ' ' + num
        return num
    })

    const [cognitiveComparisonTableRows, setRows] = useState([])
    useEffect(() => {
        const sortedList = interpretation
            .sort((item1, item2) => (item1.value > item2.value ? -1 : 1))
            .map((adItem) =>
                profiling_distances.find(
                    (pdItem) => adItem.feature === pdItem.feature,
                ),
            )
        setRows(
            sortedList
                ?.filter((item) => !!item)
                .map((item) => (
                    <TableRow
                        title={t(`${item.feature} description`)}
                        key={`key-${item.feature}`}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                        <TableCell scope='row'>{t(item.feature)}</TableCell>
                        <TableCell align='right'>
                            <Slider value={pad(item.value * 100, 5)} />
                        </TableCell>
                    </TableRow>
                )) || [],
        )
    }, [interpretation, i18n.language])

    return (
        <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableBody>
                    <TableRow></TableRow>
                    <TableRow></TableRow>
                    {cognitiveComparisonTableRows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export { VisitationResults, VisibilityButtons, CognitiveDeclineTable }
