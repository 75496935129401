import { useState } from "react"

/*
    TODO: 
    The original intention of this was to add utlities
    that allow updating certain parts of the array,
    even after multi-level nesting. Turned out this does
    more harm than good, so it will be removed altogether.
    Sometime in the future.
    Hopefully.
*/

export default function useList() {

    const [ list, setList ] = useState([])

    return { list, setList }
}