import React, { useState } from 'react'
import { Button, TableCell, TableRow } from '@material-ui/core'
import { ThreeDotMenu } from '../../utils/ThreeDotMenu'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import set_visit_status from '../AssessmentsList/set_visit_status'
import { AssessmentLinkShareDialog } from '../AssessmentsList/AssessmentLinkShare'

export default function VisitEntry(props) {
    const { history, p_id, info } = props
    const { t } = useTranslation()
    const [dialogOpen, setDialogOpen] = useState(false)

    const menuItemsHandlers = {
        assessments: () =>
            history.push(`/participants/${p_id}/visits/${info.visit_id}`),
        PartDetails: () => history.push(`/participants/${p_id}/details`),
        share_visit: () => setDialogOpen(true),
        close_visit: async () => {
            const success = await set_visit_status(info.visit_id, 'inactive', t)

            if (!success) {
                return
            }

            toast.success(t('asssessment_was_updated'), {
                autoClose: 1500,
                hideProgressBar: false,
                onClose: () => {
                    history.go(0)
                },
            })
        },
    }

    return (
        <>
            <TableRow
                hover
                style={{
                    background: info.state === 'active' ? '#F4FAFA' : '',
                }}
            >
                <TableCell align='left'>
                    {`${info.visit_id.slice(0, 8)}`}
                </TableCell>
                <TableCell>
                    {info.type === 'remote' ? t('remote') : t('local')}
                </TableCell>
                <TableCell align='left'>
                    {info.created_at.slice(0, 16)}
                </TableCell>
                <TableCell
                    align='left'
                    style={{
                        color: info.state === 'active' ? '#00a79d' : '',
                        fontWeight: info.state === 'active' ? 'bold' : '',
                    }}
                >
                    {' '}
                    {t(info.state)}{' '}
                </TableCell>
                <TableCell align='left'>
                    <Button
                        variant='text'
                        onClick={() =>
                            history.push(
                                `/participants/${p_id}/visits/${info.visit_id}`,
                            )
                        }
                    >
                        {t('assessments')}
                    </Button>
                </TableCell>
                <TableCell style={{ textAlign: 'right' }}>
                    <ThreeDotMenu
                        options={[
                            {
                                name: 'PartDetails',
                                disabled: false,
                            },
                            {
                                name: 'share_visit',
                                disabled:
                                    info.state === 'inactive' ||
                                    info.type !== 'remote',
                            },
                        ]}
                        onMenuItemSelected={(menuItem) => {
                            menuItemsHandlers[menuItem]()
                        }}
                    />
                    <AssessmentLinkShareDialog
                        {...{
                            dialogOpen,
                            setDialogOpen,
                            participantId: p_id,
                            visitId: info.visit_id,
                        }}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}
