import { Button, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ThreeDotMenu } from '../../utils/ThreeDotMenu'

const zeroPad = (num, places) => `${num}`.padStart(places, '0')

export const formatDateToLocaleString = (participant, language) => {
    let birthday = participant.year_of_birth
    let fullFirthday = null
    if (participant.month_of_birth) {
        birthday += `/${zeroPad(participant.month_of_birth, 2)}`
    }
    if (participant.day_of_birth) {
        birthday += `/${zeroPad(participant.day_of_birth, 2)}`
    }
    if (birthday.length === 10) {
        var options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }
        fullFirthday = new Date(birthday).toLocaleDateString(language, options)
    }
    return fullFirthday
}

export default function ParticipantEntry(props) {
    const { entry, facilities } = props
    const { t, i18n } = useTranslation()
    const history = useHistory()

    const p_id = entry.participant_id
    const shortcode = entry.shortcode
    const gender = entry.gender || 'N/A'
    const birthday = formatDateToLocaleString(entry, i18n.language)
    const language = t(entry.language)

    const facility =
        facilities?.find((f) => f.facility_id === entry.facility_id)?.title ||
        t('default')

    let localizedGender

    if (gender === 'male') {
        localizedGender = t('maleGender')
    } else if (gender === 'female') {
        localizedGender = t('femaleGender')
    } else if (gender === 'other') {
        localizedGender = t('otherGender')
    } else localizedGender = gender

    return (
        <TableRow hover>
            <TableCell id={p_id.toString()} align='left' title={shortcode}>
                {shortcode}
            </TableCell>

            <TableCell align='left' title={language}>
                {language}
            </TableCell>
            <TableCell align='left' title={localizedGender}>
                {localizedGender}
            </TableCell>
            <TableCell align='left' title={birthday}>
                {birthday}
            </TableCell>
            <TableCell align='left' title={facility}>
                {facility}
            </TableCell>

            <TableCell align='center'>
                <Button
                    variant='text'
                    id={p_id.toString() + 'visits'}
                    onClick={() => history.push(`/participants/${p_id}/visits`)}
                >
                    {t('PartVisits')}
                </Button>
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>
                <ThreeDotMenu
                    options={[{ name: 'PartDetails' }]}
                    onMenuItemSelected={(menuItemId) => {
                        if (menuItemId === 'PartDetails') {
                            history.push(`/participants/${p_id}/details`)
                        } else if (menuItemId === 'PartVisits') {
                            history.push(`/participants/${p_id}/visits`)
                        }
                    }}
                />
            </TableCell>
        </TableRow>
    )
}
