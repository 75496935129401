import React from 'react'
import { toast } from 'react-toastify'

import validate_profiling from '../../utils/validate_profiling'
import sanitize_results from '../../utils/sanitize_results'
import ErrorMsg from '../../utils/ErrorMsg'

/**
 * Fetch viz data for admin's version of the VizService.
 * @param {string} assessment_id
 * @returns {Promise}
 */
function admin_fetch_results(assessment_id, setResults, setResultColor, setLoading) {
  return new Promise((resolve, reject) => {

    const endpoint = window._env_.REACT_APP_BACKEND_URL
      + '/assessments/admin/poll/' + assessment_id

    // TODO: Handle Authentication

    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      // headers: headerz
    }

    fetch(endpoint, requestOptions).then(response => {
      response.text().then(results => {
        try {
          const _results = sanitize_results(results)
          /* 
              If the AD,NC and Sample do not have the same categories
              an exception will be thrown.
          */
          validate_profiling(_results.profiling)

          resolve(_results)

          setResults(_results)

          if (_results.prediction === 'low') {
            setResultColor('lightgreen')
          } else if (_results.prediction === 'medium') {
            setResultColor('lightyellow')
          } else if (_results.prediction === 'high') {
            setResultColor('lightcoral')
          }

          setLoading(false)
        }
        catch (err) {
          toast.error(<ErrorMsg err={err.message} />, { autoClose: false })
        }
      })
    }).catch(err => {
      toast.error(<ErrorMsg err={err.message} />, { autoClose: false })
      reject('error fetching admin results')
    })
  })
}

function admin_fetch_visit_results(visit_uuid, setResults, setResultColor, setLoading) {
  return new Promise((resolve, reject) => {
    const endpoint = window._env_.REACT_APP_BACKEND_URL
      + '/assessments/admin/poll/visit/' + visit_uuid

    // TODO: Handle Authentication

    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      // headers: headerz
    }

    fetch(endpoint, requestOptions).then(response => {
      response.text().then(results => {
        try {
          const _results = sanitize_results(results)
          /* 
              If the AD,NC and Sample do not have the same categories
              an exception will be thrown.
          */
          validate_profiling(_results.profiling)

          resolve(_results)

          setResults(_results)

          if (_results.prediction === 'low') {
            setResultColor('lightgreen')
          } else if (_results.prediction === 'medium') {
            setResultColor('lightyellow')
          } else if (_results.prediction === 'high') {
            setResultColor('lightcoral')
          }

          setLoading(false)
        }
        catch (err) {
          toast.error(<ErrorMsg err={err.message} />, { autoClose: false })
        }
      })
    }).catch(err => {
      toast.error(<ErrorMsg err={err.message} />, { autoClose: false })
      reject('error fetching admin results')
    })
  })
}

function admin_update_assessment_status(correlation_id, status, setEnabled) {

  return new Promise((resolve, reject) => {
    const endpoint = window._env_.REACT_APP_BACKEND_URL
      + '/assessments/admin/update/status/' + correlation_id

    let form_data = new FormData()
    form_data.append('status', status)

    // TODO: Handle Authentication

    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      body: form_data
      // headers: headerz
    }

    fetch(endpoint, requestOptions).then(response => {

      if (response.status === 200) {
        response.json().then(data => {
          setEnabled(false)
          resolve('AssessmentStatusUpdateSuccess')
        })
      } else if (response.status === 400) {
        response.json().then(data => {
          setEnabled(false)
          reject('AssessmentStatusUpdateInvalid')
        })
      } else if (response.status === 404) {
        response.json().then(data => {
          setEnabled(false)
          reject('AssessmentStatusUpdateNotFound')
        })
      } else {
        setEnabled(false)
        reject('AssessmentStatusUpdateServerError')
      }
    }).catch(err => {
      toast.error(<ErrorMsg err={err.message} />, { autoClose: false })
      reject('Cannot update task status due to unknown error!')
      setEnabled(true)
    })
  })
}

function admin_update_visit_assessments_status(visit_id, status, setEnabled) {
  return new Promise((resolve, reject) => {
    const endpoint = window._env_.REACT_APP_BACKEND_URL
      + '/assessments/admin/update/status/visit/' + visit_id

    let form_data = new FormData()
    form_data.append('status', status)

    // TODO: Handle Authentication

    const requestOptions = {
      method: 'POST',
      redirect: 'follow',
      body: form_data
      // headers: headerz
    }

    fetch(endpoint, requestOptions).then(response => {
      if (response.status === 200) {
        response.json().then(data => {
          setEnabled(false)
          resolve('AssessmentStatusUpdateSuccess')
        })
      } else if (response.status === 400) {
        response.json().then(data => {
          setEnabled(false)
          reject('AssessmentStatusUpdateInvalid')
        })
      } else if (response.status === 404) {
        response.json().then(data => {
          setEnabled(false)
          reject('AssessmentStatusUpdateNotFound')
        })
      } else {
        setEnabled(false)
        reject('AssessmentStatusUpdateServerError')
      }
    }).catch(err => {
      toast.error(<ErrorMsg err={err.message} />, { autoClose: false })
      reject('Cannot update task status due to unknown error!')
      setEnabled(true)
    })
  })
}

export {
  admin_fetch_results,
  admin_fetch_visit_results,
  admin_update_assessment_status,
  admin_update_visit_assessments_status
}
