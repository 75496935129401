import '../localization/_i18n'
import 'react-toastify/dist/ReactToastify.css'

import { HourglassEmpty } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import assessment_paths from '../assets/assessment_paths'
import {
    useAuth,
    useBooleanState,
    useList,
    useStateFactory,
} from './customHooks/_exports'
import {
    AssessmentContainer,
    AssessmentContainerRemote,
} from './screens/Assessment'
import {
    AssessmentListRemote,
    AssessmentsList,
} from './screens/AssessmentsList/AssessmentsList'
import DemographicData from './screens/DemographicData'
import { Diagnostics } from './screens/Diagnostics'
import Login from './screens/LoginPage'
import { ParticipantsListContainer } from './screens/ParticipantsList/ParticipantsList'
import VisitsList from './screens/VisitsList/VisitsList'
import VizService from './screens/VizService/VizService'
import {
    assessments,
    auth,
    participants,
    recordings,
    visits,
} from './stateObjects/_exports'
import { AdminRoute } from './utils/_exports'
import { ErrorPage } from './utils/ErrorPage'
import TopBar from './utils/TopBar'

export default function AppEntryPoint() {
    const { t } = useTranslation()

    auth.state = useAuth()
    participants.list = useList()
    visits.list = useList()
    recordings.storage = useStateFactory(assessment_paths)
    assessments.status = useStateFactory(assessment_paths)
    assessments.ids = useStateFactory(assessment_paths)
    assessments.failure_reason = useStateFactory(assessment_paths)
    assessments.readonly = useBooleanState(true)
    assessments.required = useStateFactory(assessment_paths)

    return (
        <Router>
            <TopBar className='top-bar' />

            <section className='main-layout'>
                <ToastContainer
                    position='bottom-right'
                    autoClose={4000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                />

                <Switch>
                    <PrivateRoute exact path='/'>
                        <Redirect
                            to={{
                                pathname: '/participants',
                            }}
                        />
                    </PrivateRoute>

                    <Route exact path='/login'>
                        <Login />
                    </Route>

                    <Route exact path='/invalid-url'>
                        <ErrorPage message={t('invalid_url')} />
                    </Route>

                    <Route exact path='/error'>
                        <ErrorPage color={'red'} message={t('Retry_Message')} />
                    </Route>

                    <Route exact path='/not-ready'>
                        <ErrorPage
                            icon={
                                <HourglassEmpty style={{ fontSize: '60px' }} />
                            }
                            message={t(
                                'Prediction not ready. Please try again later.',
                            )}
                        />
                    </Route>

                    <PrivateRoute exact path='/participants'>
                        <ParticipantsListContainer />
                    </PrivateRoute>

                    <PrivateRoute exact path='/participants/:p_id/details'>
                        <DemographicData />
                    </PrivateRoute>

                    <PrivateRoute exact path='/participants/:p_id/visits'>
                        <VisitsList />
                    </PrivateRoute>

                    <PrivateRoute exact path='/participants/:p_id/visits/:v_id'>
                        <AssessmentsList />
                    </PrivateRoute>

                    <RemoteTokenRoute
                        exact
                        path='/remote/participants/:p_id/visits/:v_id'
                    >
                        <AssessmentListRemote />
                    </RemoteTokenRoute>

                    <PrivateRoute
                        exact
                        path='/participants/:p_id/visits/:v_id/:assessment'
                    >
                        <AssessmentContainer />
                    </PrivateRoute>

                    <RemoteTokenRoute
                        exact
                        path='/remote/participants/:p_id/visits/:v_id/:assessment'
                    >
                        <AssessmentContainerRemote />
                    </RemoteTokenRoute>

                    <PrivateRoute
                        exact
                        path='/results/participants/:p_id/visits/:v_id/:assessment/:assessment_id'
                    >
                        <VizService />
                    </PrivateRoute>

                    <AdminRoute
                        exact
                        path='/admin/results/participants/:p_id/visits/:v_id/:assessment/:assessment_id'
                    >
                        <VizService />
                    </AdminRoute>

                    <PrivateRoute
                        exact
                        path='/results/participants/:p_id/visits/:v_id'
                    >
                        <VizService />
                    </PrivateRoute>

                    <AdminRoute
                        exact
                        path='/admin/results/participants/:p_id/visits/:v_id'
                    >
                        <VizService />
                    </AdminRoute>

                    {/* public routes */}
                    <Route exact path='/diagnostics'>
                        <Diagnostics />
                    </Route>
                </Switch>
            </section>
        </Router>
    )
}

/*
    A wrapper for <Route> that redirects to the login
    screen if you're not yet authenticated.
*/
function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                sessionStorage.getItem('token') ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    )
}

function RemoteTokenRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const { search } = location
                const remoteToken =
                    new URLSearchParams(search).get('remote_token') ||
                    sessionStorage.getItem('remote_token')

                return remoteToken ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location },
                        }}
                    />
                )
            }}
        />
    )
}
