import { Box, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import Link from './Link'
import { typography } from './typography'

const useStyles = makeStyles((_) => ({
    layout: {
        paddingBottom: '15px',
        marginTop: '20px',
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
        borderBottom: '1px solid #F2F2F2',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
}))

/**
 * @typedef {object} Props
 * @param {string} backButtonLabel
 * @param {string} backButtonPath
 * @param {string} menuInfo
 */
export default function HeaderMenu(props) {
    const { backButtonLabel, backButtonPath, label } = props

    const classes = useStyles()

    return (
        <Box className={classes.layout}>
            <Link label={backButtonLabel} to={backButtonPath} />
            {label &&
                typography(
                    'h1',
                    '',
                    label,
                    'right',
                    { textAlign: 'right', width: 'auto' },
                    '14px',
                )}
        </Box>
    )
}

HeaderMenu.propTypes = {
    /** Naming of the back button. */
    backButtonLabel: PropTypes.string,
    /** A valid path defined at app's entry point. */
    backButtonPath: PropTypes.string,
    /** Info displayed on the right. */
    menuInfo: PropTypes.string,
}
