import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resources from './_resources'

// passes i18n down to react-i18next
i18n.use(initReactI18next).init({

  resources,

  // default language
  lng: "el-GR",

  // we do not use keys in form messages.welcome
  keySeparator: false,

  // react already provides safety from xss
  interpolation: { escapeValue: false },

  saveMissing: true,
});

export default i18n;